@import "./../../../CommonSCSS/mixins.scss";

.HomePageWrapper {
  %btn {
    background: $theme-lightgreen;
    color: $white;
    @include border-radius(50px);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0.15);

    &:hover {
      background: lighten($theme-lightgreen, 5%);
      @include box-shadow(0px 0px 0px rgba($black, 0));
    }
  }

  .greenLgBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 10px 24px;
  }

  .greenMdBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 8px 20px;
  }

  .greenSmBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 4px 14px;
  }

  .greenXsBtn {
    @extend %btn;
    @include fontSize(12px);
    padding: 2px 14px;
  }

  .row {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }

  .row2 {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    @include fontSize(45px);
    font-weight: 700;
    line-height: 68px;
    text-align: center;

    span {
      position: relative;

      &:before {
        @include position(absolute, $bottom: -12px, $left: 0);
        content: "";
        background: url("./../../../../public/images/heading_bg.svg") no-repeat 0 0 /100%;
        width: 100%;
        height: 8px;
      }
    }

    @media (max-width: 991px) {
      @include fontSize(32px);
      line-height: 50px;
    }
  }

  .small-heading {
    @include fontSize(16px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: $black;
    margin-bottom: 10px;
    text-align: center;

    span {
      font-weight: 700;
    }
  }

  .head-section {
    min-height: 768px;
    border-bottom: 1px solid #e9e9e9 !important;
    padding-top: 158px;
    position: relative;

    &:before {
      @include position(absolute, $right: 10px, $bottom: 20px);
      content: "";
      background: url("./../../../../public/images/family-small-icon.svg") no-repeat 0 0/380px;
      width: 380px;
      height: 355px;
      z-index: -2;
    }

    &:after {
      @include position(absolute, $right: 20%, $top: 20%);
      content: "";
      background: url("./../../../../public/images/family-health-icon.svg") no-repeat 0 0/236px;
      width: 236px;
      height: 250px;
      z-index: -2;
    }

    h1 {
      @include fontSize(52px);
      line-height: 70px;
      color: $black;
      margin-bottom: 12px;

      @media (max-width: 1150px) {
        @include fontSize(45px);
        line-height: 65px;
      }

      @media (max-width: 991px) {
        @include fontSize(40px);
        line-height: 60px;
      }

      span {
        color: $theme-lightgreen;
        padding-left: 25px;
        padding-right: 25px;
        position: relative;

        &:before {
          @include position(absolute, $top: -5px, $left: 0);
          content: "";
          background: url("./../../../../public/images/bestTag_bg.png") no-repeat 0 0 /172px;
          width: 172px;
          height: 88px;

          @media (max-width: 1150px) {
            background: url("./../../../../public/images/bestTag_bg.png") no-repeat 0 0 /152px;
          }

          @media (max-width: 991px) {
            background: url("./../../../../public/images/bestTag_bg.png") no-repeat 0 0 /132px;
          }
        }
      }
    }

    p {
      @include fontSize(20px);
      line-height: 32px;
      color: $black;

      span {
        font-weight: 500;
      }
    }

    .product-section {
      margin-top: 40px;
      position: relative;
      z-index: 5;

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        li {
          animation: slideDown 1.5s both;

          &:nth-child(2) {
            animation-delay: 0.2s;
          }

          &:nth-child(3) {
            animation-delay: 0.5s;
          }

          &:last-child {
            margin-right: 0;
            animation-delay: 0.7s;
          }

          .product-type {
            border-radius: 15px;
            background: #fff;
            box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.12);
            padding: 20px 10px 28px 10px;
            display: inline-block;
            width: 160px;
            height: 162px;
            border: 1px solid $white;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            position: relative;

            &.disabled {
              cursor: wait;

              p,
              img {
                opacity: 0.4;
              }

              &:before {
                background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
                content: '';
                position: absolute;
                top: 22px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 9;
              }
            }

            &:hover {
              border: 1px solid $theme-lightgreen;
              transform: scale(1.1, 1.1);
              transition: transform 0.4s ease-in-out;

              p {
                color: $theme-lightgreen;
              }
            }

            img {
              height: 80px;
              margin-bottom: 10px;
            }

            p {
              @include fontSize(16px);
              color: $black;
            }

            $othericons: healthproduct, carproduct, bikeproduct, termproduct;
            $k: 0;

            @each $icons in $othericons {
              $bacRightP: $k * 120;
              $k: $k +1;

              .#{$icons} {
                position: relative;
                background: url("../../../../public/images/product-icons-BS.svg") no-repeat -#{$bacRightP}px 0px/480px;
                height: 100px;
                width: 120px;
                margin: auto;
              }
            }
          }
        }
      }
    }

    .bikeWrapper {
      position: absolute;
      bottom: -6px;
      width: 120%;
      left: -150px;
      // overflow: hidden;
      z-index: 0;
      animation: movebiker 25s linear infinite;

      .bikebox {
        width: 150px;
      }

      .biker {
        height: 85px;
        z-index: 3;
        position: relative;
      }

      .hair {
        @include position(absolute, $left: -14px, $top: 6px);
        animation: waving 3s ease-in-out infinite;
        z-index: -1;
        transform-origin: right center;
      }

      @keyframes waving {
        0% {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        20% {
          -webkit-transform: rotate(-3deg);
          -ms-transform: rotate(-3deg);
          transform: rotate(-3deg);
        }

        54% {
          -webkit-transform: rotate(6deg);
          -ms-transform: rotate(6deg);
          transform: rotate(6deg);
        }

        82% {
          -webkit-transform: rotate(-3deg);
          -ms-transform: rotate(-3deg);
          transform: rotate(-3deg);
        }
      }

      .bikerbox {
        height: 260px;
        z-index: -1;
        position: relative;
      }

      @keyframes movebiker {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(100%);
        }
      }
    }

    .carWrapper {
      position: absolute;
      bottom: 0px;
      width: 120%;
      left: -250px;
      overflow: hidden;
      z-index: 0;
      animation: drivecar 15s linear infinite;

      .carbox {
        width: 233px;
        height: 86px;

        .carbody {
          width: 100%;
        }
      }

      .bikerbox {
        height: 93px;
        z-index: -1;

        .biker {
          height: 93px;
          z-index: 3;
        }
      }

      .rearWheel {
        @include position(absolute, $left: 16px, $bottom: 0px);
        animation: rotating 1.2s linear infinite;
        z-index: 1;
      }

      .frontWheel {
        @include position(absolute, $left: 168px, $bottom: 0px);
        animation: rotating 1.2s linear infinite;
        z-index: 1;
      }

      @keyframes rotating {
        from {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
        }

        to {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        0% {
          bottom: 0px;
        }

        60% {
          bottom: 0px;
        }

        100% {
          bottom: 0px;
        }
      }

      @keyframes drivecar {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(100%);
        }
      }
    }

    .aeroplaneWrapper {
      position: absolute;
      top: 0px;
      width: 100%;
      right: 0px;
      // overflow: hidden;
      z-index: -1;

      .aeroplanebox {
        height: 130px;
        z-index: -1;
        position: absolute;
        right: -100px;
        top: 160px;
        animation: 10s ease-in-out takeOff infinite;

        img {
          height: 80px;
          z-index: 3;
        }
      }

      @keyframes takeOff {
        0% {
          transform: translate(100%, 100%);
        }

        100% {
          transform: translate(0, 0);
          right: 100%;
          top: 0px;
        }
      }
    }

    @keyframes slideDown {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20%, 0);
        transform: translate3d(0, -20%, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  .insuredWay-section {
    margin-top: 70px;
    margin-bottom: 70px;
    animation: slideInTop 3s both;

    .easyWay-box {
      border: 2px solid #e1edff;
      padding: 60px 30px;
      @include border-radius(15px);
      text-align: center;
      // opacity: 0;

      @media (max-width: 1150px) {
        padding: 30px 12px;
      }

      &:hover {
        box-shadow: 0px 12px 32px 0px rgba(32, 56, 100, 0.12);
        border: 2px solid $white;

        span {
          background: #e1edff;
        }
      }

      span {
        width: 45px;
        height: 45px;
        border: 2px solid #e1edff;
        @include border-radius(50%);
        display: inline-block;
        margin-bottom: 20px;
        padding-top: 9px;
      }

      h3 {
        @include fontSize(26px);
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 10px;

        @media (max-width: 1150px) {
          @include fontSize(22px);
          font-weight: 600;
          line-height: 28px;
        }
      }
    }

    @keyframes slideInTop {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20%, 0);
        transform: translate3d(0, -20%, 0);
      }

      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  .why-section {
    padding-top: 70px;
    padding-bottom: 70px;
    background: rgba(#1E71EC, 0.04);
    animation: slideInTop 3s both;
    animation-delay: 1s;

    .choose-sec {
      padding: 30px 15px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 6px 20px 0px rgba(32, 56, 100, 0.12);
      margin: 30px 12px 100px 12px;
      text-align: center;
      min-height: 365px;

      img {
        margin-bottom: 24px;
        height: 174px;
      }

      @media (max-width: 1150px) {
        min-height: auto;
      }

      h3 {
        @include fontSize(20px);
        color: $black;
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        @include fontSize(14px);
        color: lighten($black, 40%);
        font-weight: 400;
      }

      $othericons: icon1, icon2, icon3, icon4, icon5;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 190;
        $k: $k +1;

        // @media(max-width:1150px) {
        //     $bacRightP: $k * 150;
        // }

        .#{$icons} {
          position: relative;
          background: url("../../../../public/images/choose-sec-icons.svg") no-repeat -#{$bacRightP}px 0px/950px;
          height: 174px;
          width: 190px;
          margin: auto;
          margin-bottom: 24px;

          // @media(max-width:1150px) {
          //     background: url("../../../../public/images/choose-sec-icons.svg") no-repeat -#{$bacRightP}px 0px/750px;
          //     height: 134px;
          //     width: 150px;
          // }
        }
      }

      @media (max-width: 1150px) {
        $othericons: icon1,
          icon2,
          icon3,
          icon4,
          icon5;
        $k: 0;

        @each $icons in $othericons {
          $bacRightP: $k * 150;
          $k: $k +1;

          .#{$icons} {
            position: relative;
            background: url("../../../../public/images/choose-sec-icons.svg") no-repeat -#{$bacRightP}px 0px/750px;
            height: 134px;
            width: 150px;
            margin: auto;
            margin-bottom: 24px;
          }
        }
      }
    }

    .react-multiple-carousel__arrow--left {
      left: calc(38% + 20px);
      bottom: 0px;
      background: #e1edff;

      @media (max-width: 1150px) {
        left: calc(32% + 20px);
      }

      &:before {
        color: $black;
      }
    }

    .react-multiple-carousel__arrow--right {
      right: calc(38% + 20px);
      bottom: 0px;
      background: #e1edff;

      @media (max-width: 1150px) {
        right: calc(32% + 20px);
      }

      &:before {
        color: $black;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;

      .react-multi-carousel-dot {
        button {
          border: 1px solid $black;
          background: $white;
          height: 10px;
          width: 10px;
          margin: 0px 4px;
        }

        &.react-multi-carousel-dot--active {
          button {
            background: $black;
          }
        }
      }
    }
  }

  .clients-section {
    margin-top: 70px;
    margin-bottom: 70px;

    .logos {
      overflow: hidden;
      white-space: nowrap;

      .logos-outer {
        display: inline-block;

        .logos-slide {
          // white-space: nowrap;
          display: flex;
          animation: 30s slide infinite linear;

          // display: inline-block;
          .logobox {
            width: 180px;
            padding: 10px;
            border: 1px solid rgba(163, 183, 219, 0.3);
            margin: 12px;
            @include border-radius(10px);
            // filter: grayscale(0.9);
            // cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            // &:hover {
            //   filter: grayscale(0);
            // }

            img {
              max-height: 80px;
              width: 100%;
              object-fit: contain;
              border: none;
              outline: none;
            }
          }
        }
      }
    }

    @keyframes slide {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(-100%);
      }
    }
  }

  .recognition-section {
    padding-top: 70px;
    padding-bottom: 70px;
    background: rgba(#1E71EC, 0.04);

    .react-multi-carousel-list {
      overflow-y: hidden;
      // overflow-x: visible!important;
    }

    .choose-sec {
      padding: 30px 15px;
      border-radius: 10px;
      background: #fff;
      // box-shadow: 0px 6px 20px 0px rgba(32, 56, 100, 0.12);
      margin: 70px 12px 100px 12px;
      text-align: center;
      border: 1px solid #e3edff;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 12px 100px 0px rgba(32, 56, 100, 0.25);
        border: none;
        border-radius: 15px;
      }

      img {
        height: 170px;

        @media (max-width: 1150px) {
          height: 130px;
        }
      }
    }

    .react-multiple-carousel__arrow--left {
      left: calc(35% + 0px);
      bottom: 0px;
      background: #e1edff;

      @media (max-width: 1150px) {
        left: calc(32% + 0px);
      }

      &:before {
        color: $black;
      }
    }

    .react-multiple-carousel__arrow--right {
      right: calc(35% + 0px);
      bottom: 0px;
      background: #e1edff;

      @media (max-width: 1150px) {
        right: calc(32% + 0px);
      }

      &:before {
        color: $black;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;

      .react-multi-carousel-dot {
        button {
          border: 1px solid $black;
          background: $white;
          height: 10px;
          width: 10px;
          margin: 0px 4px;
        }

        &.react-multi-carousel-dot--active {
          button {
            background: $black;
          }
        }
      }
    }
  }

  .insurance-products-section {
    margin-top: 70px;
    margin-bottom: 70px;

    .product_p {
      @include fontSize(16px);
      color: lighten($color: $black, $amount: 60%);
      text-align: center;
    }

    .productPage-type {
      border-radius: 15px;
      background: #fff;
      box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.12);
      padding: 35px 20px;
      display: inline-block;
      width: 100%;
      border: 1px solid $white;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      position: relative;

      &.disabled {
        cursor: wait;

        p,
        img {
          opacity: 0.4;
        }

        &:before {
          background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
          content: '';
          position: absolute;
          top: 22px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 9;
          display: block;
        }
      }

      &:hover {
        // outline: 1px solid $theme-lightgreen;
        // outline-offset: -1px;
        // transform: scale(1.1, 1.1);
        // box-shadow: -12px 12px 40px 0px rgba($theme-lightgreen, 0.12);
        box-shadow: 20px 20px 60px #00000011, -20px -20px 60px rgba($theme-lightgreen, 0.09);
        transform: translateY(-0.60rem);
        transition: transform 0.4s ease-in-out;
      }

      img {
        height: 100px;
        margin-bottom: 10px;
      }

      p {
        @include fontSize(16px);
        color: $black;

        &:hover {
          color: $theme-lightgreen;
        }
      }

      $othericons: healthproduct, carproduct, bikeproduct, termproduct;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 120;
        $k: $k +1;

        .#{$icons} {
          position: relative;
          background: url("../../../../public/images/product-icons-BS.svg") no-repeat -#{$bacRightP}px 0px/480px;
          height: 100px;
          width: 120px;
          margin: auto;
        }
      }
    }
  }

  .testimonials-section {
    padding-top: 70px;
    padding-bottom: 70px;
    background: rgba(#1E71EC, 0.04);

    .testimonial-sec {
      padding: 30px 15px;
      // margin: 30px 12px 100px 12px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;

        // @media (max-width: 1150px) {
        //   width: 130px;
        //   height: 130px;
        // }
      }

      .author {
        margin-top: 50px;
        margin-bottom: 20px;

        @media (max-width: 1150px) {
          margin-top: 20px;
        }

        h5 {
          @include fontSize(20px);
          color: $black;
          font-weight: 600;
          margin-bottom: 5px;
        }

        p {
          @include fontSize(14px);
          color: lighten($black, 40%);
          font-weight: 400;
        }
      }

      .testimonial-desc {
        @include fontSize(18px);
        color: $black;
        font-weight: 400;
        line-height: 26px;
        max-width: 960px;
        margin: auto;

        @media (max-width: 1150px) {
          @include fontSize(16px);
          color: $black;
          font-weight: 400;
          line-height: 22px;
          max-width: 600px;
        }
      }
    }

    // .react-multi-carousel-track {
    //   justify-content: center;
    //   align-items: flex-start;
    //   .react-multi-carousel-item {
    //     display: flex;
    //       flex-direction: column;
    //       // align-items: flex-start;
    //       flex: 1 0 0;
    //   }
    // }
    .react-multiple-carousel__arrow--left {
      bottom: calc(50% - 21px);
      background: #e1edff;

      &:before {
        color: $black;
      }
    }

    .react-multiple-carousel__arrow--right {
      bottom: calc(50% - 21px);
      background: #e1edff;

      &:before {
        color: $black;
      }
    }
  }

  .our-partners-section {
    margin-top: 70px;
    margin-bottom: 70px;

    .product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        padding: 10px 15px;
        margin: 12px;
        // border: 1px solid black;
        max-width: 170px;
        width: 100%;
        display: flex;
        justify-content: center;
        // filter: grayscale(0.9);
        // cursor: pointer;

        // &:hover {
        //   filter: grayscale(0);
        // }

        img {
          height: 60px;
          // // margin: auto;
          // max-height: 60px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .react-multiple-carousel__arrow {
    z-index: 1;
  }
}