@import "../../../../CommonSCSS/mixins.scss";
// @import "./mixins.scss";

.faq {
  margin-bottom: 8px;
  font-family: "poppins";

  .MuiPaper-root {
    background: transparent;
    @include box-shadow(0px 2px 0px #e3edff);
    @include border-radius(12px!important);
    margin-bottom: 16px;
    margin-top: 16px;
    &:before {
      display: none;
    }
  }
  .MuiAccordionSummary-root {
    &.Mui-expanded {
      min-height: 40px;
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: $black;
    }
  }
  .MuiAccordionSummary-content {
    margin: 12px 0px;
    &.Mui-expanded {
      margin: 12px 0px;
    }
    p {
      @include fontSize(16px);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: $theme-blue;
      letter-spacing: 0px;
    }
  }
  .MuiAccordionDetails-root {
    p {
      @include fontSize(14px);
      font-family: "Poppins", sans-serif;
      color: $black;
    }
  }
}
