@import "./../../../../CommonSCSS/mixins.scss";

.svg-pi-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  padding: 6px;
  &:before {
      background-color: #F6F9FE;
      border-radius: 200%;
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

  .meterbox {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -56px;
    left: 18px;
    transform: rotate(-90deg);
    transform-origin: bottom;

    &:before {
      background-color: #30364D;
      border-radius: 20px;
      content: "";
      position: absolute;
      bottom: -92px;
      left: -101px;
      height: 18px;
      width: 18px;
      z-index: 2;
    }

    &:after {
      background-color: #fff;
      box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.15);
      border-radius: 40px;
      content: "";
      position: absolute;
      bottom: -106px;
      left: -116px;
      height: 46px;
      width: 46px;
    }

    .niddle {
      position: absolute;
      bottom: -83px;
      left: -104px;
      transform-origin: bottom;
      z-index: -1;

      img {
        height: 120px;
      }
    }
  }
}

.svg-pi-indicator {
  stroke: url(#gradient);
}

.svg-pi {
  transform: rotate(-199deg);
  /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
  animation: spinner 0.75s linear infinite;
  transform-origin: center;
}

.svg-pi-label__progress {
  font-size: 1.5em;
  font-weight: bold;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
  display: block;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}