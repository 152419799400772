@import "./../../../CommonSCSS/mixins.scss";

.navbar_m {
    @include position(fixed, $top: 0px, $left: 0px);
    z-index: 91;
    width: 100%;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    .top-strip {
        background: linear-gradient(180deg, rgba(225, 237, 255, 0.07) 0%, rgba(225, 237, 255, 0.63) 100%);
        padding: 8px 12px;

        p {
            @include fontSize(12px);
            text-transform: initial;
            text-align: center;
            line-height: 16px;

            span {
                font-weight: 600;
            }
        }
    }

    .logo-section {
        a {
            display: inline-block;
        }
        .logo {
            max-width: 140px;
        }
    }

    .menuIcon {
        background: #203864;
        width: 32px;
        height: 32px;
        @include border-radius(50%);
        display: block;
        position: relative;
        cursor: pointer;
        margin-left: auto;

        img {
            @include position(absolute, $top: 50%, $left: 50%);
            transform: translate(-50%, -50%);

        }
    }

}

.modalWrapperLogin {
    background: rgba(32, 56, 100, 0.15);
    height: 100%;

    .MuiBackdrop-root {
        background: transparent !important;
    }

    .modalContent {
        background: $white;
        margin: 12px;
        padding: 12px 12px 24px 12px;
        box-shadow: 0px 4px 24px 0px rgba(32, 56, 100, 0.50);
        @include border-radius(20px);
        @include position(absolute, $top: 50%);
        transform: translateY(-50%);

        h3 {
            @include fontSize(22px);
            line-height: 32px;
            font-weight: 300;

            span {
                font-weight: 600;
            }
        }

        p {
            @include fontSize(14px);
            line-height: 21px;
            font-weight: 400;

            span {
                font-weight: 600;
            }

            .editBtn {
                background: #203864;
                @include fontSize(12px);
                font-weight: 500;
                padding: 2px 10px;
                color: $white;
                margin-left: 15px;
                border-radius: 40px;
                text-decoration: none;
                cursor: pointer;
                display: inline-block;
                margin-top: 8px;
            }
        }

        .close-button {
            background: $white;
            color: #000;
            @include border-radius(50%);
            $size: 40px;
            height: $size;
            width: $size;
            box-sizing: border-box;
            line-height: $size;
            z-index: 99;
            display: block;
            box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.15);
            @include position(absolute, $top:-20px, $left:calc(50% - 20px));

            &:before,
            &:after {
                $width: 15px;
                $height: 1.5px;
                transform: rotate(-45deg);
                content: "";
                @include position(absolute, $left: 12px, $top: 19px);
                height: $height;
                width: $width;
                background-color: #000;
                transition: all 0.2s ease;
            }

            &:after {
                transform: rotate(-135deg);
            }

            // &:hover {
            //     &:before {
            //         background-color: #de4b4b;

            //         transform: rotate(0deg);
            //     }

            //     &:after {
            //         background-color: #de4b4b;
            //         transform: rotate(-180deg);
            //     }
            // }
        }
        .greenLgBtn{
            background: $theme-lightgreen;
            color: $white;
            @include border-radius(12px);
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            text-transform: capitalize;
            @include fontSize(14px);
            padding: 12px 30px;
            line-height: 20px;
        }
        
    }
}