@import "../CommonSCSS/mixins.scss";

.MuiModal-root {
  .MuiBackdrop-root {
    background: transparent;
  }
}

.modalWrapper {
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;
  background: rgba(#000000, 0.2);
  backdrop-filter: blur(4px);

  .modalContent {
    background: $white;
    @include border-radius(20px);
    @include box-shadow(0px 6px 12px rgba($black, 0.2));
    font-family: "poppins", sans-serif;
    margin: 60px auto;
    // padding: 0px 12px;
    max-width: 800px;
    width: 100%;
    // transform: translateY(-50%);
    // position: relative;
    // top: 50%;
    position: relative;

    &.smWidth {
      max-width: 600px;
    }

    &.xsWidth {
      max-width: 420px !important;
      padding: 40px;
    }

    &.fullwidth {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }


    &.kycPopups{
      padding:20px;
     .popup-heading {
        font-weight: 600;
        @include fontSize(20px);
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }

      .grey_p {
          font-weight: 400;
          @include fontSize(15px);
          line-height: 18px;
          text-align: center;
          margin-bottom: 16px;
          color: #999;
      }

      .policy_number_box {
          background: #FFF9E6;
          color: #FFC929;
          border: 1px solid #FFC929;
          padding: 10px 16px;
          border-radius: 30px;
          @include fontSize(18px);
          display: inline-block;
      }

      h6 {
          font-weight: 600;
          @include fontSize(16px);
          line-height: 18px;
          margin-bottom: 16px;
          text-align: left;

          span {
              font-weight: 400;
              display: block;
              text-transform: uppercase;
              @include fontSize(15px);
              margin-top: 8px;
          }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: "poppins", sans-serif;
    }

    .title {
      background: #F6F9FE;
      position: relative;
      margin-bottom: 24px;
      padding: 8px 12px;
      @include border-radius(20px 20px 0px 0px);

      h3 {
        @include fontSize(18px);
        font-weight: 400 !important;

        span {
          font-weight: 600;
        }
      }
    }

    .closePopup {
      @include border-radius(40px);
      position: relative;
      min-width: 20px;
      padding: 0px;
      height: 30px;
      width: 30px;

      &:before {
        background: $black;
        content: "";
        @include position(absolute, $left: 14px, $top: 6px);
        transform: rotate(45deg);
        height: 16px;
        width: 2px;
        transition: transform 0.3s ease-in-out;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
      }

      &:hover {
        &:before {
          transform: rotate(90deg);
          background: $red;
        }

        &:after {
          transform: rotate(-90deg);
          background: $red;
        }
      }
    }

    .radioBox {
      button {
        @include border-radius(8px !important);
        border: 1px solid rgba(32, 56, 100, 0.20);
        text-transform: capitalize;
        margin: 0px 6px;
        padding: 8px 16px;
        height: 30px;
        @include fontSize(14px);
        font-weight: 500;
        color: #000;
        font-family: 'poppins';
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        &.Mui-selected {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.40);
          font-weight: 500
        }
      }

      &.healthradioBox {
        button {
          height: auto;
          display: block;

          $othericons: oneAdult,
            twoAdults;
          $k: 0;

          @each $icons in $othericons {
            $bacRightP: $k * 33;
            $k: $k +1;

            .#{$icons} {
              position: relative;
              background: url("../../public/images/health_adults_icons.svg") no-repeat -#{$bacRightP}px 0px/66px;
              height: 33px;
              width: 33px;
              margin: 0 auto 8px;
            }
          }

          &.Mui-selected {
            $othericons: oneAdult,
              twoAdults;
            $k: 0;

            @each $icons in $othericons {
              $bacRightP: $k * 33;
              $k: $k +1;

              .#{$icons} {
                background: url("../../public/images/health_adults_icons_blue.svg") no-repeat -#{$bacRightP}px 0px/66px;
              }
            }
          }
        }
      }
    }

    .claim_txt {
      @include fontSize(16px);
      font-weight: 500;
    }

    .discountTxt_p {
      @include fontSize(14px);
      font-weight: 400;
    }

    .lighttxt_p {
      @include fontSize(14px);
      font-weight: 400;
      opacity: 0.5;
    }

    .emailPopup {
      h5 {
        @include fontSize(22px);
        font-weight: 400;
        margin-bottom: 12px;

        span {
          font-weight: 600;
        }
      }

      p {
        @include fontSize(14px);
        font-weight: 400;
        opacity: 0.6;
        font-family: "poppins";
      }

      .mainPopupImg {
        position: relative;
        top: -8px;
        height: 200px;
      }

      .thanksBox {
        p {
          @include fontSize(18px);
          line-height: 36px;
          font-weight: 600;
          opacity: 1
        }

        .greenTxt {
          @include fontSize(14px);
          line-height: 36px;
          font-weight: 400;
          color: $theme-green;
        }
      }

      .env-wrapper {
        $gg: #EFF1F4;
        $rr: #F44336;

        @mixin transition($in) {
          transition: $in;
          -webkit-transition: $in;
          -moz-transition: $in;
          -o-transition: $in;
          -ms-transition: $in;
        }

        @mixin transform($in) {
          transform: $in;
          -webkit-transform: $in;
          -moz-transform: $in;
          -o-transform: $in;
          -ms-transform: $in;
        }

        @mixin transform-style($in) {
          transform-style: $in;
          -webkit-transform-style: $in;
          -moz-transform-style: $in;
          -o-transform-style: $in;
          -ms-transform-style: $in;
        }

        @mixin perspective($in) {
          perspective: $in;
          -webkit-perspective: $in;
          -moz-perspective: $in;
          -o-perspective: $in;
          -ms-perspective: $in;
        }

        @mixin perspective-origin($in) {
          perspective-origin: $in;
          -webkit-perspective-origin: $in;
          -moz-perspective-origin: $in;
          -o-perspective-origin: $in;
          -ms-perspective-origin: $in;
        }

        @mixin transform-origin($in) {
          transform-origin: $in;
          -webkit-transform-origin: $in;
          -moz-transform-origin: $in;
          -o-transform-origin: $in;
          -ms-transform-origin: $in;
        }

        .envbox {
          transform: scale(0.2, 0.2);
          bottom: 50px;
          right: 50px;

        }

        .env {
          margin: 20px auto 0;
          background: darken($gg, 15%);
          width: 500px;
          @include perspective(3000px);
          @include perspective-origin(0% 0);
          @include transform-style(preserve-3d);

          .rest {
            width: 100px;
            height: 0;
            margin: 0 auto;
            border: 200px solid $gg;
            border-bottom: 150px solid darken($gg, 5%);
            border-top: 150px solid transparent;
            margin-top: -300px;
          }

          .content {
            width: 400px;
            margin-bottom: 500px;
            padding: 40px 30px 0px 30px;
            height: 250px;
            background: #fff;
            @include transition(all 0.5s ease);
            text-align: center;
            position: absolute;
            z-index: -5;
            margin-left: 25px;
            @include transform(translate(0, 0));
            animation-delay: 2s;
          }

          .top {
            display: block;
            width: 0;
            height: 0;
            border: 200px solid transparent;
            border-top-color: darken($gg, 10%);
            margin: 0 auto;
            @include transform(scale(1.25, 1));
            margin-bottom: -400px;
            cursor: pointer;
            @include transition(all 0.5s ease);
            @include transform-origin(50% 0);
            position: relative;
          }



          .open-env {
            label.top {

              animation: labeltop 1s ease forwards 1 0.5s;
              animation-delay: 1.2s;

            }

            .content {
              animation: out 1s ease forwards 1 0.5s;
              animation-delay: 2s;
            }
          }
        }

        @keyframes out {
          100% {
            @include transform(translate(0px, -220px));
            height: 420px;
          }
        }

        @keyframes envelope {

          0% {
            opacity: 0;
            transform: translate(400px, 0px);
          }

          100% {


            opacity: 100;
            transform: translate(0px, 0px);
          }

        }


        @keyframes labeltop {

          100% {

            @include transform(rotateX(-180deg) scale(1.25, 1));
            z-index: -20;
          }

        }
      }
    }

    .viewPolicyPopup {
      .logobox {
        @include border-radius(15px);
        border: 1px solid #E3E9F4;
        padding: 18px;
        display: flex;
        align-items: center;

        img {
          width: 100px;
        }
      }

      h5 {
        color: rgba(37, 37, 37, 0.70);
        font-size: 14px;
        font-weight: 400;

        span {
          color: #000;
          font-weight: 600;
          display: block;
          margin-top: 4px;

          &.redtxt {
            color: #D9132E;
          }
        }
      }

      .blue_box {
        @include border-radius(15px);
        padding: 20px;
        background: linear-gradient(270deg, rgba(225, 237, 255, 0.14) 0%, rgba(225, 237, 255, 0.63) 100%);
        display: flex;

        .innerbox {
          width: 50%;
          display: flex;
          align-items: center;
          gap: 15px;

          .icon_box {
            background: $white;
            padding: 15px;
            @include border-radius(10px);
          }
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 600;
      }

      .policyAddonCovers {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        li {
          color: rgba(37, 37, 37, 0.70);
          font-size: 14px;
          font-weight: 400;
          width: calc((100% - 32px) / 3);
          position: relative;
          display: flex;
          align-items: center;

          .iconBtn {
            .icon {
              color: $theme-green;
              background: #F4F4F4;
              margin-right: 10px;
            }
          }
        }
      }

      hr {
        border-top: 1.5px solid #E3E9F4;
        border-bottom: 0;
      }

      .MuiRating-root {
        .MuiRating-icon {
          color: $theme-yellow;

          .MuiSvgIcon-root {
            font-size: 35px;
          }
        }
      }

      .actionbox {
        @include border-radius(15px);
        padding: 10px 20px;
        background: linear-gradient(270deg, rgba(225, 237, 255, 0.14) 0%, rgba(225, 237, 255, 0.63) 100%);
        text-align: center;
        height: 44px;
      }

      .iconBtn {
        @include fontSize(12px);
        display: inline-flex;
        align-items: center;
        line-height: 14px;
        color: $theme-blue;
        font-family: "Poppins";
        text-decoration: none;
        cursor: pointer;

        .icon {
          background: $theme-blue;
          color: $white;
          padding: 4px;
          @include border-radius(4px);
          margin-right: 4px;
          height: 24px;
          width: 24px;
        }
      }
    }

    .loginPopup {
      .closePopup {
        border: none;
        @include position(absolute, $top: 12px, $right: 12px);

        &:before,
        &:after {
          width: 2px;
        }
      }

      h3 {
        @include fontSize(26px);
        line-height: 32px;
        font-weight: 600;
      }

      p {
        @include fontSize(14px);
        line-height: 21px;
        font-weight: 400;

        span {
          font-weight: 600;
        }

        .editBtn {
          background: #203864;
          @include fontSize(12px);
          font-weight: 500;
          padding: 2px 10px;
          color: $white;
          margin-left: 15px;
          border-radius: 40px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .varient_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 32px 0;

      &.left_align {
        justify-content: left;
        padding-left: 0;
      }

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 10px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: $theme-blue !important;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.4);
        }

        &:hover {
          background: rgba($theme-blue, 0.1);
        }
      }
    }
  }
}

.modalWrapperMobile {
  background: $white;
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;

  &.mwMobile-kyc{
    overflow-y: auto;
    backdrop-filter: blur(4px);
    background: rgba(#000, 0.2);
    display: flex;
    align-items: center;
    .modalContent{
      padding: 20px 8px;
      margin: 20px 12px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      position: relative;
      .popup-heading {
          font-weight: 600;
          @include fontSize(20px);
          line-height: 24px;
          text-align: center;
          margin-bottom: 20px;
      }
    
      .grey_p {
        font-weight: 400;
        @include fontSize(15px);
        line-height: 18px;
        text-align: center;
        margin-bottom: 16px;
        color: #999;
      }
    
      .policy_number_box {
        background: #FFF9E6;
        color: #FFC929;
        border: 1px solid #FFC929;
        padding: 10px 16px;
        border-radius: 30px;
        @include fontSize(18px);
        display: inline-block;
      }
    
      h6 {
        font-weight: 600;
        @include fontSize(16px);
        line-height: 18px;
        margin-bottom: 16px;
        text-align: left;
    
        span {
          font-weight: 400;
          display: block;
          text-transform: uppercase;
          @include fontSize(15px);
          margin-top: 8px;
        }
      }
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: none;
        border-left: none;
        border-right: none;
        margin-bottom: 12px;
      }
            .close-button {
              background: transparent;
              color: #000;
              border-radius: 50px;
              @include position(absolute, $right: 16px, $top: 16px);
              $size: 30px;
              height: $size;
              width: $size;
              box-sizing: border-box;
              line-height: $size;
              z-index: 99;
      
              &:before,
              &:after {
                $width: 20px;
                $height: 1.5px;
                transform: rotate(-45deg);
                content: "";
                @include position(absolute, $right: 5px, $top: 14px);
                height: $height;
                width: $width;
                background-color: #000;
                transition: all 0.2s ease;
              }
      
              &:after {
                transform: rotate(-135deg);
              }
      
              &:hover {
                &:before {
                  background-color: #de4b4b;
                  transform: rotate(0deg);
                }
      
                &:after {
                  background-color: #de4b4b;
                  transform: rotate(-180deg);
                }
              }
            }
    }
  }

  .modalContent {
    background: $white;
    width: 100%;
    &:focus-visible{
      outline: none;
    }

    %btn {
      background: $theme-lightgreen;
      color: $white;
      @include border-radius(12px);
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      box-shadow: none;

      &:hover {
        background: lighten($theme-lightgreen, 5%);
        @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
      }
    }

    .greenLgBtn {
      @extend %btn;
      @include fontSize(14px);
      font-weight: 500;
      padding: 12px 30px;
      line-height: 21px;
    }

    .greenMdBtn {
      @extend %btn;
      @include border-radius(8px!important);
      @include fontSize(12px);
      padding: 8px 12px;
      line-height: 18px;
    }
    .greenSmBtn {
      @extend %btn;
      @include border-radius(4px!important);
      @include fontSize(12px);
      padding: 4px 12px;
      line-height: 18px;
    }

    h3 {
      @include fontSize(16px);
      font-weight: 400;
    }

    h5 {
      @include fontSize(14px);
      font-weight: 500;
    }

    p {
      @include fontSize(12px);
    }

    .iconBtn {
      @include fontSize(12px);
      display: inline-flex;
      align-items: center;
      line-height: 14px;
      color: $theme-blue;
      font-family: "Poppins";
      text-decoration: none;
      cursor: pointer;

      .mail {
        background: $theme-blue;
        color: $white;
        padding: 4px;
        @include border-radius(4px);
        margin-right: 4px;
        height: 20px;
        width: 20px;
      }
    }

    .header {
      background: $white;
      border-bottom: 1px solid rgba($black, 0.1);
      padding: 11px 12px;
      @include position(fixed, $left: 0px, $top: 0px);
      display: flex;
      width: 100%;
      height: 50px;
      z-index: 91;

      .backLink {
        color: $black;
        @include fontSize(14px);
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding: 5px 4px 4px 16px;
        cursor: pointer;
        text-decoration: none;

        &:before {
          background: $black;
          @include position(absolute, $left: 4px, $top: 9px);
          content: '';
          height: 8px;
          width: 1px;
          transform: rotate(45deg);
        }

        &:after {
          @extend :before;
          top: 14px;
          transform: rotate(-45deg);
        }
      }
    }

    .title {
      background: rgba(#1E71EC, 0.04);
      padding: 8px 0px;
      margin-top: 50px;
      margin-bottom: 24px;

      span {
        font-weight: 600;
      }
    }

    .heading {
      padding: 8px 0px;
      margin-bottom: 8px;

      span {
        font-weight: 600;
      }
    }

    .radioBox {
      button {
        @include border-radius(8px);
        border: 1px solid rgba(32, 56, 100, 0.20);
        text-transform: capitalize;
        margin: 6px 6px;
        padding: 8px 16px;
        height: 30px;
        @include fontSize(14px);
        font-weight: 500;
        color: #000;
        font-family: 'poppins';
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        &.Mui-selected {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.40);
          font-weight: 500
        }
      }

      &.healthradioBox {
        button {
          height: auto;
          display: block;

          $othericons: oneAdult,
            twoAdults;
          $k: 0;

          @each $icons in $othericons {
            $bacRightP: $k * 33;
            $k: $k +1;

            .#{$icons} {
              position: relative;
              background: url("../../public/images/health_adults_icons.svg") no-repeat -#{$bacRightP}px 0px/66px;
              height: 33px;
              width: 33px;
              margin: 0 auto 8px;
            }
          }

          &.Mui-selected {
            $othericons: oneAdult,
              twoAdults;
            $k: 0;

            @each $icons in $othericons {
              $bacRightP: $k * 33;
              $k: $k +1;

              .#{$icons} {
                background: url("../../public/images/health_adults_icons_blue.svg") no-repeat -#{$bacRightP}px 0px/66px;
              }
            }
          }
        }
      }
    }
    
    .claim_txt {
      @include fontSize(14px);
      font-weight: 500;
    }

    .income_txt {
      @include fontSize(12px);
      font-weight: 400;

      span {
        @include fontSize(16px);
        font-weight: 500;
        color: $theme-lightgreen;
      }
    }

    .greyBg {
      background: rgba(#1E71EC, 0.04);
      min-height: calc(100vh - 60px);
      overflow-y: auto;
      padding: 50px 12px 80px;
      margin-left: -12px;
      margin-right: -12px;
    }

    .m_viewPolicyWrapper {
      .main_heading {
        background: rgba(#1E71EC, 0.04);
        padding: 8px 22px;
        margin-bottom: 20px;
      }

      .viewpolicyDetails {
        padding: 0 15px 0 22px;

        .img_box {
          padding: 8px 16px;
          border: 1px solid #E3E9F4;
          @include border-radius(8px);
          display: inline-block;
          margin-bottom: 15px;
          img {
            height: 50px;
          }
        }

        .viewpolicyDetails_inner {
          h5 {
            @include fontSize(14px);
            font-weight: 400;
            line-height: 21px;
            color: #252525;
            opacity: 0.7;
            margin-bottom: 0;
          }

          p {
            @include fontSize(14px);
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 0;

            span {
              color: #D9132E;
            }
          }
        }

        .payment_box {
          @include border-radius(15px);
          background: linear-gradient(270deg, rgba(225, 237, 255, 0.14) 0%, rgba(225, 237, 255, 0.63) 100%);
          padding: 15px;
          margin-bottom: 15px;

          .m_innerbox {
            display: flex;
            align-items: center;

            .m_icon_box {
              background: $white;
              padding: 8px;
              @include border-radius(10px);
              margin-right: 15px;
            }

            h5 {
              @include fontSize(14px);
              line-height: 21px;
              font-weight: 400;

              span {
                font-weight: 600;
                display: block
              }
            }
          }
        }

        .m_policy_addon {
          margin-bottom: 15px;

          h4 {
            @include fontSize(16px);
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 15px;
          }

          .m_policyAddonCovers {
            margin-bottom: 0;

            li {
              color: rgba(37, 37, 37, 0.70);
              @include fontSize(14px);
              font-weight: 400;
              line-height: 21px;
              margin-bottom: 10px;
              position: relative;
              display: flex;
              align-items: center;

              &:last-child {
                margin-bottom: 0;
              }

              .iconBtn {
                .icon {
                  color: $theme-green;
                  background: #F4F4F4;
                  margin-right: 10px;
                  @include border-radius(8px);
                }

                .MuiSvgIcon-root {
                  @include fontSize(16px);
                }
              }
            }
          }
        }
      }

      hr {
        border-top: 1.5px solid #E3E9F4;
        border-bottom: 0;
        margin: 0 10px;
      }

      .m_rating {
        text-align: center;
        margin: 15px 0;

        h5 {
          @include fontSize(14px);
          font-weight: 600;
          line-height: 21px;
          color: #252525;
          margin-bottom: 10px;

          span {
            display: block;
            font-weight: 400;
            opacity: 0.7;
          }
        }
      }

      .MuiRating-root {
        .MuiRating-icon {
          color: $theme-yellow;

          .MuiSvgIcon-root {
            font-size: 35px;
          }
        }
      }

      .m_actionbox {
        @include border-radius(15px);
        padding: 10px 20px;
        background: linear-gradient(270deg, rgba(225, 237, 255, 0.14) 0%, rgba(225, 237, 255, 0.63) 100%);
        text-align: center;
        height: 44px;
      }
    }

    .addonList {
      li {
        @include fontSize(12px);
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        span {
          font-weight: 500;
        }
      }
    }

    .policyDetails {
      background: $white;
      @include border-radius(8px);
      @include box-shadow(0px 4px 12px rgba(#1E71EC, 0.1));
      padding: 8px;

      .insurerLogo {
        height: 60px;
      }

      p {
        color: rgba($black, 0.5);
        @include fontSize(11px);

        span {
          color: $black;
          @include fontSize(12px);
          font-weight: 500;
        }
      }

      .planName {
        @include fontSize(11px);
        color: $black;
        font-weight: 400;
      }

      h5 {
        color: rgba($black, 0.5);
        @include fontSize(11px);

        span {
          color: $black;
          display: block;
          @include fontSize(12px);
          font-weight: 500;
        }
      }

      .coverdMembers {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        p {
          @include fontSize(11px);
          font-weight: 400;
          color: #000000;
        }

        .members {
          display: flex;
          align-items: center;

          .memberIcon {
            background-color: $white;
            border: 1px solid rgba($black, 0.2);
            @include box-shadow(0px 4px 16px rgba($theme-blue, 0.2));
            @include border-radius(40px);
            margin-left: -10px;
            width: 26px;
            height: 26px;

            $producticons: father,
              mother,
              husband,
              wife,
              son,
              daughter,
              childson,
              childdaughter;
            $k: 0;

            @each $icons in $producticons {
              $bacRightP: $k * 26;
              $k: $k +1;

              &.#{$icons} {
                background: $white url("../../public/images/policy-members.svg") no-repeat -#{$bacRightP}px 2px/234px;
              }
            }
          }
        }
      }
    }

    .whiteBoxFlex {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      margin-bottom: 16px;

      li {
        background: $white;
        @include border-radius(8px);
        padding: 12px;
        align-items: center;
        flex: 1 0 0;

        h5 {
          @include fontSize(16px);
          margin-bottom: 4px;
          color: $theme-blue;
        }

        p {
          @include fontSize(11px);
          line-height: 14px;
          opacity: 0.6;
        }
      }
    }

    .whiteGradientBox {
      padding: 0px 12px 12px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
      box-shadow: 0px 10px 12px 0px rgba(30, 113, 236, 0.10);
      @include border-radius(0 0 12px 12px);
      border: 1px dashed #203864;
      border-top: none;

      .gst_txt,
      .total_txt {
        @include fontSize(14px);
        font-weight: 500;

        span {
          float: right;
          color: $theme-blue;
        }
      }

      .total_txt {
        span {
          color: $red;
        }
      }

      ul {
        li {
          @include fontSize(12px);
          padding-left: 20px;
          position: relative;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0px;
          }

          &:before {
            @include position(absolute, $left: 1px, $top: 6px);
            content: '';
            border-left: 1px solid $black;
            border-bottom: 1px solid $black;
            transform: rotate(-45deg);
            height: 4px;
            width: 10px;
          }
        }
      }
    }

    .baseCoverList {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 40px;

        h6 {
          @include fontSize(12px);
          font-weight: 500;
          margin-bottom: 8px
        }

        // &:last-child {
        //   margin-bottom: 0px
        // }

        $othericons: carAccident, antiTheft, TpLiabilities, deathOwner, manMadeHazards;
        $k: 0;

        @each $icons in $othericons {
          $bacRightP: $k * 32;
          $k: $k +1;

          .#{$icons} {
            &:before {
              background: url("../../public/images/basecover-icons.svg") no-repeat -#{$bacRightP}px 0px/160px;
              @include position(absolute, $left: 0px, $top: calc(50% - 16px));
              content: '';
              height: 32px;
              width: 32px;
            }
          }
        }

        p {
          @include fontSize(12px);
          font-weight: 400;
          color: #7B7D7F;
        }
      }
    }

    .yellowtext_tip {
      background: #FFEECA;
      padding: 8px 16px;
      @include fontSize(10px);
      color: #E29A00;
      border-radius: (100px);
      display: inline-block;
    }

    .carExclusionList {
      li {
        @include fontSize(11px);
        line-height: 14px;
        font-weight: 400;
        margin-bottom: 6px;
        padding-left: 20px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .MuiSvgIcon-root {
          @include position(absolute, $left: 0px, $top: 1px);
          @include fontSize(12px)
        }
      }
    }

    .progressBar {
      margin-top: 24px;
      margin-bottom: 80px;

      .MuiGrid2-container {
        margin-left: -8px;
        margin-right: -8px;
      }

      .box {
        text-align: center;
        padding: 0px 8px 8px 8px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, $white 100%);
        box-shadow: 0px 4px 4px 0px rgba(30, 113, 236, 0.10);
      }

      .CircularProgressbar {
        margin-bottom: 8px;
        height: 60px;
        width: 60px;

        .CircularProgressbar-trail {
          stroke: #CCD3DF;
        }

        .CircularProgressbar-path {
          stroke: $theme-blue;
        }

        .CircularProgressbar-text {
          fill: #000000;
          @include fontSize(20px);
          font-weight: 600;
          vertical-align: middle;
          text-anchor: middle;
          dominant-baseline: middle;
        }
      }

      p {
        @include fontSize(11px);
        line-height: 14px;
        opacity: 0.6;
      }
    }

    .toggleSwitch {
      text-align: right;

      .infoicon {
        color: lighten($color: #000000, $amount: 30%);
        @include fontSize(15px);
        position: relative;
        top: 3px;
        margin-left: 4px;
      }

      .MuiTypography-root {
        font-weight: 500;
        @include fontSize(14px);
      }

      .MuiSwitch-root {
        .Mui-checked {
          color: $theme-lightgreen;
        }

        .Mui-checked+.MuiSwitch-track {
          background-color: $theme-lightgreen;
        }
      }

      .MuiSwitch-track {
        background: #D4E0F5;
      }

      .MuiSwitch-thumb {
        box-shadow: 0px 4px 4px 0px rgba(30, 113, 236, 0.10);
      }
    }

    .networkList {
      margin-bottom: 80px;

      li {
        text-align: left;
        border-bottom: 1px dashed rgba($theme-blue, 0.2);
        padding-bottom: 8px;
        margin-bottom: 8px;

        h5 {
          @include fontSize(12px);
          font-weight: 500;
        }

        p {
          @include fontSize(11px);
          opacity: 0.5;
          line-height: 14px;
        }

        h6 {
          @include fontSize(11px);
        }
      }
    }

    .rangeSlider {
      color: $theme-lightgreen;
      height: 6px;

      .MuiSlider-rail {
        opacity: 0.2;
      }

      .Mui-focusVisible {
        @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
      }

      .MuiSlider-thumb {
        height: 24px;
        width: 24px;

        &:hover {
          @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
        }
      }
    }

    .radio_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 8px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: #203864;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.40);
        }

        &:hover {
          background: rgba(#203864, 0.2);
        }
      }
    }

    // Tabs
    .tabs-section {
      .tabFooter {
        @include position(fixed, $left: 0px, $bottom: 0px);
        @include box-shadow(0px 0px 20px rgba($theme-blue, 0.2));
        background: $white;
        text-align: center;
        padding: 12px 4px;
        width: 100%;

        .MuiTabs-root {
          min-height: 20px;
          display: inline-flex;
        }

        .MuiTabs-indicator {
          display: none;
        }

        .MuiTabs-flexContainer {
          justify-content: center;
          max-width: 504px;
          margin: auto;
          outline: 1px dashed $theme-lightgreen;
          outline-offset: -1px;
          border-radius: 100px;
          overflow: hidden;

          .MuiTab-root {
            min-height: 20px;
            text-transform: capitalize;
            padding: 9.5px 16px;
            @include fontSize(12px);
            line-height: 14px;
            font-weight: 400;
            color: $black;
            // width: 100px;

            &.Mui-selected {
              background: $theme-lightgreen;
              color: $white;
            }
          }

        }
      }

      .tabPanel {
        padding: 0px;
      }
    }

    .coveredFeatures {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        @include fontSize(11px);
        line-height: 14px;
        font-weight: 400;
        margin-bottom: 6px;
        position: relative;
        padding-left: 18px;

        &:last-child {
          margin-bottom: 0;
        }

        .MuiSvgIcon-root {
          @include position(absolute, $left:0px, $top:1px);
          @include fontSize(12px)
        }
      }
    }

    .gradientBoxFeatures {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 12px;
      flex: 1 0 0;
      flex-wrap: wrap;

      li {
        min-width: 45%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        @include box-shadow(0px 4px 4px rgba($theme-blue, 0.1));
        @include border-radius(0px 0px 8px 8px);
        display: flex;
        padding: 8px 8px 8px 44px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;
        position: relative;

        p {
          @include fontSize(12px);
          line-height: 14px;
          opacity: 0.5
        }

        h5 {
          @include fontSize(11px);
          font-weight: 500;
          line-height: 14px;
        }

        &:before {
          content: '';
          @include position(absolute, $left: 12px, $top: calc(50% - 12px));
          margin-right: 12px;
          height: 24px;
          width: 24px;
        }

        $producticons: army, corona, hospital, masks, cough, medicens, doctor, ambulance;
        $k: 0;

        @each $icons in $producticons {
          $bacRightP: $k * 24;
          $k: $k +1;

          &.#{$icons}:before {
            background: url("../../public/images/medical-icons.svg") no-repeat -#{$bacRightP}px 0px/480px;
          }
        }

        $producticons: amount, bonus, hospital, healthcheckups, accident, doc, extrapremium, location, treatments, recharge, searched, familys, discount;
        $k: 0;

        @each $icons in $producticons {
          $bacRightP: $k * 24;
          $k: $k +1;

          &.#{$icons}:before {
            background: url("../../public/images/addon-icons.svg") no-repeat -#{$bacRightP}px 0px/312px;
          }
        }

        $covers: preexisting,
          medicalhistory,
          healthcheckup,
          treatment,
          medicines,
          hospitalized,
          alcohol,
          smoking,
          tobaco,
          intoxication,
          group,
          comorbidity,
          bloodpressure,
          cholesterol,
          bloodsugar,
          spinal,
          uterus,
          ulcer,
          headache,
          hernia,
          stroketia,
          thyroid,
          earnose,
          cholelithiasis,
          hemorrhoids,
          prostrate,
          goodhealth,
          claimed,
          insurancepolicy,
          liverdiseases;
        $k: 0;

        @each $icons in $covers {
          $bacRightP: $k * 24;
          $k: $k +1;

          &.#{$icons}:before {
            background: url("../../public/images/medical-health-icons.svg") no-repeat -#{$bacRightP}px 0px/720px;
          }
        }

        $producticons: hivaids,
          cancer,
          heartdisease,
          jointpain,
          kidneydiesase,
          paralysis,
          lungs,
          endocrinesystem,
          insurancedeclined,
          notmentioned,
          maternity,
          pregnancy,
          brainstroke,
          sle,
          slippeddisc,
          deviatednasal,
          mentalillness,
          handicaped,
          bloodtest,
          bpl,
          unorganizedsector,
          informalsector,
          hazardousactivity,
          insulin,
          eyes,
          wounds,
          politician,
          abroad,
          convicted;
        $k: 0;

        @each $icons in $producticons {
          $bacRightP: $k * 24;
          $k: $k +1;

          &.#{$icons}:before {
            background: url("../../public/images/medical-term-icons.svg") no-repeat -#{$bacRightP}px 0px/696px;
          }
        }

      }
    }

    .whiteBoxes {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 12px;
      align-self: stretch;
      flex-wrap: wrap;

      li {
        min-width: 45%;
        background: $white;
        @include border-radius(8px);
        padding: 12px 16px;
        margin-bottom: 12px;
        flex: 1 0 0;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-direction: column;
        justify-content: center;

        h5 {
          @include fontSize(12px);
          font-weight: 400;
        }

        h4 {
          @include fontSize(16px);
          font-weight: 500;
          color: $theme-blue;
        }

        p {
          @include fontSize(11px);
          opacity: 0.5;
          line-height: 14px;
        }
      }

      &.term-Whitebox {
        li {
          width: 180px;
          min-width: auto;
        }
      }
    }

    .exclusions {
      border-left: 1px dashed $theme-blue;
      border-right: 1px dashed $theme-blue;
      border-bottom: 1px dashed $theme-blue;
      padding: 0px 12px 12px;
      @include box-shadow(0px 10px 12px rgba($theme-blue, 0.1));
      @include border-radius(0px 0px 16px 16px);

      li {
        @include fontSize(12px);
        margin-bottom: 8px;
        position: relative;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        &:before {
          @include position(absolute, $left: 1px, $top: 6px);
          content: '';
          border-left: 1px solid $black;
          border-bottom: 1px solid $black;
          transform: rotate(-45deg);
          height: 3px;
          width: 8px;
        }
      }
    }

    .ch_heading {
      @include fontSize(16px);
      font-weight: 400;
      margin-bottom: 4px;

      span {
        font-weight: 500;
      }
    }

    .ch_p {
      @include fontSize(11px);
      font-weight: 400;
      line-height: 14px;
      opacity: 0.5;
    }

    .searchBox {
      height: 36px;
      width: 260px;
      svg {
        top: 7px;
      }
      input {
        border: none !important;
        font-size: 0.875rem;
        height: 36px;
        width: 100%;
        padding: 0px 12px 0px 34px;
      }
    }

    .comparisonWrapper {
      background: #F6F9FE;

      .comparisonHeader {
        background: $white;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(#1E71EC, 0.2);
        padding: 8px 0px;

        .featureList {
          width: 270px;
          max-width: 270px;
          h4 {
            @include fontSize(14px);
              font-weight: 500;
          }
        }

        div {
          flex: 1 0 0;
          position: relative;
        }

        p {
          @include fontSize(10px);
          min-height: 45px;
        }

        h5 {
          @include fontSize(11px);
          font-weight: 400;
          color: rgba($black, 0.5);
          text-align: right;
          margin-bottom: 4px;

          span {
            @include fontSize(12px);
            font-weight: 500;
            color: $red;
            display: inline-block;
          }
        }
      }

      .comparisonList {
        overflow-y: auto;
        height: calc(100vh - 206px);

        ul {
          display: flex;
          border-bottom: 1px dashed rgba($theme-blue, 0.2);

          &:last-child {
            border: none;
          }

          li {
            background: $white;
            border-right: 1px dashed rgba($theme-blue, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex: 1 0 0;
            @include fontSize(11px);
            line-height: 14px;
            // min-height: 42px;
            padding: 8px 4px;

            &:first-child {
              background: transparent;
              @include box-shadow(inset -10px 0px 10px -8px rgba(#1E71EC, 0.1));
              border: none;
              justify-content: left;
              text-align: left;
              width: 270px;
              max-width: 270px;
            }

            &:last-child {
              border: none;
            }

            .MuiSvgIcon-root {
              border: 1px dashed;
              @include border-radius(40px);
              padding: 4px;
              height: 20px;
              width: 20px;
            }

            .MuiSvgIcon-root[data-testid="DoneIcon"] {
              border-color: $theme-green;
              color: $theme-green;
            }

            .MuiSvgIcon-root[data-testid="CloseIcon"] {
              border-color: $red;
              color: $red;
            }
          }
        }
      }
    }

    .varient_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 6px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        // &:hover {
        //     background: rgba(#203864, 0.2);
        // }
      }
    }
  }
}

.sliding-panel-container {
  position: fixed;
  top: 0;
  left: 0;

  &.active {
    z-index: 999;
    background: rgba(32, 56, 100, 0.15);


    .panel {
      // transition: transform 1s ;
      animation: inpanel 0.6s both;
    }

    @keyframes inpanel {
      0% {
        transform: translateX(1000px);
      }

      100% {
        transform: translateX(0px);
      }
    }
  }

  .glass {
    min-width: calc(100vw - 700px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  .panel {
    height: 100%;
    width: 100%;
    max-width: 700px;
    background-color: white;
    animation: outpanel 0.3s both;
    overflow: hidden!important;
  }

  @keyframes outpanel {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(1000px);
    }
  }

  .panel-content {
    .close-button {
      background: transparent;
      color: #000;
      @include border-radius(50%);
      $size: 40px;
      height: $size;
      width: $size;
      box-sizing: border-box;
      line-height: $size;
      z-index: 99;
      display: inline-block;
      box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.15);
      position: relative;
      top: 4px;

      &:before,
      &:after {
        $width: 15px;
        $height: 1.5px;
        transform: rotate(-45deg);
        content: "";
        @include position(absolute, $left: 12px, $top: 20px);
        height: $height;
        width: $width;
        background-color: #000;
        transition: all 0.2s ease;
      }

      &:after {
        transform: rotate(-135deg);
      }

      &:hover {
        &:before {
          background-color: #de4b4b;

          transform: rotate(0deg);
        }

        &:after {
          background-color: #de4b4b;
          transform: rotate(-180deg);
        }
      }
    }

    svg[data-testid="CloseIcon"] {
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: rotate(-90deg);
        color: red;
      }
    }

    hr {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .scrollable_area {
      height: calc(100vh - 76px);
      overflow-y: auto;

      .addonlist {
        margin-top: 16px;
        list-style: none;
      }
    }

    label {
      margin-right: 0px;
      margin-left: 0px;

      .MuiCheckbox-root {
        padding: 0px;
        margin-right: 2px;
      }

      .MuiSvgIcon-root {
        // color: $theme-green;
        margin-right: 4px;
      }

      .Mui-checked {
        color: $theme-green;
      }

      .MuiTypography-root {
        @include fontSize(14px);
        font-family: "Roboto";
        font-weight: 400;
      }
    }

    .acessoriesDiv {
      @include box-shadow(0px 0px 6px rgba($black, 0.1));
      padding: 10px;
      @include border-radius(8px);
    }

    .menupopup {
      padding: 0px 0px 100px;
      overflow: auto;
      max-height: calc(100vh - 65px);
      margin-top: 65px;

      .greenMdBtn {
        background: $white;
        @include fontSize(8px);
        font-weight: 500;
        padding: 8px 24px;
        color: $theme-lightgreen;
        box-shadow: none;
        border: 1px solid $theme-lightgreen;
        line-height: 16px;
      }

      h5 {
        @include fontSize(16px);
        font-weight: 500;
        margin-bottom: 12px;
      }

      .nav-section {
        li {
          margin-bottom: 4px;
          line-height: 21px;

          &:last-child {
            margin-bottom: 0;
          }

          .nav-link {
            text-decoration: none;
            color: #000;
            @include fontSize(14px);
            font-weight: 400;
            font-family: 'poppins';
            cursor: pointer;
            padding: 4px 0px;
            display: inline-block;
          }
        }
      }

      hr {
        border: none;
        border-top: 1px dashed #D2D7E0;
      }

      .ca-link {
        text-decoration: none;
        @include fontSize(14px);
        color: $black;
        padding: 8px 24px;
        @include border-radius(30px);
        border: 1px solid $black;
        position: relative;
        margin-top: 24px;
        display: inline-block;

        &:hover {
          color: $theme-lightgreen;
          border: 1px solid $theme-lightgreen;
        }

        span {
          @include position(absolute, $top: -11px, $right: 12px);
          @include border-radius(30px);
          background: #F8E007;
          color: $black;
          padding: 0px 8px;
          display: inline;

          p {
            @include fontSize(9px);
            font-weight: 500;
          }

          .blink_text {
            animation-name: blinker;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          &.boxblink {
            animation-name: boxblinker;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          @keyframes blinker {
            0% {
              opacity: 1.0;
            }

            50% {
              opacity: 0.1;
            }

            100% {
              opacity: 1.0;
            }
          }

          @keyframes boxblinker {
            0% {
              scale: 1;
            }

            50% {
              scale: 0.8;
            }

            100% {
              scale: 1;
            }
          }
        }
      }
            .pir-link {
              background: #E1EDFF;
              @include box-shadow(0px 6px 8px rgba($theme-blue, 0.1));
              text-decoration: none;
              @include fontSize(14px);
              color: $theme-blue;
              display: inline-block;
              padding: 9px 22px;
              @include border-radius(30px);
              border: none;
              position: relative;
      
              span {
                @include position(absolute, $top: -12px, $right: 22px);
                @include border-radius(30px);
                background: $red;
                color: $white;
                padding: 0px 8px;
                display: inline;
                transition: all ease-in-out 0.5s;
      
                p {
                  @include fontSize(9px);
                  font-weight: 600;
                }
      
              }
            }
            .pos-link {
              background: $theme-blue;
              @include box-shadow(0px 6px 8px rgba($theme-blue, 0.1));
              text-decoration: none;
              @include fontSize(14px);
              color: $white;
              display: inline-block;
              padding: 9px 16px;
              @include border-radius(30px);
              margin-top: 8px;
              border: none;
              position: relative;
            }
    }
  }
}

// logout popup css
.modalWrapperLogout {
  height: 100%;
  background: rgba(#000000, 0.2);
  backdrop-filter: blur(4px);

  .MuiBackdrop-root {
    background: transparent !important;
  }

  .modalContent {
    background: $white;
    margin: 12px;
    padding-top: 40px;
    padding-bottom: 30px;
    box-shadow: 0px 4px 40px 0px rgba(37, 37, 37, 0.15);
    @include border-radius(15px);
    @include position(absolute, $top: 50%);
    transform: translateY(-50%);
    max-width: 420px;

    .m_loginPopup {
      h3 {
        @include fontSize(18px);
        line-height: 27px;
        font-weight: 600;
      }
    }

    .closePopup {
      @include border-radius(40px);
      @include position(absolute, $top: 0px, $right: 0);
      padding: 0px;
      height: 40px;
      width: 40px;

      &:before {
        background: $black;
        content: '';
        @include position(absolute, $right: 20px, $top: 15px);
        transform: rotate(45deg);
        height: 15px;
        width: 1.4px;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
      }

      &:hover {
        background: none;
      }
    }

    .greenXlBtn {
      @extend %btn;
      @include fontSize(14px);
      padding: 12px 30px;
      line-height: 21px;

      @media(max-width:380px) {
        padding: 12px 15px;
      }
    }
  }
}

.modalFullPageWrapper {
  background: $white;
  @include position(fixed, $top: 0px, $left: 0px);
  // overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;

  .modalContent {
    background: $white;
    // padding: 0px 12px;
    // max-width: 900px;
    width: 100%;

    .policyDetailWrapperr {
      padding-top: 60px;

      .policyDetailHeader {
        @include position(fixed, $top: 0px, $left: 0px);
        z-index: 9;
        // border-bottom: 1px solid rgba($black, 0.1);
        background: $white;
        padding: 13px 16px;
        width: 100%;

        .MuiGrid2-container {
          max-width: 100% !important;
        }

        .backStep {
          margin-right: 8px;
          color: #000000;
          cursor: pointer;

          &:hover {
            color: $theme-green;

            svg {
              left: -4px;
            }
          }

          svg {
            position: relative;
            transition: all 0.5s ease;
            left: 0px;
            top: 3px;
            height: 26px;
            width: 30px;
          }
        }

        p {
          @include fontSize(18px);
          font-weight: 400;

          span {
            font-weight: 600
          }
        }

        .sendQuoteBtn {
          @include fontSize(12px);
          line-height: 18px;
          color: #203864;
          font-family: "Poppins";
          text-decoration: none;
          cursor: pointer;
          position: relative;
          bottom: 3px;

          .mail {
            background: #203864;
            color: $white;
            // @include fontSize(14px);
            padding: 4px;
            @include border-radius(6px);
            margin-right: 4px;
            position: relative;
            top: 7px;
          }
        }
      }

      .pd-contentSection {
        background: #F6F9FE;
        padding: 24px;
        min-height: calc(100vh - 60px);

        .MuiGrid2-container {
          max-width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }

        .quote-specs {
          .planName_box {
            // max-width: 300px;
            background: $white;
            @include border-radius(8px);
            padding: 12px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 4px 12px 0px rgba(30, 113, 236, 0.10);

            img {
              height: 80px;
              width: 140px;
              margin-right: 12px
            }

            p {
              @include fontSize(12px);
              font-weight: 400;
              color: #7B7D7F;

              span {
                display: block;
                margin-top: 8px;
                color: #000000
              }
            }
          }

          .coverdMembers {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            p {
              @include fontSize(12px);
              margin-right: 12px;
            }

            .members {
              display: flex;
              align-items: center;

              .memberIcon {
                background-color: $white;
                border: 1px solid rgba($black, 0.2);
                @include box-shadow(0px 4px 16px rgba($theme-blue, 0.2));
                @include border-radius(40px);
                margin-left: -10px;
                width: 40px;
                height: 40px;

                $producticons: father,
                  mother,
                  husband,
                  wife,
                  son,
                  daughter,
                  childson,
                  childdaughter;
                $k: 0;

                @each $icons in $producticons {
                  $bacRightP: $k * 40;
                  $k: $k +1;

                  &.#{$icons} {
                    background: $white url("../../public/images/policy-members.svg") no-repeat -#{$bacRightP}px 2px/360px;
                  }
                }
              }
            }
          }

          .planDetailBox {
            p {
              @include fontSize(12px);
              font-weight: 400;
              color: #7B7D7F;

              span {
                display: block;
                margin-top: 4px;
                @include fontSize(14px);
                font-weight: 500;
                color: #000000;
              }
            }

            .premium_txt {
              @include fontSize(12px);
              font-weight: 400;
              color: #7B7D7F;

              span {
                display: block;
                margin-top: 0px;
                @include fontSize(18px);
                font-weight: 500;
                color: #D9132E;
              }
            }
          }
        }

        hr {
          border-top: 1px solid #CBD2DF;
          border-bottom: none;
          border-left: none;
          border-right: none;
        }

        .carExclusionList {
          li {
            @include fontSize(14px);
            font-weight: 400;
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }

            .MuiSvgIcon-root {
              position: relative;
              top: 3px;
              margin-right: 10px;
              @include fontSize(16px)
            }
          }
        }

        .coveredFeatures {
          @extend .carExclusionList;
          display: flex;
          align-items: flex-start;
          align-content: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          flex-wrap: wrap;

          li {
            display: flex;
            min-width: 220px;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            margin-bottom: 0px;

            .MuiSvgIcon-root {
              top: 0px;
              margin-right: 0px;
              color: $theme-blue;
            }
          }
        }

        .gradientBoxFeatures {
          display: flex;
          align-items: flex-start;
          align-content: flex-start;
          gap: 12px;
          flex: 1 0 0;
          flex-wrap: wrap;

          li {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
            @include box-shadow(0px 4px 4px rgba($theme-blue, 0.1));
            @include border-radius(0px 0px 8px 8px);
            display: flex;
            min-width: 220px;
            padding: 12px 16px 12px 60px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;
            position: relative;

            p {
              @include fontSize(12px);
              line-height: 18px;
            }

            h5 {
              font-size: 14px!important;
              font-weight: 500;
              line-height: 21px;
            }

            &:before {
              content: '';
              @include position(absolute, $left: 12px, $top: 14px);
              margin-right: 12px;
              height: 40px;
              width: 40px;
            }

            $producticons: army, corona, hospital, masks, cough, medicens, doctor, ambulance;
            $k: 0;

            @each $icons in $producticons {
              $bacRightP: $k * 40;
              $k: $k +1;

              &.#{$icons}:before {
                background: url("../../public/images/medical-icons.svg") no-repeat -#{$bacRightP}px 0px/800px;
              }
            }

            $producticons: amount, bonus, hospital, healthcheckups, accident, doc, extrapremium, location, treatments, recharge, searched, familys, discount;
            $k: 0;

            @each $icons in $producticons {
              $bacRightP: $k * 40;
              $k: $k +1;

              &.#{$icons}:before {
                background: url("../../public/images/addon-icons.svg") no-repeat -#{$bacRightP}px 0px/520px;
              }
            }

            $covers: preexisting,
              medicalhistory,
              healthcheckup,
              treatment,
              medicines,
              hospitalized,
              alcohol,
              smoking,
              tobaco,
              intoxication,
              group,
              comorbidity,
              bloodpressure,
              cholesterol,
              bloodsugar,
              spinal,
              uterus,
              ulcer,
              headache,
              hernia,
              stroketia,
              thyroid,
              earnose,
              cholelithiasis,
              hemorrhoids,
              prostrate,
              goodhealth,
              claimed,
              insurancepolicy,
              liverdiseases;
            $k: 0;

            @each $icons in $covers {
              $bacRightP: $k * 40;
              $k: $k +1;

              &.#{$icons}:before {
                background: url("../../public/images/medical-health-icons.svg") no-repeat -#{$bacRightP}px 0px/1200px;
              }
            }

            $producticons: hivaids,
              cancer,
              heartdisease,
              jointpain,
              kidneydiesase,
              paralysis,
              lungs,
              endocrinesystem,
              insurancedeclined,
              notmentioned,
              maternity,
              pregnancy,
              brainstroke,
              sle,
              slippeddisc,
              deviatednasal,
              mentalillness,
              handicaped,
              bloodtest,
              bpl,
              unorganizedsector,
              informalsector,
              hazardousactivity,
              insulin,
              eyes,
              wounds,
              politician,
              abroad,
              convicted;
            $k: 0;

            @each $icons in $producticons {
              $bacRightP: $k * 40;
              $k: $k +1;

              &.#{$icons}:before {
                background: url("../../public/images/medical-term-icons.svg") no-repeat -#{$bacRightP}px 0px/1160px;
              }
            }



          }
        }

        .whiteBoxes {
          display: flex;
          align-items: flex-start;
          align-content: flex-start;
          gap: 24px;
          align-self: stretch;
          flex-wrap: wrap;

          li {
            background: $white;
            @include border-radius(8px);
            padding: 12px 16px;
            margin-bottom: 12px;
            min-width: 220px;
            flex: 1 0 0;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            flex-direction: column;
            justify-content: center;

            h5 {
              @include fontSize(14px);
              font-weight: 400;
            }

            h4 {
              @include fontSize(18px);
              font-weight: 500;
              color: $theme-blue;
            }

            p {
              @include fontSize(12px);
              opacity: 0.5;
              line-height: 18px;
            }
          }

          &.term-Whitebox {
            li {
              width: 180px;
              min-width: auto;
            }
          }
        }

        .exclusions {
          border-left: 1px dashed $theme-blue;
          border-right: 1px dashed $theme-blue;
          border-bottom: 1px dashed $theme-blue;
          padding: 0px 12px 12px;
          @include box-shadow(0px 10px 12px rgba($theme-blue, 0.1));
          @include border-radius(0px 0px 16px 16px);

          li {
            @include fontSize(14px);
            margin-bottom: 8px;
            position: relative;
            padding-left: 24px;

            &:last-child {
              margin-bottom: 0px;
            }

            &:before {
              @include position(absolute, $left: 1px, $top: 6px);
              content: '';
              border-left: 1px solid $black;
              border-bottom: 1px solid $black;
              transform: rotate(-45deg);
              height: 4px;
              width: 10px;
            }
          }
        }

        .tabs-section {
          .MuiTabs-flexContainer {
            justify-content: center;
            max-width: 504px;
            margin: auto;
            outline: 1px dashed $theme-lightgreen;
            outline-offset: -1px;
            border-radius: 100px;
            overflow: hidden;

            .MuiTab-root {
              min-height: 37px;
              text-transform: capitalize;
              padding: 8px 20px;
              @include fontSize(14px);
              font-weight: 400;
              font-family: 'poppins';
              color: #000000;

              &.Mui-selected {
                background: $theme-lightgreen;
                color: $white;
              }
            }
          }

          .MuiTabs-indicator {
            display: none;
          }

          .tabPanel {
            padding-left: 0px;
            padding-right: 0px;
            // animation: slideDown 0.8s both;

            h5 {
              @include fontSize(18px);
              font-weight: 500;
            }

            .addonList {
              li {
                list-style-type: none;
                @include fontSize(14px);
                font-family: "poppins";
                margin-bottom: 12px;

                &:last-child {
                  margin-bottom: 0;
                }

                span {
                  font-weight: 600;
                  float: right;
                }
              }
            }

            .whiteBox {
              padding: 24px 20px;
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
              box-shadow: 0px 10px 12px 0px rgba(30, 113, 236, 0.10);
              @include border-radius(0 0 12px 12px);
              border: 1px dashed #203864;
              border-top: none;

              .gst_txt,
              .total_txt {
                @include fontSize(16px);
                font-weight: 500;

                span {
                  font-weight: 600;
                  float: right;
                  color: #203864;
                }
              }

              .total_txt {
                span {
                  color: #D9132E;
                }
              }
            }

            .yellowtext_tip {
              background: #FFEECA;
              padding: 8px 16px;
              @include fontSize(12px);
              color: #E29A00;
              border-radius: (100px);
              display: inline-block;
            }

            .baseCoverList {
              li {
                margin-bottom: 16px;
                display: flex;
                align-items: center;

                &:last-child {
                  margin-bottom: 0px
                }

                $othericons: carAccident, antiTheft, TpLiabilities, deathOwner, manMadeHazards;
                $k: 0;

                @each $icons in $othericons {
                  $bacRightP: $k * 50;
                  $k: $k +1;

                  .#{$icons} {
                    // position: relative;
                    background: url("../../public/images/basecover-icons.svg") no-repeat -#{$bacRightP}px 0px/250px;
                    height: 50px;
                    width: 50px;
                    display: block;
                    min-width: 50px;
                    margin-right: 10px
                  }
                }

                h6 {
                  @include fontSize(14px);
                  font-weight: 500;
                  margin-bottom: 8px
                }

                p {
                  @include fontSize(12px);
                  font-weight: 400;
                  color: #7B7D7F;
                }
              }
            }

            .ch_text {
              @include fontSize(18px);
              font-weight: 400;
              text-align: center;

              span {
                font-weight: 600;
              }
            }

            .cg-table {
              .tabletop-strip {
                border-radius: 12px 12px 0px 0px;
                background: #E0EBFC;
                padding: 12px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;

                span {
                  @include fontSize(14px);
                  font-weight: 500;
                }
              }

              .cg_list {
                li {
                  padding-bottom: 12px;
                  margin-bottom: 12px;
                  border-bottom: 1px dashed #CBD2DF;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  &:last-child {
                    margin-bottom: 0
                  }

                  .hospital_name {
                    @include fontSize(14px);
                    font-weight: 500;

                    span {
                      font-weight: 400;
                      opacity: 0.5;
                      display: block;
                      margin-top: 4px;
                    }
                  }

                  .city {
                    @include fontSize(14px);
                    font-weight: 400;
                  }
                }
              }
            }

            .progressBar {
              .MuiGrid2-container {
                margin-left: -12px;
                margin-right: -12px;
              }

              .box {
                text-align: center;
                padding: 0px 12px 12px 12px;
                border-radius: 8px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
                box-shadow: 0px 4px 4px 0px rgba(30, 113, 236, 0.10);
              }

              .CircularProgressbar {
                // padding: 12px;
                color: $theme-green;
                margin-bottom: 12px;
                height: 70px;
                width: 70px;

                .CircularProgressbar-trail {
                  stroke: #CCD3DF;
                }

                .CircularProgressbar-path {
                  stroke: #203864;
                }

                .CircularProgressbar-text {
                  fill: #000000;
                  @include fontSize(22px);
                  font-weight: 600;
                  vertical-align: middle;
                }
              }

              p {
                @include fontSize(12px);
                opacity: 0.6;
              }
            }

            .toggleSwitch {
              text-align: right;

              .infoicon {
                color: lighten($color: #000000, $amount: 30%);
                @include fontSize(15px);
                position: relative;
                top: 3px;
                margin-left: 4px;
              }

              .MuiTypography-root {
                font-weight: 500;
                @include fontSize(18px);
              }

              .MuiSwitch-root {
                .Mui-checked {
                  color: $theme-lightgreen;
                }

                .Mui-checked+.MuiSwitch-track {
                  background-color: $theme-lightgreen;
                }
              }

              .MuiSwitch-track {
                background: #D4E0F5;
              }

              .MuiSwitch-thumb {
                box-shadow: 0px 4px 4px 0px rgba(30, 113, 236, 0.10);
              }
            }
          }

          @keyframes slideDown {
            from {
              opacity: 0;
              -webkit-transform: translate3d(0, -5%, 0);
              transform: translate3d(0, -5%, 0);
            }

            to {
              opacity: 1;
              -webkit-transform: none;
              transform: none;
            }
          }

          &.health {
            .MuiTabs-flexContainer {
              max-width: 304px;
            }
          }

          &.term {
            .MuiTabs-flexContainer {
              max-width: 315px !important;
            }
          }
        }

        .bikedetailsection {
          margin-top: 12px;

          h5 {
            @include fontSize(18px);
            font-weight: 500;
          }

          .addonList {
            li {
              list-style-type: none;
              @include fontSize(14px);
              font-family: "poppins";
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                font-weight: 600;
                float: right;
              }
            }
          }

          .whiteBox {
            padding: 24px 20px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
            box-shadow: 0px 10px 12px 0px rgba(30, 113, 236, 0.10);
            @include border-radius(0 0 12px 12px);
            border: 1px dashed #203864;
            border-top: none;

            .gst_txt,
            .total_txt {
              @include fontSize(16px);
              font-weight: 500;

              span {
                font-weight: 600;
                float: right;
                color: #203864;
              }
            }

            .total_txt {
              span {
                color: #D9132E;
              }
            }
          }
        }
      }

      .chDetailHeader {
        @include position(fixed, $top: 0px, $left: 0px);
        z-index: 9;
        border-bottom: 1px solid rgba($black, 0.1);
        background: $white;
        padding: 13px 16px;
        width: 100%;

        .logo {
          display: block;
          height: 45px;
        }
      }

      .ch-contentSection {
        .MuiGrid2-container {
          max-width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }

        .insurerBox_lb {
          margin-bottom: 24px;

          h2 {
            @include fontSize(28px);
            font-weight: 400;
            text-align: center;
            margin-bottom: 10px;

            span {
              font-weight: 600;
            }
          }

          p {
            @include fontSize(16px);
            font-weight: 400;
            text-align: center;
            opacity: 0.5;
          }

          .productTabs {
            outline: 1px dashed $theme-blue;
            outline-offset: -1px;
            @include border-radius(100px);
            display: inline-block;
            overflow: hidden;

            .defaultTab {
              font-family: 'poppins';
              background: transparent;
              // @include border-radius(100px);
              color: #000;
              position: relative;
              @include fontSize(14px);
              text-transform: capitalize;
              padding: 8px 20px;
              font-weight: 400;
              position: relative;
              top: -1px;
              margin-bottom: -2px;

              &.active {
                background: $theme-blue;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
                color: $white;
                padding: 8px 20px;
              }
            }
          }
        }

        .showchTable {
          min-height: calc(100vh - 204px);
          background: #F6F9FE;

          .cg-table {
            .tabletop-strip {
              border-radius: 12px 12px 0px 0px;
              background: #E0EBFC;
              padding: 12px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 24px;

              span {
                @include fontSize(14px);
                font-weight: 500;
              }
            }

            .cg_list {
              li {
                padding-bottom: 12px;
                margin-bottom: 12px;
                border-bottom: 1px dashed #CBD2DF;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:last-child {
                  margin-bottom: 0;
                  border-bottom: none;
                }

                .hospital_name {
                  @include fontSize(14px);
                  font-weight: 500;

                  span {
                    font-weight: 400;
                    opacity: 0.5;
                    display: block;
                    margin-top: 4px;
                  }
                }

                .city {
                  @include fontSize(14px);
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      %btn {
        background: $theme-lightgreen;
        color: $white;
        @include border-radius(12px);
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        text-transform: capitalize;

        &:hover {
          background: lighten($theme-lightgreen, 5%);
          @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
        }
      }

      .greenXlBtn {
        @extend %btn;
        @include fontSize(16px);
        padding: 12px 30px;
        line-height: 24px;
      }

      .greenLgBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 10px 20px;
        line-height: 21px;
      }

      .greenMdBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 6px 16px;
      }

      .greenSmBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 3px 14px;
      }

      .greenXsBtn {
        @extend %btn;
        @include fontSize(12px);
        padding: 2px 14px;
      }

      .comparisonWrapper {
        background: #F6F9FE;
        padding-bottom: 12px;

        .comparisonHeader {
          background: $white;
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(#1E71EC, 0.2);
          border-bottom: 1px solid rgba(#1E71EC, 0.2);
          padding: 12px;

          .featureList {
            width: 270px;
            max-width: 270px;
            h4 {
                @include fontSize(18px);
                font-weight: 500;
              }
          }

          div {
            flex: 1 0 0;
            position: relative;

            .close {
              @include position(absolute, $right: 12px, $top: 0px);
              cursor: pointer;
              height: 30px;
              width: 30px;
              z-index: 9;

              &:hover {
                &:before {
                  transform: rotate(90deg);
                  background: $red;
                }

                &:after {
                  transform: rotate(-90deg);
                  background: $red;
                }
              }

              &:before {
                background: $black;
                content: '';
                @include position(absolute, $left: 14px, $top: 8px);
                transform: rotate(45deg);
                height: 14px;
                width: 1.5px;
                transition: all ease 0.3s;
              }

              &:after {
                @extend :before;
                transform: rotate(-45deg);
              }
            }
          }

          p {
            @include fontSize(12px);
          }

          h5 {
            @include fontSize(12px);
            font-weight: 400;
            color: rgba($black, 0.5);
            text-align: right;

            span {
              @include fontSize(16px);
              font-weight: 500;
              color: $red;
              display: block;
            }
          }
        }

        .comparisonList {
          padding: 0px 12px;
          overflow-y: auto;
          height: calc(100vh - 242px);

          ul {
            display: flex;
            border-bottom: 1px dashed rgba($theme-blue, 0.2);

            &:last-child {
              border: none;
            }

            li {
              background: $white;
              border-right: 1px dashed rgba($theme-blue, 0.2);
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1 0 0;
              text-align: center;
              @include fontSize(12px);
              min-height: 42px;
              padding: 12px 4px;

              &:first-child {
                background: transparent;
                @include box-shadow(inset -10px 0px 10px -8px rgba(#1E71EC, 0.1));
                border: none;
                justify-content: left;
                width: 270px;
                max-width: 270px;
                button {
                  @include fontSize(12px);
                  font-weight: 500;
                  color: $black;
                  cursor: default;
                  &:hover {
                    background: transparent;
                  }
                  svg {
                    color: $black;
                    border: none;
                    opacity: 0.5;
                    height: 14px;
                    width: 14px;
                    padding: 0px;
                  }
                }
              }

              &:last-child {
                border: none;
              }

              .MuiSvgIcon-root {
                border: 1px dashed;
                @include border-radius(40px);
                padding: 4px;
                height: 28px;
                width: 28px;
              }

              .MuiSvgIcon-root[data-testid="DoneIcon"] {
                border-color: $theme-green;
                color: $theme-green;
              }

              .MuiSvgIcon-root[data-testid="CloseIcon"] {
                border-color: $red;
                color: $red;
              }
            }
          }
        }
      }
    }
  }
}

%btn {
  background: $theme-lightgreen;
  color: $white;
  @include border-radius(12px);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  box-shadow: none;

  &:hover {
    background: lighten($theme-lightgreen, 5%);
    @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
  }
}

.greenXlBtn {
  @extend %btn;
  @include fontSize(16px);
  padding: 12px 30px;
  line-height: 24px;
}

.greenLgBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 10px 20px;
  line-height: 21px;
}

.greenMdBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 6px 16px;
}

.greenSmBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 3px 14px;
}

.greenXsBtn {
  @extend %btn;
  @include fontSize(12px);
  padding: 2px 14px;
}