@import "../../../../CommonSCSS/mixins.scss";


.frame-group,
.complaint-parent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}



.complaint-parent {
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 20px 24px;
    align-items: flex-start;
    text-align: center;
    font-size: 45px;
    color: #252525;
    font-family: Poppins;
    padding-top: 120px;

    @media(max-width:899px) {
       padding-top: 90px;
    }

    .frame-group {
        align-self: stretch;
        padding: 24px 0;
        align-items: center;
        gap: 60px;
    }

    .contact-us1 {
        width: 100%;
        font-weight: 600;
        display: inline-block;
        font-size: 45px;
        text-align: center;

        h1 {
            font-size: 45px;
            font-weight: 600;

            @media(max-width:899px) {
                @include fontSize(18px);
                font-weight: 600;
            }
        }
    }

    .contact-us {
        align-self: stretch;
        position: relative;
        height: 68px;
    }

    .we-eagerly-await {
        width: 100%;
        position: relative;
        font-size: 16px;
        display: inline-block;
        opacity: 0.6;
        text-align: center;
    }

    .contact-us-parent,
    .frame-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .contact-us-parent {
        align-self: stretch;
        gap: 10px;
    }

    .frame-wrapper {
        width: 100%;
        max-width: 1200px;
    }

    .objects-icon {
        position: relative;
        max-height: 300px;
        width: 100%;
    }

    .want-to-connect-container {
        position: relative;
        font-weight: 600;
    }

    .just-share-your {
        position: relative;
        font-size: 14px;
        opacity: 0.5;
    }

    .objects-parent,
    .want-to-connect-with-us-parent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    .objects-parent {
        flex: 1;
        gap: 40px;
        min-width: 468px;
    }

    .comprehensive1 {
        flex: 1;
        position: relative;
        line-height: 24px;
    }

    .frame-item,
    .vector-icon1 {
        position: relative;
        width: 13.09px;
        height: 12px;
        display: none;
    }

    .vector-icon1 {
        width: 10px;
        height: 5.63px;
    }

    .plan-type1 {
        position: relative;
    }

    .plan-type-container {
        position: absolute;
        bottom: 34px;
        left: 7.04px;
        border-radius: 20px;
        background-color: #fff;
        display: none;
        flex-direction: row;
        padding: 0 4px;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 10px;
        color: #00785a;
    }

    .input-field1,
    .input-field2 {
        position: relative;
        border-radius: 8px;
        height: 49px;
    }

    .input-field1 {
        align-self: stretch;
    }

    .input-field2 {
        flex: 1;
    }

    .input-field-group {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
    }

    .how-can-we {
        flex: 1;
        position: relative;
    }

    .how-can-we-assist-you-wrapper {
        align-self: stretch;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        height: 100px;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .input-field-wrapper,
    .input-field4 {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-field4 {
        border-radius: 8px;
        justify-content: center;
    }

    .input-field-wrapper {
        justify-content: flex-start;
    }

    .buy-now1 {
        position: relative;
        font-weight: 500;
    }

    .button1,
    .input-field-parent {
        display: flex;
        align-items: center;
    }

    .button1 {
        border-radius: 8px;
        background-color: #00b587;
        flex-direction: row;
        padding: 12px 24px;
        justify-content: flex-start;
        text-align: right;
        font-size: 14px;
        color: #fff;
    }

    .input-field-parent {
        align-self: stretch;
        border-radius: 0 0 12px 12px;
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 0),
                rgba(30, 113, 236, 0.08));
        box-shadow: 0 10px 12px rgba(107, 98, 220, 0.08);
        border-right: 1px dashed rgba(30, 113, 236, 0.5);
        border-bottom: 1px dashed rgba(30, 113, 236, 0.5);
        border-left: 1px dashed rgba(30, 113, 236, 0.5);
        flex-direction: column;
        padding: 24px;
        justify-content: center;
        gap: 24px;
    }

    .span {
        font-weight: 500;
        font-family: Poppins;
    }

    .span1 {
        font-weight: 600;
    }

    .a {
        color: #00b587;
    }

    .am-to-0600pm,
    .customer-support-on-container {
        align-self: stretch;
        position: relative;
    }

    .am-to-0600pm {
        opacity: 0.5;
    }

    .customer-support-on-91-9676-parent,
    .frame-wrapper1 {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .customer-support-on-91-9676-parent {
        border-radius: 12px;
        border: 1px dashed #203864;
        padding: 12px 24px;
        align-items: flex-start;
        gap: 8px;
    }

    .frame-wrapper1 {
        align-items: center;
        text-align: center;
        font-size: 14px;
        color: #203864;
    }

    .frame-container,
    .frame-div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 24px;
    }

    .frame-div {
        flex: 1;
        // height: 461px;
        flex-direction: column;
        min-width: 468px;
        text-align: left;
        font-size: 13px;
    }

    .frame-container {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1200px;
        font-size: 20px;
        color: #000;
        align-items: center;
    }
}

.fileUpload {
    text-align: center;

    p {
        @include fontSize(12px);
    }

    button {
        background: transparent;
        color: $theme-blue;
        text-transform: none;
        display: inline-block;
        @include box-shadow(0px 0px 0px rgba($white, 0));
        @include fontSize(14px);
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;

        &:hover {
            background: transparent;
            color: $theme-blue;
            @include box-shadow(0px 0px 0px rgba($white, 0));

            svg {
                color: $theme-blue;
            }
        }

        svg {
            color: $theme-lightgreen;
            display: block;
            margin: 0px auto;
            height: 30px;
            width: 30px;
        }
    }
}

@media screen and (max-width: 960px) {
    .input-field2 {
        flex: unset;
        align-self: stretch;
    }

    .input-field-group {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}