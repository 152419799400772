@import "../../../../CommonSCSS/mixins.scss";

.aboutusWrapper {
    padding-top: 120px;

    .pageHeader {
        background: url("../../../../../public/images/cms-header-bg.svg") no-repeat top center/contain;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        height: 150px;
        width: 100%;

        h1 {
            @include fontSize(45px);
            font-weight: 600;
            width: 100%;
            text-align: center;
        }
    }

    .sectionTabs {
        text-align: center;
        margin: 60px 0px 48px;

        .MuiTabs-flexContainer {
            border: 1px dashed $theme-blue;
            @include border-radius(50px);
            display: inline-flex;
            height: 44px;
            overflow: hidden;

            button {
                padding: 10px 20px;
                @include fontSize(16px);
                font-weight: 400;
                line-height: 24px;
                text-transform: inherit;
                color: $black;
                min-height: 30px;

                &.Mui-selected {
                    background: $theme-blue;
                    color: $white;
                }
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .MuiTabPanel-root {
        padding: 0px;
    }

    .futureEndeavours {

        font-size: 45px;
        font-weight: 600;

        @media(max-width:700px) {
            font-size: 18px;
        }

        h2 {
            font-size: 40px;
            font-weight: 600;
        }
    }

    .frame-child {
        width: 100%;
        position: relative;
        max-width: 1400px;
        overflow: hidden;
        height: 150px;
        flex-shrink: 0;
        z-index: 0;
    }

    .about-us1 {
        position: absolute;
        margin: 0 !important;
        top: calc(50% - 34px);
        left: calc(50% - 103px);
        font-weight: 600;
        z-index: 1;
    }

    .about-us-wrapper,
    .frame-group {
        display: flex;
        justify-content: flex-start;
    }

    .frame-group {
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex-direction: column;
        align-items: center;
        position: relative;
        gap: 10px;
    }

    .about-us-wrapper {
        background-color: #203864;
        flex-direction: row;
        padding: 10px 20px;
        align-items: flex-start;
        color: #fff;
    }

    .key-management-personal {
        position: relative;
    }

    .key-management-personal-wrapper {
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
    }

    .managing-director-and {
        position: relative;
        cursor: pointer;
    }

    .frame-container,
    .managing-director-and-ceo-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .managing-director-and-ceo-wrapper {
        padding: 10px 20px;
        justify-content: flex-start;
    }

    .frame-container {
        border-radius: 20px;
        border: 1px dashed #203864;
        overflow: hidden;
        justify-content: center;
        text-align: left;
        font-size: 16px;
    }

    .professionalism-technical-com {
        flex: 1;
        position: relative;
    }

    .professionalism-technical-com-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 1324px;
    }

    .we-are-insurance {
        align-self: stretch;
        position: relative;
        font-weight: 600;

        h2 {
            font-size: 40px;
            font-weight: 600;
        }
    }

    .we-have-professionals {
        align-self: stretch;
        position: relative;
        font-size: 16px;
    }

    .we-are-insurance-brokers-licen-parent {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }

    .frame-item {
        position: relative;
        border-radius: 50%;
        background-color: #00b587;
        width: 22px;
        height: 22px;
        display: none;
        z-index: 0;
    }

    .icon,
    .icon1,
    .icon2,
    .icon3,
    .icon4,
    .icon5,
    .icon6 {
        position: relative;
        width: 32px;
        height: 32px;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 2;
    }

    .icon1,
    .icon2,
    .icon3,
    .icon4,
    .icon5,
    .icon6 {
        width: 26px;
        height: 26px;
        display: none;
        z-index: 3;
    }

    .icon2,
    .icon3,
    .icon4,
    .icon5,
    .icon6 {
        width: 28px;
        height: 28px;
        z-index: 4;
    }

    .icon3,
    .icon4,
    .icon5,
    .icon6 {
        width: 24px;
        height: 24px;
        z-index: 5;
    }

    .icon4,
    .icon5,
    .icon6 {
        width: 25px;
        height: 25px;
        z-index: 6;
    }

    .icon5,
    .icon6 {
        width: 26px;
        height: 26px;
        z-index: 7;
    }

    .icon6 {
        width: 23px;
        height: 23px;
        z-index: 8;
    }

    .ellipse-parent,
    .frame-wrapper1 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .ellipse-parent {
        background-color: #fff;
        padding: 1px 0;
        justify-content: center;
        position: relative;
        gap: 10px;
    }

    .frame-wrapper1 {
        width: 22px;
        justify-content: flex-start;
    }

    .committed-to-the {
        align-self: stretch;
        position: relative;
    }

    .frame-inner {
        position: relative;
        border-radius: 50%;
        background-color: #d9d9d9;
        width: 11px;
        height: 11px;
    }

    .ellipse-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .frame-parent2,
    .frame-parent3 {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .frame-parent3 {
        display: flex;
        flex-direction: row;
        gap: 57px;
    }

    .frame-parent2 {
        display: none;
        flex-direction: column;
        gap: 10px;
    }

    .committed-to-the-objective-of-parent {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
    }

    .frame-parent1,
    .frame-wrapper {
        align-self: stretch;
        display: flex;
    }

    .frame-parent1 {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .frame-wrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .icon7,
    .icon8 {
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
    }

    .icon7 {
        width: 32px;
        height: 32px;
        display: none;
        z-index: 2;
    }

    .icon8 {
        width: 26px;
        height: 26px;
        z-index: 3;
    }

    .vector-icon2 {
        position: absolute;
        margin: 0 !important;
        top: calc(50% - 3.5px);
        left: calc(50% - 5.5px);
        width: 12px;
        height: 8.25px;
        display: none;
        z-index: 1;
    }

    .icon20 {
        position: relative;
        width: 23px;
        height: 23px;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 8;
    }

    .frame-parent7 {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
    }

    .committed-to-the3,
    .icon26 {
        position: relative;
        flex-shrink: 0;
    }

    .icon26 {
        width: 26px;
        height: 26px;
        overflow: hidden;
        z-index: 7;
    }

    .committed-to-the3 {
        align-self: stretch;
        display: inline-block;
        height: 21px;
    }

    .icon32,
    .icon37 {
        position: relative;
        width: 25px;
        height: 25px;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 6;
    }

    .icon37 {
        width: 28px;
        height: 28px;
        z-index: 4;
    }

    .ellipse-parent21 {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .icon45 {
        position: relative;
        width: 24px;
        height: 24px;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 5;
    }

    .instance-parent,
    .left-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
    }

    .instance-parent {
        align-self: stretch;
        text-align: left;
        font-size: 14px;
    }

    .left-area {
        flex: 1;
        min-width: 600px;
    }

    .illustration-icon {
        position: relative;
        width: 423.35px;
        height: 449.82px;
        opacity: 0.9;
    }

    .left-area-parent,
    .right-area {
        flex-direction: row;
        justify-content: center;
    }

    .right-area {
        width: 485.17px;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        padding: 20px 6px;
        box-sizing: border-box;
        align-items: center;
    }

    .left-area-parent {
        width: 100%;
        flex-wrap: wrap;
        gap: 24px;
        max-width: 1324px;
        text-align: justify;
        font-size: 20px;
    }

    .expertise,
    .frame-div,
    .left-area-parent {
        display: flex;
        align-items: center;
    }

    .frame-div {
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-end;
        gap: 30px;
        font-size: 16px;
        padding: 0px 12px;
    }

    .expertise {
        position: relative;
        font-weight: 600;
        justify-content: center;
        width: 486px;
        height: 58px;
        flex-shrink: 0;

        h2 {
            font-size: 40px;
            font-weight: 600;
        }
    }

    .some-of-the {
        position: relative;
        font-size: 16px;
        // text-transform: capitalize;
        color: #252525;
    }

    .expertise-parent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        max-width: 1324px;
    }

    .frame-icon {
        position: relative;
        width: 160px;
        height: 160px;
        overflow: hidden;
        flex-shrink: 0;
    }

    .health-insurance {
        position: relative;
        font-weight: 600;
    }

    .frame-parent25 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }

    .individualsfamily-floater-top {
        align-self: stretch;
        position: relative;
        font-size: 14px;
        line-height: 26px;
        text-transform: capitalize;
        text-align: center;
    }

    .frame-parent24 {
        flex: 1;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0 6px 20px rgba(32, 56, 100, 0.12);
        display: flex;
        flex-direction: column;
        padding: 24px;
        align-items: center;
        justify-content: flex-start;
    }

    .termtermropchildren-plans {
        position: relative;
        font-size: 14px;
        line-height: 26px;
        text-transform: capitalize;
        text-align: center;
        display: inline-block;
        width: 343px;
    }

    .frame-parent28 {
        flex: 1;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0 6px 20px rgba(32, 56, 100, 0.12);
        flex-direction: column;
        padding: 24px 0;
        align-items: center;
    }

    .frame-parent23,
    .frame-parent28,
    .frame-wrapper14 {
        display: flex;
        justify-content: flex-start;
    }

    .frame-parent23 {
        align-self: stretch;
        flex-direction: row;
        align-items: flex-end;
        gap: 24px;
    }

    .frame-wrapper14 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        max-width: 1324px;
        text-align: left;
        font-size: 20px;
        color: #252525;
    }

    .rectangle-div {
        position: relative;
        border-radius: 100px;
        background-color: #e1edff;
        width: 35px;
        height: 35px;
        z-index: 0;
    }

    .frame-child26 {
        position: absolute;
        margin: 0 !important;
        top: calc(50% - 5.56px);
        left: calc(50% - 3.1px);
        width: 7.01px;
        height: 11.12px;
        z-index: 1;
    }

    .rectangle-parent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        gap: 10px;
    }

    .frame-child27,
    .frame-child28 {
        position: relative;
        border-radius: 40px;
        background-color: #fff;
        border: 1px solid #252525;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        overflow: hidden;
        flex-shrink: 0;
    }

    .frame-child28 {
        background-color: #252525;
    }

    .frame-parent31 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }

    .frame-child36 {
        position: absolute;
        margin: 0 !important;
        top: calc(50% - 5.56px);
        left: calc(50% - 38.91px);
        width: 7.01px;
        height: 11.12px;
        z-index: 1;
    }

    .rectangle-group {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        gap: 10px;
        transform: rotate(180deg);
        transform-origin: 0 0;
    }

    .frame-parent22,
    .frame-parent30 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
    }

    .frame-parent22 {
        background-color: rgba(30, 113, 236, 0.04);
        // overflow: hidden;
        flex-direction: column;
        padding: 80px 0;
        box-sizing: border-box;
        gap: 60px;
        width: 100%;
    }

    .frame-icon3,
    .our-mission {
        position: relative;
        flex-shrink: 0;
    }

    .our-mission {
        align-self: stretch;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58px;

        h2 {
            font-size: 40px;
            font-weight: 600;
        }
    }

    .frame-icon3 {
        width: 525.81px;
        height: 501.86px;
        overflow: hidden;
    }

    .to-establish-and {
        align-self: stretch;
        position: relative;
        font-size: 14px;
        // text-transform: capitalize;
        text-align: justify;
    }

    .mission-statement {
        position: relative;
        font-size: 20px;
        font-weight: 600;
    }

    .charmcircle-tick-icon {
        position: relative;
        width: 16px;
        height: 16px;
        overflow: hidden;
        flex-shrink: 0;
    }

    .charmcircle-tick-parent {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
    }

    .frame-parent33,
    .mission-statement-parent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .mission-statement-parent {
        align-self: stretch;
        gap: 10px;
        font-size: 14px;
    }

    .frame-parent33 {
        flex: 1;
        gap: 30px;
        min-width: 600px;
    }

    .frame-parent32 {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 24px;
        text-align: left;
        font-size: 20px;
    }

    .our-mission-vision-parent {
        gap: 24px;
        padding: 0px 12px;
    }

    .ethics-values-principles-wrapper,
    .our-mission-vision-parent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1324px;
    }

    .compliance-with-irdai,
    .div {
        position: relative;
        text-transform: capitalize;
    }

    .div {
        font-weight: 500;
    }

    .compliance-with-irdai {
        flex: 1;
        color: #222;
    }

    .parent {
        flex: 1;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: -12px 12px 40px rgba(32, 56, 100, 0.2);
        height: 90px;
        display: flex;
        flex-direction: row;
        padding: 24px;
        box-sizing: border-box;
        align-items: center;
        justify-content: flex-start;
        gap: 14px;
        min-width: 320px;
        max-width: 430px;
    }

    .frame-parent35,
    .group {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .group {
        flex: 1;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: -12px 12px 40px rgba(32, 56, 100, 0.2);
        height: 90px;
        padding: 24px;
        box-sizing: border-box;
        justify-content: flex-start;
        gap: 14px;
        min-width: 320px;
        max-width: 430px;
    }

    .frame-parent35 {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;
        max-width: 1324px;
        text-align: left;
        font-size: 14px;
        color: #00b587;
    }

    .frame-parent34,
    .quality-policy-statement-parent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .frame-parent34 {
        align-self: stretch;
        background-color: rgba(30, 113, 236, 0.04);
        padding: 80px 12px;
        align-items: center;
        gap: 60px;
    }

    .quality-policy-statement-parent {
        flex: 1;
        align-items: flex-start;
        gap: 20px;
        min-width: 600px;
    }

    .frame-icon4 {
        position: relative;
        width: 150px;
        height: 98.81px;
        overflow: hidden;
        flex-shrink: 0;
    }

    .frame-frame {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .providing-quality-advice-throu-wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .frame-parent38 {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
    }

    .line-div {
        align-self: stretch;
        position: relative;
        border-top: 1px solid #e3edff;
        box-sizing: border-box;
        height: 1px;
    }

    .frame-icon5,
    .frame-icon6 {
        position: relative;
        width: 150px;
        overflow: hidden;
        flex-shrink: 0;
    }

    .frame-icon5 {
        height: 110.58px;
        display: none;
    }

    .frame-icon6 {
        height: 139.82px;
    }

    .frame-parent39,
    .frame-parent40 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .frame-parent40 {
        align-items: flex-end;
        gap: 10px;
    }

    .frame-parent39 {
        align-self: stretch;
        align-items: center;
        gap: 24px;
    }

    .frame-parent37,
    .towards-this-goal-we-are-com-parent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .frame-parent37 {
        align-self: stretch;
        gap: 30px;
        font-size: 14px;
    }

    .towards-this-goal-we-are-com-parent {
        flex: 1;
        gap: 20px;
        font-size: 20px;
    }

    .frame-parent36 {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 24px;
        max-width: 1324px;
        text-align: left;
        padding: 0px 12px;
    }

    .about-us,
    .frame-parent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .frame-parent {
        align-self: stretch;
        padding: 12px 0 0;
        align-items: center;
        gap: 80px;
    }

    .about-us {
        position: relative;
        background: linear-gradient(#fff, #fff),
            linear-gradient(180deg, rgba(227, 237, 255, 0.2), rgba(227, 237, 255, 0.2));
        width: 100%;
        overflow: hidden;
        align-items: flex-start;
        text-align: center;
        font-size: 45px;
        color: #000;
        font-family: Poppins;
    }

    .react-multi-carousel-list {
        width: 100%;
        max-width: 1360px;

        .react-multiple-carousel__arrow {
            background: #E1EDFF;
            min-height: 36px;
            height: 36px;
            min-width: 36px;
            width: 36px;
            bottom: 0px;

            &:before {
                color: $black;
                font-size: 14px;
                font-weight: 600;
            }

            &:hover {
                background: $theme-blue;

                &:before {
                    color: $white;
                }
            }

            &.react-multiple-carousel__arrow--left {
                left: calc(50% - 96px);
                z-index: 2;
            }

            &.react-multiple-carousel__arrow--right {
                right: calc(50% - 96px);
                z-index: 2;
            }
        }

        .react-multi-carousel-dot button {
            border: 1px solid $black;
            background: $white;
        }

        .react-multi-carousel-dot--active button {
            border: 1px solid $black;
            background: $black;
        }
    }

    .productCard {
        padding: 12px 12px 80px;

        .inner {
            background: $white;
            @include border-radius(12px);
            @include box-shadow(0px 6px 12px rgba($theme-blue, 0.1));
            padding: 24px 12px;
            min-height: 320px;

            img {
                height: 160px;
            }

            h4 {
                @include fontSize(20px);
                font-weight: 600;
                margin-bottom: 8px;
            }

            p {
                @include fontSize(14px);
                line-height: 26px;
            }
        }
    }

    @media screen and (max-width: 960px) {
        .left-area {
            flex: unset;
            align-self: stretch;
        }

        .left-area-parent {
            flex-direction: column;
            gap: 24px;
            align-items: flex-end;
            justify-content: center;
        }
    }



    // Management
    .vice-chairman-parent {
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        gap: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .about-us-management {
        position: relative;
        width: 100%;
        overflow: hidden;
        text-align: left;
        font-size: 20px;
        color: #00b587;
        gap: 40px;
        display: flex;
        flex-direction: column;
        font-family: Poppins;

        .mainWrapper {
            box-sizing: border-box;
            align-items: center;
            gap: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            padding: 30px 0px;

            .content-wrapper {
                flex-direction: row-reverse;
            }

            &:nth-child(odd) {
                background: #F6F9FE;

                .content-wrapper {
                    flex-direction: row;
                }
            }
        }

        .fiii-chief-operating,
        .kv-bhaskar-rao {
            position: relative;
            font-weight: 600;
            display: inline-block;
        }

        .kv-bhaskar-rao {
            font-weight: 500;
        }

        .charmcircle-tick-icon {
            position: relative;
            top: 3px;
            width: 16px;
            height: 16px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .no-limit-on {
            flex: 1;
            position: relative;
        }

        .charmcircle-tick-parent {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }

        .instance-parent {
            align-self: stretch;
            gap: 20px;
            font-size: 14px;
        }

        .content,
        .instance-parent,
        .kv-bhaskar-rao-ma-parent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .kv-bhaskar-rao-ma-parent {
            align-self: stretch;
            gap: 20px;
            color: #000;
        }

        .content {
            flex: 1;
            gap: 10px;
        }

        .md-sir-2 {
            position: absolute;
            top: calc(50% - 190px);
            left: calc(50% - 190px);
            width: 385px;
            height: 380px;
            object-fit: cover;
        }

        .md-sir-2-wrapper {
            position: relative;
            border-radius: 20px;
            background-color: #d1c3c3;
            box-shadow: -12px 12px 40px rgba(32, 56, 100, 0.2);
            width: 380px;
            height: 380px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .photo {
            @include box-shadow(-12px 12px 40px rgba(32, 56, 100, 0.2));
            @include border-radius(20px);
            height: 380px;
            width: 380px;
            min-width: 380px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex: 1;
            gap: 24px;
            max-width: 1324px;
            width: 100%;
            padding: 0px 12px;
        }

        .vice-chairman {
            align-self: stretch;
            background-color: rgba(30, 113, 236, 0.04);
            padding: 30px 0;
        }

        .photo-child {
            position: relative;
            width: 460px;
            height: 460px;
            overflow: hidden;
            flex-shrink: 0;
            object-fit: cover;
        }

        .photo1 {
            height: 380px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .dr-muralidhara-reddy,
        .managing-director {
            align-self: stretch;
            position: relative;
            font-weight: 600;
        }

        .managing-director {
            font-weight: 500;
        }

        .paragraph {
            align-self: stretch;
            flex-direction: column;
            align-items: flex-start;
            font-size: 14px;
        }

        .content1,
        .muralidhara-reddy,
        .paragraph {
            display: flex;
            justify-content: flex-start;
        }

        .content1 {
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .muralidhara-reddy {
            width: 100%;
            background-color: #fff;
            flex-direction: row;
            align-items: center;
            gap: 24px;
            max-width: 1324px;
            padding: 0px 12px;
        }

        .charmcircle-tick-parent3,
        .charmcircle-tick-parent7 {
            align-self: stretch;
            flex-direction: row;
            justify-content: center;
            gap: 10px;
        }

        .charmcircle-tick-parent3 {
            display: flex;
            align-items: center;
        }

        .charmcircle-tick-parent7 {
            display: none;
            align-items: flex-start;
        }

        .photo-item {
            position: relative;
            width: 460px;
            height: 459.72px;
            overflow: hidden;
            flex-shrink: 0;
            object-fit: cover;
        }

        .md,
        .md-wrapper,
        .photo2 {
            display: flex;
            align-items: flex-start;
        }

        .photo2 {
            width: 380px;
            flex-direction: column;
            justify-content: flex-start;
        }

        .md,
        .md-wrapper {
            flex-direction: row;
            justify-content: center;
        }

        .md-wrapper {
            flex: 1;
            gap: 24px;
            max-width: 1324px;
            padding: 0px 12px;
        }

        .md {
            align-self: stretch;
            background-color: rgba(30, 113, 236, 0.04);
            padding: 30px 0px;
        }

        .frame-child {
            border-radius: 50%;
            background-color: #d9d9d9;
            width: 10px;
            height: 10px;
        }

        .brahmos-aerospace,
        .frame-child {
            position: relative;
        }

        .ellipse-parent,
        .frame-group,
        .frame-parent {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
        }

        .ellipse-parent {
            flex-direction: row;
            align-items: center;
        }

        .frame-group,
        .frame-parent {
            align-self: stretch;
            align-items: flex-start;
        }

        .frame-group {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 0 0 28px;
        }

        .frame-parent {
            flex-direction: column;
        }

        .coo {
            width: 100%;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            padding: 30px 12px;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            gap: 24px;
            max-width: 1324px;
        }

        .content4 {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
        }

        .md-sir-21 {
            position: relative;
            width: 380px;
            height: 383px;
            object-fit: cover;
        }

        .md-sir-2-container {
            border-radius: 20px;
            background-color: #d1c3c3;
            box-shadow: -12px 12px 40px rgba(32, 56, 100, 0.2);
            width: 380px;
            height: 380px;
            overflow: hidden;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }

        .md-sir-3 {
            position: absolute;
            top: calc(50% - 190px);
            left: calc(50% - 190px);
            width: 384px;
            height: 380px;
            object-fit: cover;
        }

        .branch-head-wrapper,
        .description4 {
            display: flex;
            justify-content: flex-start;
        }

        .description4 {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            color: #000;
        }

        .branch-head-wrapper {
            flex: 1;
            flex-direction: row;
            align-items: center;
            gap: 24px;
            max-width: 1324px;
            padding: 0px 12px;
        }
    }

    // CEO
    .murlidhara-reddy-parent {
        position: relative;
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        justify-content: flex-start;
        gap: 60px;
        text-align: center;
        font-size: 45px;
        color: #000;
        font-family: Poppins;

        .director {
            align-self: stretch;
            position: relative;
            font-weight: 500;
            display: none;
            height: 30px;
            flex-shrink: 0;
        }

        .dr-muralidhara-reddy {
            position: relative;
            font-size: 24px;
            font-weight: 600;
            color: #00b587;
            display: inline-block;
            width: 792px;
        }

        .managing-director {
            align-self: stretch;
            position: relative;
            font-weight: 500;
            color: #000;
        }

        .director-parent {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            max-width: 1324px;
        }

        .span {
            font-weight: 500;
            font-family: Poppins;
        }

        .he-has-the {
            font-weight: 600;
        }

        .he-has-the-richest-experience {
            color: #203864;
        }

        .dr-k-muralidhara-container {
            flex: 1;
            position: relative;
        }

        .dr-k-muralidhara-reddy-also-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: 1324px;
            font-size: 16px;
            color: #000;
        }

        .frame-parent {
            align-self: stretch;
            background-color: #f6f9fe;
            display: flex;
            flex-direction: column;
            padding: 200px 12px 24px;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            z-index: 0;
        }

        .md-sir-1 {
            position: absolute;
            top: calc(50% - 183px);
            left: calc(50% - 192px);
            width: 384px;
            height: 479.97px;
            object-fit: cover;
        }

        .md-sir-1-wrapper {
            position: relative;
            border-radius: 20px;
            background-color: #d1c3c3;
            width: 384px;
            height: 366px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .murlidhara-reddy,
        .murlidhara-reddy-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .murlidhara-reddy-inner {
            margin: 0 !important;
            position: absolute;
            top: 0;
            left: calc(50% - 192px);
            z-index: 1;
        }

        .murlidhara-reddy {
            align-self: stretch;
            padding: 200px 0 0;
            position: relative;
            gap: 10px;
            font-size: 20px;
            color: #828282;
        }

        .image-icon {
            position: relative;
            width: 588px;
            height: 370px;
            overflow: hidden;
            flex-shrink: 0;
            max-width: 588px;
        }

        .awardsrecognition {
            align-self: stretch;
            position: relative;
            font-weight: 600;

            h2 {
                font-size: 40px;
                font-weight: 600;
            }
        }

        .charmcircle-tick-icon1 {
            position: relative;
            width: 16px;
            height: 16px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .point01,
        .points {
            align-self: stretch;
            align-items: flex-start;
        }

        .point01 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 10px;
        }

        .points {
            flex-direction: column;
            gap: 20px;
            font-size: 14px;
        }

        .awards,
        .awards-content,
        .points {
            display: flex;
            justify-content: flex-start;
        }

        .awards-content {
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            min-width: 400px;
        }

        .awards {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 12px;
            box-sizing: border-box;
            align-items: center;
            gap: 24px;
            max-width: 1324px;
            text-align: left;
        }

        .a-few-words {
            align-self: stretch;
            position: relative;
            line-height: 32px;
            font-weight: 600;
        }

        .title {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 26px;
        }

        .dr-reddy-worked {
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 500;
        }

        .dr-reddy-belongs,
        .dr-reddy-worked {
            align-self: stretch;
            position: relative;
        }

        .amaze-insurance-brokers {
            font-weight: 600;
            color: #203864;
        }

        .few-words {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 12px;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            max-width: 1324px;
            font-size: 16px;
        }

        .dr-reddy-has {
            align-self: stretch;
            position: relative;
            font-size: 16px;
        }

        .title1 {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        .corpora-2-icon1 {
            position: relative;
            width: 60px;
            height: 60px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .div1 {
            position: relative;
            text-transform: capitalize;
            font-weight: 500;
        }

        .container {
            border-radius: 11px;
            background-color: #00b587;
            display: none;
            flex-direction: column;
            padding: 12px 14px;
            align-items: center;
            justify-content: center;
        }

        .compliance-with-irdai1 {
            align-self: stretch;
            position: relative;
            text-transform: capitalize;
            color: #000;
        }

        .point011 {
            flex: 1;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: -12px 12px 40px rgba(32, 56, 100, 0.2);
            min-height: 181px;
            display: flex;
            flex-direction: column;
            padding: 24px 12px;
            box-sizing: border-box;
            align-items: center;
            justify-content: flex-start;
            gap: 14px;
            min-width: 250px;
            max-width: 360px;
        }

        .compliance-with-irdai3 {
            align-self: stretch;
            position: relative;
            text-transform: capitalize;
            color: #222;
        }

        .all-points {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            gap: 24px;
            font-size: 14px;
            color: #fff;
        }

        .summary {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 12px;
            box-sizing: border-box;
            align-items: center;
            justify-content: flex-start;
            gap: 60px;
            max-width: 1324px;
        }

        .dr-reddy-has1 {
            align-self: stretch;
            position: relative;
            font-size: 16px;
            color: #252525;
        }

        .vector-icon,
        .vector-icon1 {
            height: 160px;
            width: 160px;
        }

        .vector-icon1 {
            height: 99.08%;
            width: 97.83%;
            top: 1.06%;
            right: 0.81%;
            bottom: -0.13%;
            left: 1.36%;
            display: none;
        }

        .group-icon {
            height: 71.61%;
            width: 30.17%;
            top: 1.3%;
            right: 15.83%;
            bottom: 27.09%;
            left: 54%;
            display: none;
        }

        .group-icon,
        .group-icon1,
        .group-icon2,
        .group-icon3 {
            position: absolute;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon1 {
            height: 72.71%;
            width: 42.86%;
            top: 15.49%;
            right: 2.63%;
            bottom: 11.8%;
            left: 54.51%;
            display: none;
        }

        .group-icon2,
        .group-icon3 {
            height: 86.3%;
            width: 59.4%;
            top: 11.97%;
            right: 40.6%;
            bottom: 1.73%;
            left: 0;
        }

        .group-icon3 {
            height: 80.17%;
            width: 78.57%;
            top: 0;
            right: 0;
            bottom: 19.83%;
            left: 21.43%;
        }

        .group-icon4,
        .vector-icon2,
        .vector-icon3,
        .vector-icon4,
        .vector-icon5 {
            position: absolute;
            height: 46.79%;
            width: 53.34%;
            top: 53.21%;
            right: 16.61%;
            bottom: 0;
            left: 30.05%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .vector-icon2,
        .vector-icon3,
        .vector-icon4,
        .vector-icon5 {
            height: 70.71%;
            width: 70.71%;
            top: 14.65%;
            right: 14.64%;
            bottom: 14.65%;
            left: 14.64%;
        }

        .vector-icon3,
        .vector-icon4,
        .vector-icon5 {
            height: 68.36%;
            width: 68.36%;
            top: 15.82%;
            right: 15.82%;
            bottom: 15.82%;
            left: 15.82%;
        }

        .vector-icon4,
        .vector-icon5 {
            height: 18.92%;
            width: 35.45%;
            top: 17.66%;
            right: 43.52%;
            bottom: 63.42%;
            left: 21.03%;
        }

        .vector-icon5 {
            height: 57.86%;
            width: 57.86%;
            top: 21.81%;
            right: 20.32%;
            bottom: 20.33%;
            left: 21.82%;
        }

        .div9 {
            position: absolute;
            top: 12.44px;
            left: 17.96px;
            text-transform: capitalize;
            display: inline-block;
            width: 8.89px;
            height: 16.89px;
            -webkit-text-stroke: 0.2px #000;
        }

        .frame3,
        .group-icon5 {
            position: absolute;
            top: 70.56px;
            left: 98.04px;
            width: 43.27px;
            height: 43.27px;
        }

        .group-icon5 {
            height: 51.33%;
            width: 26.62%;
            top: 48.37%;
            right: 63.96%;
            bottom: 0.3%;
            left: 9.43%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .frame2,
        .vector-parent {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 144.57px;
            height: 144.62px;
        }

        .vector-parent {
            width: 180px;
            height: 180px;
        }

        .frame1 {
            position: relative;
            border-radius: 90px;
            background-color: #fff;
            width: 180px;
            height: 180px;
            overflow: hidden;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .project-insurances {
            position: relative;
            font-size: 20px;
            font-weight: 600;
            font-family: Poppins;
        }

        .title3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
        }

        .earcarcpmmarine-cum-ear {
            align-self: stretch;
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
            font-family: Poppins;
            text-align: center;
        }

        .inner {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }

        .various-points01 {
            flex: 1;
            border-radius: 15px;
            height: 398px;
            display: flex;
            flex-direction: row;
            padding: 24px;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: center;
            font-family: Poppins;
            min-width: 320px;
            max-width: 424px;
            font-size: 16px;
            font-family: Poly;
        }

        .frame-icon {
            position: relative;
            border-radius: 90px;
            width: 180px;
            height: 180px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .operational-covers {
            position: relative;
            font-weight: 600;
        }

        .iar-fire-special {
            align-self: stretch;
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
            text-align: center;
            font-family: Poppins;
        }



        .title5 {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
        }

        .advance-loss-of {
            align-self: stretch;
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
        }


        .group-icon6,
        .group-icon7,
        .group-icon8 {
            position: absolute;
            height: 77.13%;
            width: 15.84%;
            top: 0;
            right: 30.84%;
            bottom: 22.87%;
            left: 53.32%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon7,
        .group-icon8 {
            height: 35.28%;
            width: 37.56%;
            top: 53.69%;
            right: 62.44%;
            bottom: 11.03%;
            left: 0;
        }

        .group-icon8 {
            width: 37.49%;
            top: 48.84%;
            right: 62.48%;
            bottom: 15.88%;
            left: 0.03%;
        }

        .group-icon10,
        .group-icon11,
        .group-icon12,
        .group-icon13,
        .group-icon14,
        .group-icon9 {
            position: absolute;
            height: 35.28%;
            width: 37.56%;
            top: 43.99%;
            right: 62.44%;
            bottom: 20.73%;
            left: 0;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon10,
        .group-icon11,
        .group-icon12,
        .group-icon13,
        .group-icon14 {
            top: 33.97%;
            bottom: 30.75%;
        }

        .group-icon11,
        .group-icon12,
        .group-icon13,
        .group-icon14 {
            top: 39.39%;
            bottom: 25.33%;
        }

        .group-icon12,
        .group-icon13,
        .group-icon14 {
            width: 37.49%;
            top: 33.97%;
            right: 62.48%;
            bottom: 30.75%;
            left: 0.03%;
        }

        .group-icon13,
        .group-icon14 {
            top: 29.12%;
            bottom: 35.6%;
        }

        .group-icon14 {
            width: 37.55%;
            top: 24.27%;
            right: 62.44%;
            bottom: 40.45%;
            left: 0;
        }

        .group-icon15,
        .group-icon16,
        .group-icon17,
        .group-icon18,
        .group-icon19,
        .vector-icon6 {
            position: absolute;
            height: 35.28%;
            width: 37.49%;
            top: 19.41%;
            right: 62.48%;
            bottom: 45.31%;
            left: 0.03%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon16,
        .group-icon17,
        .group-icon18,
        .group-icon19,
        .vector-icon6 {
            width: 37.55%;
            top: 14.56%;
            right: 62.44%;
            bottom: 50.16%;
            left: 0;
        }

        .group-icon17,
        .group-icon18,
        .group-icon19,
        .vector-icon6 {
            width: 37.56%;
            top: 9.71%;
            bottom: 55.01%;
        }

        .group-icon18,
        .group-icon19,
        .vector-icon6 {
            width: 37.49%;
            top: 4.85%;
            right: 62.48%;
            bottom: 59.87%;
            left: 0.03%;
        }

        .group-icon19,
        .vector-icon6 {
            height: 93.66%;
            width: 99.83%;
            top: 6.34%;
            right: 0.17%;
            bottom: 0;
            left: 0;
        }

        .vector-icon6 {
            height: 85.28%;
            width: 87.22%;
            top: 6.12%;
            right: 6.54%;
            bottom: 8.61%;
            left: 6.24%;
        }

        .div10 {
            position: absolute;
            top: 15.61%;
            left: 35.99%;
            text-transform: capitalize;
        }

        .group-icon20 {
            position: absolute;
            height: 93.39%;
            width: 95.31%;
            top: 3.35%;
            right: 2.53%;
            bottom: 3.25%;
            left: 2.17%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
            opacity: 0.1;
        }

        .frame7,
        .frame8 {
            position: absolute;
            top: 0;
            left: 0.08px;
            width: 43.77px;
            height: 38.84px;
        }

        .frame7 {
            left: 0;
            width: 43.85px;
            height: 41.47px;
            font-size: 21px;
        }

        .group-icon21,
        .group-icon22,
        .group-icon23,
        .group-icon24,
        .group-icon25 {
            position: absolute;
            height: 35.28%;
            width: 37.56%;
            top: 42.67%;
            right: 8.44%;
            bottom: 22.05%;
            left: 54.01%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon22,
        .group-icon23,
        .group-icon24,
        .group-icon25 {
            width: 37.49%;
            top: 37.35%;
            right: 8.47%;
            bottom: 27.37%;
            left: 54.04%;
        }

        .group-icon23,
        .group-icon24,
        .group-icon25 {
            width: 37.56%;
            top: 32.02%;
            right: 8.44%;
            bottom: 32.7%;
            left: 54.01%;
        }

        .group-icon24,
        .group-icon25 {
            width: 37.49%;
            top: 26.7%;
            right: 8.47%;
            bottom: 38.02%;
            left: 54.04%;
        }

        .group-icon25 {
            width: 37.56%;
            top: 20.29%;
            right: 5.64%;
            bottom: 44.43%;
            left: 56.8%;
        }

        .div11 {
            position: absolute;
            top: 18.53%;
            left: 38.23%;
            text-transform: capitalize;
        }

        .group-icon26 {
            position: absolute;
            height: 93.39%;
            width: 95.31%;
            top: 3.35%;
            right: 2.53%;
            bottom: 3.25%;
            left: 2.16%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
            opacity: 0.1;
        }

        .frame10,
        .group-icon27 {
            position: relative;
            width: 43.77px;
            height: 38.84px;
        }

        .group-icon27 {
            margin-top: -36px;
        }

        .frame9 {
            position: absolute;
            top: 18.87px;
            left: 63.1px;
            width: 43.77px;
            height: 41.47px;
            display: flex;
            flex-direction: column;
            padding: 0 0.00009918212890625px 0 0;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .group-icon28 {
            position: absolute;
            height: 35.28%;
            width: 37.56%;
            top: 63.84%;
            right: 33.71%;
            bottom: 0.88%;
            left: 28.73%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon29,
        .group-icon30,
        .group-icon31,
        .group-icon32,
        .group-icon33,
        .vector-icon10 {
            position: absolute;
            height: 35.28%;
            width: 37.49%;
            top: 59.58%;
            right: 33.75%;
            bottom: 5.14%;
            left: 28.76%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon30,
        .group-icon31,
        .group-icon32,
        .group-icon33,
        .vector-icon10 {
            width: 37.56%;
            top: 54.57%;
            right: 33.5%;
            bottom: 10.15%;
            left: 28.94%;
        }

        .group-icon31,
        .group-icon32,
        .group-icon33,
        .vector-icon10 {
            width: 37.49%;
            top: 49.52%;
            right: 33.54%;
            bottom: 15.2%;
            left: 28.98%;
        }

        .group-icon32,
        .group-icon33,
        .vector-icon10 {
            width: 37.56%;
            top: 42.04%;
            right: 34.99%;
            bottom: 22.68%;
            left: 27.46%;
        }

        .group-icon33,
        .vector-icon10 {
            width: 37.49%;
            top: 37.77%;
            right: 35.02%;
            bottom: 26.95%;
            left: 27.49%;
        }

        .vector-icon10 {
            height: 85.28%;
            width: 87.22%;
            top: 6.13%;
            right: 6.54%;
            bottom: 8.6%;
            left: 6.24%;
        }

        .div12 {
            position: absolute;
            top: 19.49%;
            left: 38.12%;
            text-transform: capitalize;
        }

        .frame11,
        .vector-icon12 {
            position: absolute;
            top: 37.49px;
            left: 36.07px;
            width: 43.85px;
            height: 41.47px;
        }

        .vector-icon12 {
            height: 85.78%;
            width: 86.68%;
            top: 6.23%;
            right: 6.73%;
            bottom: 7.99%;
            left: 6.6%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .div13 {
            position: absolute;
            top: 14.79%;
            left: 48.44%;
            text-transform: capitalize;
            transform: rotate(26.89deg);
            transform-origin: 0 0;
        }

        .group-icon36 {
            position: absolute;
            height: 93.9%;
            width: 94.76%;
            top: 3.3%;
            right: 3.26%;
            bottom: 2.8%;
            left: 1.98%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
            opacity: 0.1;
        }

        .frame14,
        .group-icon37 {
            position: relative;
            width: 42.82px;
            height: 40.07px;
        }

        .group-icon37 {
            margin-top: -37px;
        }

        .frame13 {
            position: absolute;
            top: 75.13px;
            left: 72.75px;
            width: 44.02px;
            height: 42.42px;
            display: flex;
            flex-direction: column;
            padding: 0 0 0 1.1994407176971436px;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .frame6 {
            position: absolute;
            top: 8.77px;
            left: 31.17px;
            width: 116.77px;
            height: 117.55px;
        }

        .group-icon38,
        .group-icon39,
        .vector-icon14,
        .vector-icon15 {
            position: absolute;
            height: 3.11%;
            width: 2.33%;
            top: 30.27%;
            right: 38.4%;
            bottom: 66.61%;
            left: 59.27%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon38,
        .group-icon39,
        .vector-icon15 {
            height: 14.51%;
            width: 7.54%;
            top: 17.87%;
            right: 31.28%;
            bottom: 67.62%;
            left: 61.19%;
        }

        .group-icon38,
        .group-icon39 {
            height: 68.94%;
            width: 19.95%;
            top: 29.42%;
            right: 80.05%;
            bottom: 1.64%;
            left: 0;
        }

        .group-icon39 {
            height: 38.3%;
            width: 21.07%;
            top: 33.78%;
            right: 64.94%;
            bottom: 27.91%;
            left: 13.99%;
        }

        .group-icon40,
        .group-icon41,
        .group-icon42,
        .group-icon43,
        .group-icon44 {
            position: absolute;
            height: 20.32%;
            width: 16.34%;
            top: 41.55%;
            right: 82.42%;
            bottom: 38.13%;
            left: 1.24%;
            max-width: 100%;
            overflow: hidden;
            max-height: 100%;
        }

        .group-icon41,
        .group-icon42,
        .group-icon43,
        .group-icon44 {
            height: 58.26%;
            width: 21%;
            top: 40.63%;
            right: 0;
            bottom: 1.11%;
            left: 79%;
        }

        .group-icon42,
        .group-icon43,
        .group-icon44 {
            height: 11.71%;
            width: 8.53%;
            top: 29.68%;
            right: 3.13%;
            bottom: 58.61%;
            left: 88.34%;
        }

        .group-icon43,
        .group-icon44 {
            height: 65.61%;
            width: 32.48%;
            top: 34.39%;
            right: 14.61%;
            bottom: 0;
            left: 52.9%;
        }

        .group-icon44 {
            height: 16.04%;
            width: 16.33%;
            top: 40.77%;
            right: 2.4%;
            bottom: 43.2%;
            left: 81.27%;
        }

        .frame5 {
            position: absolute;
            top: 32.16px;
            left: 5px;
            width: 230px;
            height: 152.39px;
        }

        .cgl-public-liability,
        .liability-insurances {
            position: relative;
            font-family: Poppins;
            color: #252525;
        }

        .liability-insurances {
            font-weight: 600;
        }

        .cgl-public-liability {
            align-self: stretch;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
            text-align: center;
        }

        .group-icon45 {
            position: absolute;
            top: 0;
            left: 0;
            width: 34.14px;
            height: 33.09px;
        }

        .group-icon46,
        .group-icon47,
        .group-icon48 {
            position: absolute;
            top: 10px;
            left: 42px;
            width: 36.13px;
            height: 35.04px;
        }

        .group-icon47,
        .group-icon48 {
            top: 55px;
            left: 27px;
            width: 36.14px;
        }

        .group-icon48 {
            top: 80px;
            left: 75px;
            width: 36.08px;
            height: 35.06px;
        }

        .group-icon49,
        .vector-icon16 {
            position: absolute;
            top: 0;
            left: 0;
            width: 32.98px;
            height: 30.45px;
        }

        .group-icon49 {
            top: 25.19px;
            left: 26.24px;
            width: 9.86px;
            height: 9.86px;
        }

        .div14,
        .vector-icon17 {
            position: absolute;
            top: 0;
            left: 0;
            width: 4.73px;
            height: 4.73px;
        }

        .div14 {
            top: 0.67px;
            left: 0.98px;
            font-weight: 300;
            display: inline-block;
            width: 2.51px;
            height: 3.76px;
        }

        .frame-icon3,
        .frame20 {
            position: relative;
            width: 4.73px;
            height: 4.73px;
        }

        .frame-icon3 {
            width: 22.09px;
            height: 17.75px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .frame19 {
            position: absolute;
            top: 3.89px;
            left: 5.38px;
            width: 22.09px;
            height: 22.67px;
            display: flex;
            flex-direction: column;
            padding: 0 0.00034332275390625px 0 0;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
        }

        .frame17,
        .frame18 {
            position: absolute;
            top: 38px;
            left: 83px;
            width: 36.1px;
            height: 35.05px;
        }

        .frame17 {
            top: 0;
            left: 46.5px;
            width: 119.1px;
            height: 115.06px;
        }

        .frame16,
        .group-icon50,
        .vector-icon18 {
            position: absolute;
            top: 39.41px;
            left: 0;
            width: 71.3px;
            height: 150.7px;
        }

        .frame16,
        .vector-icon18 {
            top: 0;
            width: 165.6px;
            height: 190.12px;
        }

        .vector-icon18 {
            top: 14.15px;
            left: 2.71px;
            width: 64.11px;
            height: 45.31px;
        }

        .vector-icon19,
        .vector-icon20 {
            position: absolute;
            top: 16.1px;
            left: 5.09px;
            width: 59.79px;
            height: 41.41px;
        }

        .vector-icon20 {
            top: 0;
            left: 0;
            width: 20.31px;
            height: 20.31px;
        }

        .div15,
        .vector-icon21 {
            position: absolute;
            top: 1.98px;
            left: 1.99px;
            width: 16.34px;
            height: 16.35px;
        }

        .div15 {
            top: 1.28px;
            left: 9.68px;
            line-height: 69px;
            font-weight: 500;
            display: inline-block;
            width: 7.94px;
            height: 19.58px;
            transform: rotate(19.15deg);
            transform-origin: 0 0;
        }

        .frame22,
        .vector-icon22 {
            position: absolute;
            top: 6.11px;
            left: 32.8px;
            width: 20.31px;
            height: 22.38px;
        }

        .vector-icon22 {
            top: 0;
            left: 0;
            width: 22.64px;
            height: 22.64px;
        }

        .div16,
        .vector-icon23 {
            position: absolute;
            top: 2.01px;
            left: 2.21px;
            width: 18.22px;
            height: 18.22px;
        }

        .div16 {
            top: 3.89px;
            left: 6.92px;
            line-height: 69px;
            font-weight: 500;
            display: inline-block;
            width: 7.94px;
            height: 19.58px;
        }

        .frame24,
        .vector-icon24 {
            position: relative;
            width: 22.64px;
            height: 23.47px;
        }

        .vector-icon24 {
            width: 9.29px;
            height: 14.38px;
            margin-left: -9px;
        }

        .frame23 {
            position: absolute;
            top: 0;
            left: 14.55px;
            width: 22.86px;
            height: 23.47px;
            display: flex;
            flex-direction: row;
            padding: 0 0.22461318969726562px 0 0;
            box-sizing: border-box;
            align-items: center;
            justify-content: flex-end;
        }

        .vector-icon25,
        .vector-icon26 {
            position: absolute;
            top: 18.47px;
            left: 2.71px;
            width: 59.93px;
            height: 44.74px;
        }

        .vector-icon26 {
            top: 20.79px;
            left: 7.49px;
            width: 52.9px;
            height: 40.09px;
        }

        .group-icon51,
        .group-icon52 {
            position: absolute;
            top: 31.65px;
            left: 46.61px;
            width: 20.14px;
            height: 15.52px;
        }

        .group-icon52 {
            top: 44.07px;
            left: 0;
            width: 41.23px;
            height: 22.64px;
        }

        .group-icon53,
        .group-icon54 {
            position: absolute;
            top: 1.71px;
            left: 26.87px;
            width: 6.17px;
            height: 8.81px;
            opacity: 0.77;
        }

        .group-icon54 {
            top: 46.28px;
            left: 23.11px;
            width: 12.89px;
            height: 13.46px;
        }

        .frame21,
        .vector-icon27 {
            position: absolute;
            top: 9.3px;
            left: 48.21px;
            width: 2.32px;
            height: 2.43px;
        }

        .frame21 {
            top: 109px;
            left: 70.5px;
            width: 66.82px;
            height: 66.71px;
            font-size: 48px;
            color: #ffc96f;
        }

        .layer-1 {
            position: absolute;
            top: 13px;
            left: 4.5px;
            width: 165.6px;
            height: 190.12px;
        }

        .motor-insurance-health,
        .retail-segment {
            position: relative;
            font-family: Poppins;
            color: #252525;
        }

        .retail-segment {
            font-size: 20px;
            font-weight: 600;
            text-align: left;
        }

        .motor-insurance-health {
            align-self: stretch;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
        }


        .vector-icon28 {
            position: relative;
            width: 160px;
            height: 160px;
        }

        .div17 {
            position: absolute;
            top: 2.33px;
            left: 3.29px;
            text-transform: capitalize;
            font-weight: 600;
            display: inline-block;
            width: 8px;
            height: 18.67px;
            -webkit-text-stroke: 0.2px #000;
        }

        .frame26 {
            position: relative;
            width: 144.57px;
            height: 144.62px;
            margin-top: -152px;
        }

        .vector-group {
            position: absolute;
            top: 10.02px;
            left: 10px;
            width: 160px;
            height: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }

        .other-insurances {
            position: relative;
            font-size: 20px;
            font-weight: 600;
        }

        .vehicle-insurance-for {
            align-self: stretch;
            position: relative;
            line-height: 26px;
            text-transform: capitalize;
            text-align: center;
        }

        .various-area,
        .various-points08 {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
        }

        .various-area {
            align-self: stretch;
            flex-wrap: wrap;
            gap: 24px;
            text-align: left;
            font-size: 20px;
            color: #252525;
        }

        .client-solutions,
        .client-solutions-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        .client-solutions-inner {
            width: 100%;
            gap: 20px;
            max-width: 1324px;
        }

        .client-solutions {
            align-self: stretch;
            background-color: rgba(30, 113, 236, 0.04);
            padding: 80px 12px;
        }

        .dr-reddys-tenure {
            flex: 1;
            position: relative;
            font-weight: 600;

            h2 {
                font-size: 40px;
                font-weight: 600;
            }
        }

        .description {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .icon-child {
            position: relative;
            border-radius: 50%;
            background-color: #00b587;
            width: 22px;
            height: 22px;
            z-index: 0;
        }

        .vector-icon34 {
            position: absolute;
            margin: 0 !important;
            top: calc(50% - 4.75px);
            left: calc(50% - 6.75px);
            width: 13.5px;
            height: 9.75px;
            z-index: 1;
        }

        .icon {
            width: 22px;
            flex-direction: row;
            padding: 1px 0;
            box-sizing: border-box;
            position: relative;
            gap: 10px;
        }

        .desctiption,
        .icon,
        .point012 {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        .desctiption {
            flex: 1;
            flex-direction: column;
        }

        .point012 {
            align-self: stretch;
            flex-direction: row;
            gap: 12px;
        }

        .honorable-justice-sri {
            font-weight: 500;
        }

        .sub-point01 {
            width: 588px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }

        .sub-content,
        .sub-points {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .sub-points {
            align-self: stretch;
            justify-content: flex-start;
            gap: 20px;
        }

        .sub-content {
            flex: 1;
            justify-content: center;
            gap: 16px;
        }

        .he-ranked-top {
            align-self: stretch;
            position: relative;
            font-weight: 500;
        }

        .content {
            align-self: stretch;
            gap: 20px;
            font-size: 16px;
        }

        .achievements,
        .content,
        .description1,
        .point013 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .achievements {
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            gap: 20px;
            max-width: 1324px;
            text-align: left;
        }

        .description1,
        .point013 {
            align-self: stretch;
            gap: 10px;
        }

        .point013 {
            flex-direction: row;
            justify-content: center;
            gap: 12px;
            text-align: justify;
        }
    }

}