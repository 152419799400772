@import "./mixins.scss";

.productPagesWrapper {
  padding-top: 90px;

  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
  }

  h1 {
    @include fontSize(45px);
    font-weight: 600;

    @media (max-width: 767px) {
      @include fontSize(30px);

      img {
        height: 50px;
      }
    }
  }

  h2 {
    @include fontSize(26px);
    line-height: 40px;

    span {
      position: relative;
      margin-bottom: 8px;

      &:before {
        background: url("../../public/images/heading_bg.svg") no-repeat 0px 0px/100%;
        @include position(absolute, $left: 0px, $bottom: -8px);
        content: "";
        width: 100%;
        height: 8px;
      }
    }
  }

  h3 {
    @include fontSize(26px);
    line-height: 40px;

    span {
      position: relative;
      margin-bottom: 8px;

      &:before {
        background: url("../../public/images/heading_bg.svg") no-repeat 0px 0px/100%;
        @include position(absolute, $left: 0px, $bottom: -8px);
        content: "";
        width: 100%;
        height: 8px;
      }
    }
  }

  ol {
    margin-left: 17px;

    li {
      @include fontSize(14px);
      margin-bottom: 6px;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .columnReverse {
    @media screen and (max-width: 960px) {
      flex-direction: column-reverse;

      .formSection {
        margin-bottom: 32px;
        margin-top: 0;
      }
    }
  }

  .tickTitle {
    color: $theme-blue;
    @include fontSize(16px);
    border-bottom: 1px dashed rgba($black, 0.2);
    display: inline-block;
    position: relative;
    padding: 0px 0px 10px 20px;
    margin-bottom: 8px;

    &:before {
      content: "";
      @include position(absolute, $left: 0px, $top: 7px);
      border-left: 1.4px solid $theme-blue;
      border-bottom: 1.4px solid $theme-blue;
      transform: rotate(-45deg);
      height: 5px;
      width: 10px;
    }
  }

  .sectionSpacing {
    margin-bottom: 80px;

    @media screen and (max-width: 959px) {
      margin-bottom: 40px;
    }
  }

  .claimContainer {
    @media screen and (min-width: 900px) {
      margin: 0 auto;
      max-width: 360px;
    }

    .claimSection {
      position: relative;
      width: fit-content;

      span {
        position: absolute;
        right: -70px;
        font-size: 14px;
        color: rgb(var(--accent_bg_btn));
        background-color: #fff0b9;
        height: 24px;
        line-height: 25px;
        padding: 0 15px;
        border-radius: 25px;
        font-weight: 700;
        top: 3px;
      }

    }

    .ratingSection {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;
      border-bottom: 1px dashed #203864;
      width: fit-content;
      min-width: 360px;


      @media screen and (max-width: 600px) {
        min-width: 100%;
        gap: 8px;
      }

      @media screen and (max-width: 320px) {
        gap: 4px;
      }

      .starRating {
        display: flex;
        flex-direction: column;
      }

    }
  }







  %btn {
    background: $theme-lightgreen;
    color: $white;
    @include border-radius(12px);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    box-shadow: none;

    &:hover {
      background: lighten($theme-lightgreen, 5%);
      @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
    }

    svg {
      animation: animName 2s linear infinite;
      margin: 0px 20px;
    }

    @keyframes animName {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }
  }

  .greenXlBtn {
    @extend %btn;
    @include fontSize(16px);
    padding: 12px 30px;
    line-height: 24px;
  }

  .underline {
    position: relative;
    margin-bottom: 8px;
    // display: inline-block;

    &:before {
      background: url("../../public/images/heading_bg.svg") no-repeat 0px 0px/100%;
      @include position(absolute, $left: 0px, $bottom: -8px);
      content: "";
      width: 100%;
      height: 8px;
    }
  }

  .formSection {
    border: 1px dashed $theme-blue;
    border-top: none;
    @include box-shadow(0px 10px 12px rgba(#1e71ec, 0.1));
    @include border-radius(0px 0px 20px 20px);
    // padding: 0px 12px 12px;
    min-height: 370px;

    @media screen and (max-width: 1199px) {
      margin-top: 32px;
    }

    &.carbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/carForm_Background.svg") no-repeat bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    &.twbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/bikeformbg.svg") no-repeat bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    .backnavigate {
      position: relative;
    }

    .healthbg {
      position: relative;
      padding-top: 0px !important;
      min-height: auto;

      .heading_desc {
        display: none;
      }

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/healthformbg.svg") no-repeat bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    &.termbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/termformbg.svg") no-repeat bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    h1 {
      font-weight: 400;
      @include fontSize(22px);

      .greenText {
        color: $theme-lightgreen;
        position: relative;
        margin: 0px 8px;

        &:before {
          background: url("../../public/images/bestTag_bg.svg") no-repeat 0px 0px/100%;
          @include position(absolute, $left: -12px, $top: -1px);
          content: "";
          width: 70px;
          height: 60px;
        }
      }

      span {
        font-weight: 600;

        &:before {
          background: none;
        }
      }
    }

    .newAndRenew {
      @include fontSize(16px);

      a {
        font-weight: 600;
        color: $theme-lightgreen;
      }
    }

    .backStep {
      color: #000000;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      height: 32px;
      width: 32px;
      display: inline-block;

      &:hover {

        &:before,
        &:after {
          background: $theme-lightgreen;
          left: 10px;
        }
      }

      &:before {
        content: "";
        width: 1px;
        height: 10px;
        background: $black;
        @include position(absolute, $left: 12px, $top: 8px);
        transform: rotate(45deg);
        transition: all ease-in-out 0.3s;
        display: none;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
        top: 15px;
      }
    }

    .claimed {
      font-family: "Poppins", sans-serif;
      @include fontSize(16px);
      font-weight: 500;
      line-height: 16px;
      margin: 10px 0px;
      color: $black;
      margin: 0;

      span {
        @include fontSize(14px);
        font-weight: 400;
        line-height: 16px;
        color: rgba($black, 0.5);
        padding-top: 8px;
      }
    }

    .radioBoxList {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      &.left_align {
        justify-content: left;
        padding-left: 0;
      }

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 6px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: #203864;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.4);
        }

        &:hover {
          background: rgba(#203864, 0.2);
        }
      }
    }

    .disabledCheckbox {
      .MuiTypography-root {
        margin-left: 0px;

        a {
          color: $theme-lightgreen;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .Mui-disabled {
        color: $black;
      }
    }

    .radioBox {
      button {
        @include border-radius(8px !important);
        border: 1px solid rgba(32, 56, 100, 0.2);
        text-transform: capitalize;
        margin: 0px 6px;
        padding: 8px 16px;
        height: 30px;
        @include fontSize(14px);
        line-height: 14px;
        font-weight: 500;
        color: #000;
        font-family: "poppins";
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        &.Mui-selected {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.4);
          font-weight: 500;
        }
      }

      &.healthradioBox {
        button {
          height: auto;
          display: block;

          $othericons: oneAdult, twoAdults;
          $k: 0;

          @each $icons in $othericons {
            $bacRightP: $k * 33;
            $k: $k + 1;

            .#{$icons} {
              position: relative;
              background: url("../../public/images/health_adults_icons.svg") no-repeat -#{$bacRightP}px 0px/66px;
              height: 33px;
              width: 33px;
              margin: 0 auto 8px;
            }
          }

          &.Mui-selected {
            $othericons: oneAdult, twoAdults;
            $k: 0;

            @each $icons in $othericons {
              $bacRightP: $k * 33;
              $k: $k + 1;

              .#{$icons} {
                background: url("../../public/images/health_adults_icons_blue.svg") no-repeat -#{$bacRightP}px 0px/66px;
              }
            }
          }
        }
      }
    }

    .toinsure {
      font-family: "Poppins", sans-serif;
      @include fontSize(14px);
      font-weight: 500;
      line-height: 14px;
      margin: 0px;

      span {
        @include fontSize(16px);
        color: $theme-lightgreen;
      }
    }

    .rangeSlider {
      color: $theme-lightgreen;
      height: 6px;

      .MuiSlider-rail {
        opacity: 0.2;
      }

      .Mui-focusVisible {
        @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
      }

      .MuiSlider-thumb {
        height: 24px;
        width: 24px;

        &:hover {
          @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
        }
      }
    }

    .varient_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 32px 0;

      &.left_align {
        justify-content: left;
        padding-left: 0;
      }

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 10px 8px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: #203864;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba(32, 56, 100, 0.4);
        }

        &:hover {
          background: rgba(#203864, 0.2);
        }
      }
    }

    .manufactour_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 32px 0;

      &.left_align {
        justify-content: left;
        padding-left: 0;
      }

      li {
        width: 120px;
        max-height: 91px;
        padding: 62px 12px 16px;
        border-radius: 6px;
        background: $white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 10px;
        line-height: 15px;
        cursor: pointer;
        color: $black;
        border: 0;
        text-align: center;
        position: relative;

        &.active {
          background: $theme-blue !important;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        &:first-child {
          margin-left: 0px;
        }

        &:hover {
          background: rgba($theme-blue, 0.1);
        }
      }

      $othericons: hyundai, maruti, tata, volkswagen, mahindra, kia, honda,
        skoda, toyota, mercedes, audi, bmw;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 60;
        $k: $k + 1;

        .#{$icons} {
          position: absolute;
          background: url("./../../public/images/manufacture_imgs.svg") no-repeat -#{$bacRightP}px 0px/720px;
          height: 50px;
          width: 60px;
          top: 10px;
          left: calc(50% - 30px);
        }
      }

      $othericons: bajaj, tvs, hero, honda, royalenfield, ktm;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 50;
        $k: $k + 1;

        .#{$icons} {
          position: absolute;
          background: url("./../../public/images/bikemanufacture_imgs.svg") no-repeat -#{$bacRightP}px 0px/300px;
          height: 50px;
          width: 50px;
          top: 10px;
          left: calc(50% - 25px);
        }
      }
    }

    .insuredMembers {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 32px 0;

      li {
        width: 102px;
        max-height: 91px;
        padding: 48px 8px 8px;
        border-radius: 6px;
        background: $white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 10px;
        line-height: 15px;
        cursor: pointer;
        color: $black;
        border: 0;
        text-align: center;
        position: relative;

        &.active {
          background: $theme-blue !important;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        &:first-child {
          margin-left: 0px;
        }

        &:hover {
          background: rgba($theme-blue, 0.1);
        }

        // health adults list
        $othericons: oneadult, twoadults;
        $k: 0;

        @each $icons in $othericons {
          $bacRightP: $k * 33;
          $k: $k + 1;

          .#{$icons} {
            position: absolute;
            background: url("./../../public/images/health_adults_icons.svg") no-repeat -#{$bacRightP}px 0px/66px;
            height: 33px;
            width: 33px;
            top: 10px;
            left: calc(50% - 16px);

            &.active {
              background: url("./../../public/images/health_adults_icons_blue.svg") no-repeat -#{$bacRightP}px 0px/66px;
            }
          }
        }
      }
    }
  }

  .greybg {
    background: rgba(#1e71ec, 0.04);
  }

  .boderWhitegradientBox {
    border-radius: 0px 0px 12px 12px;
    border-right: 1px dashed $theme-blue;
    border-bottom: 1px dashed $theme-blue;
    border-left: 1px dashed $theme-blue;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    box-shadow: 0px 10px 12px 0px rgba(30, 113, 236, 0.1);
    padding: 0px 20px 12px 20px;
    margin: 12px 0px;
    display: inline-block;
    width: 100%;

    p {
      opacity: 0.5;
    }
  }

  .whiteGradientBox {
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    box-shadow: 0px 4px 4px 0px rgba(32, 56, 100, 0.1);
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0px;
    gap: 12px;
    flex: 1 0 0;

    .icon {
      @include border-radius(10px);
      display: flex;
      width: 80px;
      height: 80px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      background: rgba(30, 113, 236, 0.04);

      img {
        width: 100%;
        max-width: 60px;
        height: 60px;
      }
    }

    h5 {
      @include fontSize(14px);
    }
  }



  .recommendation {
    border: 1px dashed $theme-lightgreen;
    @include border-radius(12px);
    padding: 8px;

    .inner {
      background: rgba(#1e71ec, 0.04);
      @include border-radius(12px);
      display: flex;
      flex-direction: column;
      padding: 12px;
      align-items: center;
      align-content: center;
      justify-content: center;
      gap: 12px;
      align-self: stretch;
      flex-wrap: wrap;
      text-align: center;

      @media screen and (max-width: 767px) {
        .ctaBtn {
          width: 100%;
        }
      }

      h4 {
        @include fontSize(20px);
      }
    }
  }

  .numberBox {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    padding-bottom: 24px;

    li {
      display: flex;
      min-width: 340px;
      padding: 24px 12px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 12px;
      background: #fff;
      box-shadow: 4px 4px 0px 0px #e3edff;

      div {
        border-radius: 50px;
        background: rgba(#1e71ec, 0.04);
        height: 60px;
        width: 60px;
        position: relative;

        p {
          @include position(absolute, $left: 0px, $top: -24px);
          @include fontSize(48px);
          font-weight: 600;
          color: $white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }
      }

      h4 {
        @include fontSize(16px);
      }

      p {
        opacity: 0.5;
      }
    }
  }

  .numberList {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;

    li {
      display: flex;
      min-width: 588px;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;

      @media (max-width: 600px) {
        min-width: 100%;
        gap: 12px;
      }

      h4 {
        @include fontSize(16px);
      }

      span {
        border-radius: 20px;
        box-shadow: 2px 2px 0px 0px #e3edff;
        display: flex;
        font-size: 16px;
        min-width: 40px;
        width: 40px;
        height: 40px;
        max-height: 40px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}