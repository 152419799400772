// Color Variables
$black: #000000;
$white: #ffffff;
$grey: #808080;
$red: #D9132E;
$theme-blue:#203864;
$theme-green:#00785A;
$theme-lightgreen:#00B587;
// $theme-green: #47b74f;
$theme-yellow: #ffdc6d;

$primary: #568ee8;
$secondary: #ea4559;
$lightbg: #fbfbfb;

// Class Variables
$quote: ".quote";
$proposal: ".proposal";
$payment: ".payment";
$documents: ".documents";
$policyConfirm: ".policyConfirm";
$pageNotFound: ".pageNotFound";

/*--------------Mixins------------------*/
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  //font-size: $size;
  font-size: calculateRem($size);
}

@mixin position($position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin abs-position ($top, $right, $bottom, $left) {
  // @include abs-position(10px, 10px, auto, auto);
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Margins
@for $i from 0 to 20 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }

  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }

  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }

  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }

  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }
}

// Paddings
@for $i from 0 to 20 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }

  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }

  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }

  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }

  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }

  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }
}