.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media(max-width: 899px) {
    width: 40px;
    height: 40px;
    bottom: 16px;
    right: 16px;
  }

  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  .contact_icon {
    background-color: #42db87;
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 30px;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translatey(0px);
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 0 0 #42db87;
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    text-decoration: none !important;
    transition: all 300ms ease-in-out;

    @media(max-width: 899px) {
      width: 40px;
      height: 40px;
      transition: none;
      animation: none;
      // box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 30%),
      //   0px 1px 3px 1px rgb(0 0 0 / 20%);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    svg {
      width: 30px;
      height: 30px;
      fill: #fff;

      @media(max-width: 899px) {
        width: 24px;
        height: 24px;
      }
    }
  }
}


.floating_btn2 {
  @extend .floating_btn;

  @media(max-width: 899px) {
    bottom: 74px;
  }

  .contact_icon {
    background: #2196f3;

    svg {
      @media(max-width: 899px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}