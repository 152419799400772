@import '../../../../../CommonSCSS/mixins.scss';


#{$pageNotFound}Wrapper {
    .MuiGrid2-container {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    background: $lightbg;
    padding-top: 80px;
    min-height:100vh;
    h3 {
        @include fontSize(24px);
        font-weight: 700;
    }
    p {
        @include fontSize(15px);
        opacity: 0.5;
    }
    .imgSection {
        max-width: 340px;
        margin: 120px auto 24px;
        position: relative;
        z-index: 9;
        img {
            width: 100%;
        }
        .oppsImg {
            @include position(absolute, $right:80px, $top:-80px);
            animation: swing ease-in-out 2s infinite alternate;
            transform-origin: center bottom -20px;
            width: 140px;
            z-index: -1;
        }
        @keyframes swing {
            0% { transform: rotate(4deg); }
            100% { transform: rotate(-4deg); }
        }
        .eyes {
            display: flex;
            justify-content: space-between;
            @include position(absolute, $right:calc(50% - 50px), $top:50px);
            width: 90px;
            z-index: 9;
            .eye {
                background: $white;
                width: 26px;
                height: 30px;
                border-radius: 50%;
                display: inline-block;
                overflow: hidden;
                position: relative;
                &:before {
                    content: '';
                    @include position (absolute, $left:0px, $right:0px, $top:0px, $bottom:0px);
                    margin: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: rgba(20,20,20,1);
                }
                &.sad:before {
                    animation: sadb 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
                }
            }
            @keyframes sadb {
                0%, 10% {
                    top: 0;
                }
                20%, 40% {
                    top: 50%;
                }
                50%, 100% {
                    top: 0;
                }
            }
        }
    }

}
