@import "../../../CommonSCSS/mixins.scss";

.cmsWrapper {
    padding-top: 150px;
    @media (max-width: 960px) {
        padding-top: 60px;
    }
    
    h1,h2, h3, h4 {
        font-weight: 600;
    }
    h1 {
        @include fontSize(45px);
        margin-bottom: 60px;
        @media (max-width: 960px) {
            @include fontSize(18px);
            margin-bottom: 8px;
        }
    }
    h2 {
        @include fontSize(45px);
        margin-bottom: 60px;
        @media (max-width: 960px) {
            @include fontSize(18px);
            margin-bottom: 8px;
        }
    }
    h3 {
        @include fontSize(20px);
        margin-top: 30px;
        margin-bottom: 12px;
        @media (max-width: 960px) {
            @include fontSize(14px);
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }
    p {
        @include fontSize(14px);
        line-height: 24px;
        margin-bottom: 12px;
        @media (max-width: 960px) {
            @include fontSize(11px);
            line-height: 14px;
            margin-bottom: 10px;
        }
        span {
            font-weight: 600;
        }
    }
    a {
        color: $theme-lightgreen;
        text-decoration: none;
    }
}