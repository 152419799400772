@import "../../../CommonSCSS/mixins.scss";

.switch {
    @include border-radius(4px);
    border: 1px solid $black;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 32px;
    overflow: hidden;
    button {
        text-transform: capitalize;
        @include fontSize(12px);
        font-weight: 400;
        &.Mui-selected {
            color: $white;
            background-color: $primary;
            &:hover {
                background-color: $primary;
            }
        }
    }
    // &Label {
    //     position: relative;
    //     line-height: 27px;
    //     @include fontSize(12px);
    //     margin: 0px;
    //     color: #000;
    //     text-align: center;
    //     cursor: pointer;
    //     z-index: 2;
    //     width: 40px;
    // }
    // &Input {
    //     display: none;
    //     &:checked+ {
    //         .switchLabel {
    //             color: $white;
    //             -webkit-transition: 0.2s ease-out;
    //             -moz-transition: 0.2s ease-out;
    //             -ms-transition: 0.2s ease-out;
    //             -o-transition: 0.2s ease-out;
    //             transition: 0.2s ease-out;
    //         }
    //         .switchOn~.switchSelection {
    //             left: 40px;
    //         }
    //     }
    // }

    // &Selection {
    //     @include position(absolute, $left:0px, $top:0px);
    //     z-index: 1;
    //     width: 40px;
    //     height: 32px;
    //     background-color: $primary;
    //     -webkit-transition: left 0.2s ease-out;
    //     -moz-transition: left 0.2s ease-out;
    //     -ms-transition: left 0.2s ease-out;
    //     -o-transition: left 0.2s ease-out;
    //     transition: left 0.2s ease-out;
    // }
}