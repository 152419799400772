@import "./CommonSCSS/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  height: 100%;
  margin: 0px;
  padding: 0px;
  @include fontSize(16px);
  overflow-x: hidden;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}
p {
  @include fontSize(14px);
}

// Margins
@for $i from 0 to 20 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }

  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }

  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }

  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }

  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }

  .m-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}

// Paddings
@for $i from 0 to 20 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }

  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }

  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }

  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }

  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }

  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }

  .p-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }
}
.text-right{
  text-align: right !important;
}
.text-center{
  text-align: center !important;
}
ul{
  margin: 0;
  padding: 0;
  li{
    list-style: none;
  }
}
button{
  font-family: "Poppins", sans-serif !important;
}
%btn {
  background: $theme-lightgreen;
  color: $white;
  @include border-radius(12px);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: capitalize;

  &:hover {
    background: lighten($theme-lightgreen, 5%);
    @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
  }
}

.greenXlBtn {
  @extend %btn;
  @include fontSize(16px);
  padding: 12px 30px;
  line-height: 24px;
}

.greenLgBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 10px 20px;
  line-height: 21px;
}

.greenMdBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 6px 16px;
}

.greenSmBtn {
  @extend %btn;
  @include fontSize(14px);
  padding: 3px 14px;
}

.greenXsBtn {
  @extend %btn;
  @include fontSize(12px);
  padding: 2px 14px;
}
.roundedBtn {
  @include border-radius(100px !important);
  box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0.2) !important;
}

.MuiGrid2-container {
  max-width: 1360px;
  margin-left: auto!important;
  margin-right: auto!important;
  width: 100% !important;
}
img{
  vertical-align: middle;
}
.text-xs-center{
 @media(max-width:600px){
  text-align: center;
 }
}
.MuiDialogContent-root{
  overflow:hidden;
}
.textGrey {
  color: rgba(0, 0, 0, 0.5);
}
.textBold {
  font-weight: 600;
}
.text-md-left{
  @media(max-width:899px){
    text-align: left !important;
  }
}
.text-md-right{
  @media(max-width:899px){
    text-align: right !important;
  }
}
.text-md-left{
  @media(max-width:899px){
    text-align: left !important;
  }
}
.text-md-center{
  @media(max-width:899px){
    text-align: center !important;
  }
}



