@import "../../../CommonSCSS/mixins.scss";

.ctaBtn {
  %primary {
    background: $primary;
    color: $white;
    @include border-radius(6px);
    text-transform: capitalize;

    &:hover {
      background: lighten($primary, 10%);
    }
  }

  %secondary {
    background: $secondary;
    color: $theme-green;
    @include border-radius(6px);
    text-transform: capitalize;

    &:hover {
      background: lighten($secondary, 8%);
    }
  }

  %tertiary {
    background: transparent;
    color: $theme-lightgreen;
    text-transform: capitalize;
    @include border-radius(60px);
    box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0);

    &:hover {
      background: $white;
      box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0);
    }
  }

  .textMdBtn {
    @extend %tertiary;
    @include fontSize(14px);
    padding: 8px 20px;
  }

  .smallSecondaryBtn {
    @extend %secondary;
    @include fontSize(12px);
    padding: 4px 24px;
  }

  .regularSecondaryBtn {
    @extend %secondary;
    @include fontSize(16px);
    padding: 8px 32px;
  }

  .regularPrimaryBtn {
    @extend %primary;
    @include fontSize(16px);
    padding: 8px 32px;
  }

  %btn {
    background: $theme-lightgreen;
    color: $white;
    @include border-radius(8px);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0);

    &:hover {
      background: lighten($theme-lightgreen, 5%);
      @include box-shadow(0px 0px 0px rgba($black, 0));
    }

    &.Mui-disabled {
      background: $theme-lightgreen !important;
    }

    .loader {
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $white;
      border-right-color: transparent;
      transform-origin: center;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .greenXlBtn {
    @extend %btn;
    @include fontSize(16px);
    font-weight: 400;
    padding: 12px 28px;
    line-height: 24px;
  }
  .greenLgBtn {
    @extend %btn;
    @include fontSize(14px);
    font-weight: 500;
    padding: 12px 24px;
    line-height: 21px;
  }

  .greenMdBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 8px 20px;
  }

  .greenSmBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 4px 14px;
  }

  .greenXsBtn {
    @extend %btn;
    @include fontSize(12px);
    padding: 2px 14px;
  }

  // red btns
  .redMdBtn {
    @extend %btn;
    @include fontSize(14px);
    padding: 8px 20px;
    background: #ea4559;
  }
  .redSmBtn {
    @extend %btn;
    @include fontSize(12px);
    padding: 8px 12px;
    background: #ea4559;
  }

  // blue btns
  // .blueMDBtn {
  //   @extend %btn;
  //   @include fontSize(14px);
  //   padding: 6px 16px;
  //   background: #203864;
  //   font-weight: 400;
  //   border-radius: 8px;
  //   border: 1px solid #203864;
  //   &:hover {
  //     background-color: transparent;
  //     color: #203864;
  //     border-color: #203864;
  //   }
  // }

  //bordered btns

  .borderMDBtn {
    @extend %btn;
    @include fontSize(14px);
    background: transparent;
    padding: 6px 16px;
    border: 1px solid #203864;
    color: #203864;
    border-radius: 8px;
    &:hover {
      background-color: #203864;
      color: #fff;
    }
    &.active {
      background: #203864;
      color: #fff;
    }
  }
}
