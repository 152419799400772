@import "./../../../CommonSCSS/mixins.scss";

.Navbar {
    @include position(fixed, $top: 0px, $left: 0px);
    z-index: 99;
    width: 100%;
    background: $white;
    &.box-shadow{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    .row {
        max-width: 2000px !important;
        margin-left: auto;
        margin-right: auto;
    }
    a {
        cursor: pointer;
    }
    .top-strip {
        background: linear-gradient(180deg, rgba(225, 237, 255, 0.07) 0%, rgba(225, 237, 255, 0.63) 100%);
        width: 100%;
        p {
            @include fontSize(16px);
            text-align: center;

            span {
                font-weight: 600;
            }
        }
    }

    .logo-section {
        .logo {
            max-width: 210px;
        }
    }

    .nav-section {
        ul {
            display: flex;
            align-items: center;
            justify-content: end;

            li {
                margin-left: 16px;

                &:first-child {
                    margin-left: 0;
                }

                .nav-link {
                    text-decoration: none;
                    @include fontSize(14px);
                    color: $black;
                    cursor: pointer;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    display: inline-block;
                    &:hover {
                        color: $theme-lightgreen;
                    }
                    &.product-link{
                        text-transform: capitalize;
                        font-family: "poppins";
                        color: $black;
                        padding: 2px 16px 2px 0px;
                        min-height: 10px;
                        font-weight: 400;
                        position: relative;
                        &:before{
                            @include position(absolute, $top:12px, $right:0);
                            content:'';
                            background: url("./../../../../public/images/bottom-dropdown-icon.svg") no-repeat 0 0/10px;
                            height: 7px;
                            width: 10px;
                        }
                        &:hover {
                            color: $theme-lightgreen;
                            background: none;
                            transition: none;
                        }
                        &:focus,&:active{
                            background: none;
                            transition: none;
                        }
                    }
                }

                .ca-link {
                    text-decoration: none;
                    @include fontSize(14px);
                    color: $black;
                    display: inline-block;
                    padding: 9px 22px;
                    @include border-radius(30px);
                    border: 1px solid $black;
                    position: relative;

                    &:hover {
                        color: $theme-lightgreen;
                        border: 1px solid $theme-lightgreen;
                    }

                    span {
                        @include position(absolute, $top: -11px, $right: 12px);
                        @include border-radius(30px);
                        background: #F8E007;
                        color: $black;
                        padding: 4px 8px;
                        display: inline;
                        p {
                            @include fontSize(9px);
                            font-weight: 600;
                        }
    
                        .blink_text {
                            animation-name: blinker;
                            animation-duration: 1s;
                            animation-timing-function: linear;
                            animation-iteration-count: infinite;
                        }
                        &.boxblink {
                            animation-name: boxblinker;
                            animation-duration: 1s;
                            animation-timing-function: linear;
                            animation-iteration-count: infinite;
                            transition: all ease-in-out 1s;
                        }
    
                        @keyframes blinker {
                            0% {
                                opacity: 1.0;
                            }
    
                            50% {
                                opacity: 0.1;
                            }
    
                            100% {
                                opacity: 1.0;
                            }
                        }
                        @keyframes boxblinker {
                            0% {
                                scale: 1;
                            }
    
                            50% {
                                scale: 0.6;
                            }
    
                            100% {
                                scale: 1;
                            }
                        }
                    } 
                }
                .pir-link {
                    background: #E1EDFF;
                    @include box-shadow(0px 6px 8px rgba($theme-blue, 0.1));
                    text-decoration: none;
                    @include fontSize(14px);
                    color: $theme-blue;
                    display: inline-block;
                    padding: 9px 22px;
                    @include border-radius(30px);
                    border: none;
                    position: relative;

                    &:hover {
                        span {
                            right: 116px;
                            @media (max-width:980px) {
                                right: 70px;
                            }
                        }
                    }

                    span {
                        @include position(absolute, $top: -9px, $right: 22px);
                        @include border-radius(30px);
                        background: $red;
                        color: $white;
                        padding: 2px 8px;
                        display: inline;
                        transition: all ease-in-out 0.5s;

                        p {
                            @include fontSize(9px);
                            font-weight: 600;
                        }

                    }
                }
                .pos-link {
                    background: $theme-blue;
                    @include box-shadow(0px 6px 8px rgba($theme-blue, 0.1));
                    text-decoration: none;
                    @include fontSize(14px);
                    color: $white;
                    display: inline-block;
                    padding: 9px 16px;
                    @include border-radius(30px);
                    border: none;
                    position: relative;
                }
            }
        }
    }
    .rounded {
        @include border-radius(100px!important);
        box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0.2)!important;
    }
}
