@import "../../../../CommonSCSS/mixins.scss";

.complaint_wrapper {
    margin-top: 100px;

    .main_heading {
        h1,h5 {
            font-family: "Poppins", sans-serif;
            @include fontSize(18px);
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            margin-bottom: 0;

            span {
                display: block;
                @include fontSize(11px);
                font-weight: 400;
                line-height: 14px;
                color: #808080;
                padding-top: 8px;
            }
        }
    }

    .form_details {
        border-radius: 0px 0px 12px 12px;
        @include border-radius(0 0 12px 12px);
        border-right: 1px dashed rgba(30, 113, 236, 0.50);
        border-bottom: 1px dashed rgba(30, 113, 236, 0.50);
        border-left: 1px dashed rgba(30, 113, 236, 0.50);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(30, 113, 236, 0.08) 100%);
        box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
        padding: 0 16px 20px;
        margin: 0 0 24px;

        h6 {
            font-family: "Poppins", sans-serif;
            @include fontSize(18px);
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            margin-bottom: 20px;

            span {
                display: block;
                @include fontSize(11px);
                font-weight: 400;
                line-height: 14px;
                color: #808080;
                padding-top: 8px;
            }
        }
    }

    .customer_support {
        border: 1px dashed #203864;
        padding: 12px 24px;
        @include border-radius(12px);

        h5 {
            font-family: "Poppins", sans-serif;
            @include fontSize(14px);
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            margin-bottom: 8px;

            .MuiTypography-root {
                color: #00B587;
                font-weight: 500;
                text-decoration: none;
                cursor: pointer;
                display: inline-block;
            }
        }

        p {
            font-family: "Poppins", sans-serif;
            @include fontSize(14px);
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            margin-bottom: 0px;
            color: #203864;
            color: lighten($color: #203864, $amount: 50%);
        }
    }
}