

.contact-parent,
.frame-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.frame-wrapper {
    align-items: flex-start;
    max-width: 1324px;
    text-align: left;
    font-size: 14px;
}

.contact-parent {
    position: relative;
    background-color: #fff;
    padding: 20px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 60px;
    text-align: center;
    font-size: 45px;
    color: #252525;
    font-family: Poppins;
    padding-top: 140px;

    .contact-us1 {
            align-self: stretch;
            position: relative;
            font-weight: 600;
            h1{
                font-size: 45px;
                font-weight: 600;
            }
        }
    
        .contact-us {
            width: 1324px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    
        .were-here-to {
            align-self: stretch;
            position: relative;
            font-size: 16px;
            line-height: 32px;
            opacity: 0.6;
        }
    
        .contact-us-parent {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            max-width: 1324px;
            margin: 0px auto;
        }
    
        .g158-icon {
            position: relative;
            width: 195.42px;
            height: 183.05px;
        }
    
        .g158-wrapper {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    
        .support-mail {
            font-weight: 500;
        }
    
        .carebimastreetcom,
        .support-mail {
            position: relative;
        }
    
        .other-query-parent,
        .support-mail-parent {
            align-self: stretch;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    
        .support-mail-parent {
            background: linear-gradient(90deg,
                    rgba(0, 181, 135, 0.3),
                    rgba(255, 255, 255, 0));
            padding: 8px 12px;
        }
    
        .other-query-parent {
            background: linear-gradient(90deg,
                    rgba(32, 56, 100, 0.3),
                    rgba(255, 255, 255, 0));
            padding: 12px;
        }
    
        .div {
            position: relative;
            text-transform: lowercase;
        }
    
        .phone-number-parent {
            align-self: stretch;
            border-radius: 12px;
            background: linear-gradient(90deg,
                    rgba(217, 19, 46, 0.3),
                    rgba(255, 255, 255, 0));
            padding: 12px;
        }
    
        .frame-container,
        .frame-div,
        .phone-number-parent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    
        .frame-div {
            align-self: stretch;
            gap: 15px;
        }
    
        .frame-container {
            flex: 1;
            gap: 24px;
        }
    
        .bimastreet {
            margin: 0;
        }
    
        .bimastreet-amaze-insurance-container {
            position: relative;
            font-weight: 600;
        }
    
        .the-legend-metro {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            display: inline-block;
            width: 308px;
        }
    
        .bimastreet-amaze-insurance-bro-parent,
        .frame-wrapper1 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    
        .bimastreet-amaze-insurance-bro-parent {
            align-self: stretch;
            align-items: flex-start;
            gap: 10px;
        }
    
        .frame-wrapper1 {
            flex: 1;
            border-radius: 12px;
            background-color: rgba(30, 113, 236, 0.04);
            padding: 24px;
            align-items: center;
            font-size: 20px;
        }
    
        .share-your-information {
            align-self: stretch;
            position: relative;
            font-size: 14px;
            opacity: 0.5;
        }
    
        .fill-the-details-parent {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            text-align: center;
            font-size: 20px;
        }
    
        .comprehensive1 {
            flex: 1;
            position: relative;
            line-height: 24px;
        }
    
        .frame-item,
        .vector-icon1 {
            position: relative;
            width: 13.09px;
            height: 12px;
            display: none;
        }
    
        .vector-icon1 {
            width: 10px;
            height: 5.63px;
        }
    
        .comprehensive-group,
        .plan-type-container {
            position: absolute;
            background-color: #fff;
            flex-direction: row;
            justify-content: flex-start;
        }
    
        .comprehensive-group {
            width: 100%;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            height: 42px;
            display: flex;
            padding: 12px;
            align-items: center;
        }
    
        .plan-type-container {
            bottom: 34px;
            left: 7.04px;
            border-radius: 20px;
            display: none;
            padding: 0 4px;
            align-items: flex-start;
            font-size: 10px;
            color: #00785a;
        }
    
        .input-field1,
        .input-field2 {
            position: relative;
            border-radius: 8px;
            height: 49px;
        }
    
        .input-field1 {
            align-self: stretch;
        }
    
        .input-field2 {
            flex: 1 0 0;
            width: 100%;
        }
    
        .input-field-parent {
            align-self: stretch;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 24px;
        }
    
        .vector-icon4 {
            position: relative;
            width: 10px;
            height: 5.63px;
        }
    
        .button1 {
            border-radius: 8px;
            background-color: #00b587;
            flex-direction: row;
            padding: 12px 24px;
            align-items: center;
            text-align: right;
            font-size: 14px;
            color: #fff;
        }
    
        .button1,
        .frame-group,
        .contact-parent1 {
            display: flex;
            justify-content: flex-start;
        }
    
        .contact-parent1 {
            flex: 1;
            border-radius: 0 0 12px 12px;
            background: linear-gradient(180deg,
                    rgba(255, 255, 255, 0),
                    rgba(30, 113, 236, 0.08));
            box-shadow: 0 10px 12px rgba(107, 98, 220, 0.08);
            border-right: 1px dashed rgba(30, 113, 236, 0.5);
            border-bottom: 1px dashed rgba(30, 113, 236, 0.5);
            border-left: 1px dashed rgba(30, 113, 236, 0.5);
            flex-direction: column;
            padding: 20px;
            align-items: center;
            gap: 20px;
            font-size: 13px;
            color: #000;
        }
    
        .frame-group {
            align-self: stretch;
            flex-direction: row;
            align-items: flex-start;
            gap: 24px;
        }
        
}

@media screen and (max-width: 1200px) {
    .input-field-parent {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}