@import "./../../../CommonSCSS/mixins.scss";

.Footer {
  padding-top: 60px;
  padding-bottom: 60px;
  background: rgba(#1E71EC, 0.04);
  margin-top: 80px;

  a {
    cursor: pointer;
  }
  .logo {
    height: 54px;
  }
  h5 {
    @include fontSize(16px);
    line-height: 24px;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;
    // letter-spacing: 1.6px;
    font-weight: 600;
  }

  .footer-nav-list {
    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .nav-link {
        @include fontSize(14px);
        text-transform: capitalize;
        font-weight: 400;
        color: $black;
        text-decoration: none;
        display: inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
        &:hover {
            color: $theme-green;
          }
      }
    }
  }

  .socialIcons {
    display: flex;
    gap: 8px;
    a {
      color: $theme-blue;
      display: inline-block;
      padding: 4px;
      &:hover {
       color: $theme-lightgreen;
      }
    }
  }

  .detail_p {
    @include fontSize(14px);
    // text-transform:capitalize;
    font-weight: 600;
    color: $black;

    span {
      display: block;
      margin-top: 2px;
      font-weight: 400;
      a {
        text-decoration: none;
        color: $black;
        &:hover {
          color: $theme-lightgreen;
        }
      }
    }
  }
  .new_p {
    @include fontSize(14px);
    // text-transform: capitalize;
    font-weight: 400;
    color: $black;

    span {
      font-weight: 600;
    }
  }
  .disclaimer_p {
    @include fontSize(10px);
    // text-transform: capitalize;
    font-weight: 400;
    color: $black;
    text-align: center;
    span {
      font-weight: 600;
    }
  }
  .desc_box {
    padding: 12px 0px;
    background: $white;
    p {
      @include fontSize(14px);
      text-transform: capitalize;
      font-weight: 400;
      color: $black;
      line-height: 28px;
      text-align: center;
    }
  }
}
