@import "./../../../CommonSCSS/mixins.scss";

.pagesWrapper {
    padding: 24px;
    max-width: 920px;
    margin: 160px auto 100px;
    background: linear-gradient(180deg, #fff0, #1e71ec14);
    border-bottom: 1px dashed #1e71ec80;
    border-left: 1px dashed #1e71ec80;
    border-radius: 0 0 12px 12px;
    border-right: 1px dashed #1e71ec80;
    box-shadow: 0 10px 12px #6b62dc14;
    @media(max-width:600px) {
        margin: 80px 12px;
    }

    h5 {
        @include fontSize(14px);
        font-weight: 400;
        background: $theme-blue;
        color: $white;
        @include border-radius(8px);
        padding: 4px 16px;
        display: inline-block;
        position: relative;
        margin-top: 24px;
        margin-bottom: 24px;
        &:before {
            position: absolute;
            bottom: -24px;
            left: 24px;
            content: '';
            background: $theme-blue;
            height: 24px;
            width: 1.5px;
        }
        &:after {
            position: absolute;
            bottom: -24px;
            left: 21px;
            content: '';
            border-left: 1.5px solid $theme-blue;
            border-bottom: 1.5px solid $theme-blue;
            height: 6px;
            width: 6px;
            transform: rotate(-45deg);
        }
    }
    ul {
        padding-left: 8px;
        li {
            margin: 8px;
            list-style-type: circle;
            a {
                display: inline-block;
                padding: 4px 0px;
                color: $theme-blue;
                text-decoration: none;
                @include fontSize(14px);
                cursor: pointer;
                &:hover {
                    color: $theme-lightgreen;
                }
            }
        }
    }
}