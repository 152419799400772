@import "../../../../CommonSCSS/mixins.scss";

.claimsWrapper {
  .headerContent {
    display: flex;
    justify-content: center;

    .top_heading {
      text-align: center;
      width: 60%;
    }
  }
  .frame-group{
    @media(max-width:767px){
      padding-top: 0;
    }
  }


  .bottomSection {
    padding: 0 24px;

    .claim-parent {
      border-radius: 0 0 12px 12px;
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0),
          rgba(30, 113, 236, 0.08));
      box-shadow: 0 10px 12px rgba(107, 98, 220, 0.08);
      border-right: 1px dashed rgba(30, 113, 236, 0.5);
      border-bottom: 1px dashed rgba(30, 113, 236, 0.5);
      border-left: 1px dashed rgba(30, 113, 236, 0.5);

      .gridContainer {

        .mainHeading {
          padding: 16px;
          font-size: 20px;
          font-weight: 600;
          @media(max-width: 600px){
            padding: 8px 16px;
            font-size: 16px;
          }
        }

        .gridHeading {
          font-size: 20px;
          font-weight: 600;
          padding: 16px;
          @media(max-width: 600px) {
              padding:  16px;
              font-size: 16px;
            }
        }

        .ownHead {
          padding: 16px 16px 0 16px !important;
        }

        .css-1ujnqem-MuiTabs-root {
          min-height: 36px;
        }

        .claimTab {
          padding: 0;

          .checkDeclaration label {
            margin: 0;
            position: relative;
            padding: 0 0 0 30px;

            .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
              position: absolute;
              top: -5px;
              left: -5px;
            }
          }


          .MuiBox-root {
            padding: 0 12px;
          }

          .fileUpload {
            padding: 0;
          }

          .fileUpload1,
          .accidentPhotos {
            padding: 0;
          }
        }

        .ownDamage .checkDeclaration label {
          margin: 0;
        }

        .MuiGrid2-root .tabPanel {
          padding: 0;

          .termIn {
            display: flex;
            gap: 24px;
            flex-direction: column;
            padding: 0
          }

          .claimPolicyDoc {
            display: flex;
            align-items: center;
            gap: 15px;
            @media(max-width: 600px) {
              flex-direction: column;
              align-items: flex-start;
            }

            h5 {
              font-weight: 400;
              font-size: 16px;
            }

            .yes {
              color: #fff;
              background-color: #203864;
              border: 1px solid #203864;
              border-radius: 8px;
              padding: 8px 16px;
              font-weight: 400;
              font-size: 14px;
              line-height: unset;
              text-transform: unset;
              max-height: 35px;
              box-shadow: none;
              @media(max-width:600px) {
                  font-size: 12px;
                  padding: 6px 12px;
                  min-width: auto;
                  line-height: 13px;
                }
            }

            .nope {
              color: #000;
              background-color: #fff;
              border: 1px solid #cfcdcd;
              box-shadow: unset;
              @extend .yes;
            }
          }

          .claimPolicyDoc1 {
            justify-content: space-between;
            @extend .claimPolicyDoc;

            span {
              cursor: pointer;
              @media(max-width:600px){
                border: 1px dashed ;
                padding: 4px 8px ;
                border-radius: 8px;
                font-size: 14px;
              }
            }
          }

          .file {
            padding: 0;
          }

          .file1 {
            padding: 0;

            .cloudButton {
              padding-left: 0;
            }
          }
          .fileUpload{
            position: relative;
            width: fit-content;
          }

          .fileUpload .cloudButton {
            display: flex;
            gap: 15px;
            padding: 0;
            
            
            @media(max-width:600px){
              text-align: left;
            }
            
          }
          .error {
            color: #ea4559;
            font-size: 0.6875rem;
            position: absolute;
            bottom: -16px;
            right: 0;
          }
        }

        .submit div {
          display: flex;
          justify-content: center;

          .submitButton {
            background-color: #00b587;
            padding: 12px 24px;
            margin: 24px;
            text-transform: unset;
          }
        }
      }

      .tabListButtons div div {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        

        .active {
          color: #fff;
          background-color: #203864;
          border: 1px solid #203864;
          border-radius: 8px;
          padding: 8px 16px;
          font-weight: 400;
          font-size: 14px;
          min-height: unset;
          text-transform: unset;
          max-height: 35px;
          @media(max-width:600px) {
            font-size: 12px;
            padding: 6px 12px;
          }
        }

        .inActive {
          color: #000;
          background-color: #fff;
          border: 1px solid #cfcdcd;
          @extend .active;
        }
      }

      .MuiTabs-indicator {
        display: none;
      }
    }

    hr {
      border-top: 1px solid #C7C9CB !important;
      border: none;
      // margin-top: 16px;
      // margin-bottom: 16px;
    }

    .support {
      display: flex;
      justify-content: center;
      padding-top: 24px;

      div {
        padding: 12px 24px;
        border-radius: 12px 12px 12px 12px;
        border: 1px dashed rgba(30, 113, 236, 0.5);

        h4 {
          font-size: 14px;
          font-weight: 500;
          color: #203864;

          span {
            // color: #000;
            color: #8f9bb1;
          }

          .green {
            color: #00b587;
          }
        }
      }
    }
  }

  .healthTab {
    .healthPolicyDoc {
      .uploadHeading {
        margin: 16px 0 0;
      }

      h5 {
        font-weight: 400;
        font-size: 16px;
      }

      .fileUpload1 {
        padding: 0;
      }

      button {
        padding: 0;
      }
    }

    .healthHeading {
      font-size: 20px;
      font-weight: 600;
      @media(max-width: 600px) {
          font-size: 16px;
        }
    }

    .yes {
      border-radius: 8px;
      color: #fff;
      background-color: #203864;
      border: 1px solid #203864;
      max-height: 35px;
      box-shadow: unset;
      text-transform: unset;
    }

    .nope {
      color: #000;
      background-color: #fff;
      border: 1px solid #cfcdcd;

      @extend .yes;
    }

    .bimaType {
      display: flex;
      gap: 16px;
    }
  }
}


@media screen and (max-width: 767px) {
  .claimsWrapper .bottomSection {
    padding: 0 12px;
  }

  .contact-us-parent {
    .contact-us {
      height: unset;

      .contact-us1 {
        font-size: 1.125rem;
        font-weight: 700;
      }
    }

    .headerContent .top_heading {
      font-size: 0.6875rem;
      line-height: 14px;
      color: #808080;
      width: 100%;
    }
  }
}

