@import "../../../../CommonSCSS/mixins.scss";

.aboutusWrapper_m {
    padding-top: 90px;

    .pageHeader {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        @include fontSize(18px);
        font-weight: 500;
        text-align: center;
        padding-bottom: 20px;

        h1 {
            @include fontSize(18px);
            font-weight: 600;
        }
    }

    .desc_p {
        @include fontSize(11px);
        line-height: 14px;
        text-align: justify;
        font-weight: normal;
    }

    h2 {
        @include fontSize(18px);
        font-weight: 500;
    }

    h4 {
        @include fontSize(18px);
        font-weight: 500;
    }

    h5 {
        @include fontSize(14px);
        font-weight: 500;
    }

    .sectionTabs {
        text-align: center;
        margin: 30px 12px;

        .MuiTabs-flexContainer {
            gap: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            button {
                padding: 10px 20px;
                @include fontSize(11px);
                @include border-radius(20px);
                font-weight: 400;
                line-height: 14px;
                text-transform: inherit;
                color: $black;
                min-height: 30px;
                outline: 1px dashed $theme-blue;
                outline-offset: -1px;

                &.Mui-selected {
                    background: $theme-blue;
                    color: $white;
                }
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }

    .MuiTabPanel-root {
        padding: 0;
    }

    .illustration-icon {
        max-width: 290px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
    }

    .instance-parent {
        .frame-wrapper {
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            .frame-parent1,
            .frame-parent7 {
                display: flex;
                gap: 18px;

                img {
                    width: 24px;
                    height: 24px;
                    max-width: 24px;
                }

                .committed-to-the,
                .committed-to-the3 {
                    @include fontSize(11px);
                    font-weight: 400;
                }

                .committed-to-the-objective-of-parent {
                    align-self: center;
                }

                .we-are-insurance-brokers-licen-parent {
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 10px;
                    margin-top: 10px;

                    .ellipse-group {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    .frame-inner {
                        position: relative;
                        border-radius: 50%;
                        background-color: #d9d9d9;
                        width: 11px;
                        height: 11px;
                    }

                    .key-management-personal {
                        position: relative;
                        @include fontSize(11px);
                    }

                    .key-management-personal-wrapper {
                        display: flex;
                        flex-direction: row;
                        padding: 10px 20px;
                        align-items: flex-start;
                        justify-content: flex-start;
                        cursor: pointer;
                    }

                    .managing-director-and {
                        position: relative;
                        cursor: pointer;
                    }

                    .ellipse-parent21 {
                        align-self: stretch;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    .frame-parent2,
                    .frame-parent3 {
                        align-items: flex-start;
                        justify-content: flex-start;
                    }

                    .frame-parent3 {
                        display: flex;
                        flex-direction: row;
                        gap: 57px;
                    }
                }
            }
        }
    }

    .expertise-section {
        background: #F6F9FE;
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;

        h4 {
            margin-bottom: 5px;
        }

        .react-multi-carousel-list {
            width: 100%;

            .react-multiple-carousel__arrow {
                background: #E1EDFF;
                min-height: 36px;
                height: 36px;
                min-width: 36px;
                width: 36px;
                bottom: 0px;

                &:before {
                    color: $black;
                    font-size: 14px;
                    font-weight: 600;
                }

                &:hover {
                    background: $theme-blue;

                    &:before {
                        color: $white;
                    }
                }

                &.react-multiple-carousel__arrow--left {
                    left: calc(50% - 96px);
                    z-index: 2;
                }

                &.react-multiple-carousel__arrow--right {
                    right: calc(50% - 96px);
                    z-index: 2;
                }
            }

            .react-multi-carousel-dot-list {
                bottom: 6px;
            }

            .react-multi-carousel-dot button {
                border: 1px solid $black;
                background: $white;
            }

            .react-multi-carousel-dot--active button {
                border: 1px solid $black;
                background: $black;
            }
        }

        .productCard {
            padding: 12px 12px 30px;
            text-align: center;

            .inner {
                background: $white;
                @include border-radius(12px);
                @include box-shadow(0px 6px 12px rgba($theme-blue, 0.1));
                padding: 24px 12px;
                margin-top: 18px;
                margin-bottom: 30px;

                img {
                    height: 160px;
                }

                h4 {
                    @include fontSize(14px);
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                p {
                    @include fontSize(11px);
                    line-height: 14px;
                }
            }
        }
    }

    .vision_mission_img {
        max-width: 375px;
        width: 100%;
        margin-top: 30px;
    }

    .ms_points_list {
        li {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;

            p {
                @include fontSize(11px);
            }

            &:last-child {
                margin-bottom: 0;
            }

            img {
                align-self: flex-start;
                position: relative;
                top: 3px;
            }
        }
    }

    .ethic_point_list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        li {
            text-align: center;
            padding: 14px;
            box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.20);
            @include border-radius(10px);
            width: calc((100% - 12px) / 2);

            span {
                color: $theme-lightgreen;
                @include fontSize(14px);
                font-weight: 500;
                display: block;
                margin-bottom: 6px;
            }

            p {
                @include fontSize(11px);
            }
        }
    }

    hr {
        border-top: 1px solid #E3EDFF;
        border-bottom: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .personnelbox {
        padding-bottom: 30px;

        &.bluebox {

            padding: 20px 12px !important;

            &:nth-child(odd) {
                background: rgba(30, 113, 236, 0.04);
            }
        }

        img {
            @include border-radius(20px);
            width: 199px;
            height: 190px;
            box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.20);
        }

        h4 {
            color: $theme-lightgreen;
            margin-bottom: 10px;
        }

        h5 {
            margin-bottom: 10px;
        }

        .points_list {
            li {
                display: flex;
                gap: 10px;
                margin-bottom: 10px;

                p {
                    @include fontSize(11px);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    align-self: flex-start;
                    position: relative;
                    top: 1px;
                    width: 15px;
                    height: 15px;
                }


            }

            .dot_list {
                margin-left: 25px;

                li {
                    @include fontSize(11px);
                    margin-bottom: 10px;
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        @include position(absolute, $top: calc(50% - 5px), $left: 0);
                        @include border-radius(50%);
                        background: #D9D9D9;
                        content: '';
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }

    .personnelbox2 {
        padding: 0px 12px 24px;
        text-align: center;
        position: relative;

        &:before {
            @include position(absolute, $bottom: 0, $left: 0);
            content: '';
            background: rgba(30, 113, 236, 0.04);
            width: 100%;
            height: 80%;
            border-radius: 10px;
        }

        img {
            @include border-radius(20px);
            width: 167px;
            height: 160px;
            box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.20);
        }

        h4 {
            color: $theme-lightgreen;
            margin-bottom: 10px;
        }

        h5 {
            margin-bottom: 10px;
        }

        p {
            @include fontSize(11px);

            span {
                color: #203864;
            }
        }
    }

    .awardsRec-icon {
        max-width: 367px;
        width: 100%;
    }

    .es_list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        li {
            text-align: center;
            padding: 14px;
            box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.20);
            @include border-radius(10px);
            width: calc((100% - 12px) / 2);

            img {
                margin-bottom: 12px;
                width: 40px;
                height: 40px;
            }

            p {
                @include fontSize(11px);
                word-wrap: break-word;
            }
        }
    }

    .cs_offerd_sec {
        background: rgba(30, 113, 236, 0.04);
        padding: 20px 12px;

        h4,
        h5 {
            text-align: center;
        }

        .csOffered_listpoints {
            margin-top: 20px;

            li {
                padding: 12px 0px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                gap: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.evenRow {
                    flex-direction: row-reverse;
                }

                img {
                    width: 140px;
                    height: 140px;
                    min-width: 140px;
                }

                h5 {
                    margin-bottom: 10px;
                    text-align: left;
                }

                p {
                    text-align: left;
                }

            }
        }
    }

    .tenure_achievements {
        gap: 14px;

        li {
            display: flex;
            gap: 12px;
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                height: 22px;
                width: 22px;
                min-width: 22px;
            }
        }

        .ms_points_list {
            margin-left: 32px;

            li {
                display: flex;
                gap: 10px;
                margin-bottom: 10px;

                p {
                    @include fontSize(11px);
                    text-align: left;
                }

                img {
                    align-self: flex-start;
                    position: relative;
                    top: 3px;
                    width: 15px;
                    min-width: 15px;
                    height: 15px;
                }
            }
        }
    }
}