@import "./../../../CommonSCSS/mixins.scss";

.homepageWrapper_m {
    // padding-left: 12px;
    // padding-right: 12px;

    .common_p {
        @include fontSize(14px);
        font-weight: 400;
        color: $black;
        line-height: 22px;

        span {
            font-weight: 500;
        }
    }

    .main_subHeading {
        @include fontSize(20px);
        font-weight: 600;
        color: $black;
        text-align: center;
        margin-bottom: 10px;

        span {
            position: relative;

            &:before {
                @include position(absolute, $bottom: -8px, $left: 0px);
                content: '';
                background: url('./../../../../public/images/heading_bg.svg') no-repeat 0 0 /100%;
                width: 100%;
                height: 7px;
            }
        }
    }

    .small_subHeading {
        @include fontSize(14px);
        font-weight: 500;
        color: $black;
        margin-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        text-align: center;

        span {
            font-weight: 700;
        }
    }

    .head-section {
        // padding-left: 12px;
        // padding-right: 12px;
        // min-height: 656px;
        padding-top: 90px;
        border-bottom: 1px solid #E9E9E9;
        position: relative;
        overflow: hidden;
        padding-bottom: 110px;

        h1 {
            @include fontSize(24px);
            font-weight: 600;
            color: $black;
            margin-bottom: 6px;

            span {
                color: $theme-lightgreen;
                padding-left: 10px;
                padding-right: 10px;
                position: relative;

                &:before {
                    @include position(absolute, $top: -3px, $left: -3px);
                    content: '';
                    background: url('./../../../../public/images/bestTag_bg.png')no-repeat 0 0 /78px;
                    width: 78px;
                    height: 40px;
                }
            }
        }

        .productWrapper {
            width: 100%;
            position: relative;
            // z-index: 9;

            .productList {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .product-type {
                    margin: 10px;
                    max-width: 160px;
                    width: 40%;
                    flex: 1 0 auto;
                    box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.12);
                    @include border-radius(15px);
                    text-align: center;
                    animation: slideDown 1.5s both;

                    &:nth-child(2) {
                        animation-delay: 0.2s;
                    }

                    &:nth-child(3) {
                        animation-delay: 0.5s;
                    }

                    &:last-child {
                        // margin-right: 0;
                        animation-delay: 0.7s;
                    }

                    .box {
                        text-decoration: none;
                        cursor: pointer;
                        padding: 20px 10px;
                        display: inline-block;
                        width: 100%;
                        position: relative;

                        &.disabled {
                            cursor: wait;

                            p,
                            img {
                                opacity: 0.4;
                            }

                            &:before {
                                background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
                                content: '';
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                z-index: 9;
                                display: block;
                            }
                        }


                        img {
                            width: 80px;
                        }

                        p {
                            margin-top: 8px;
                            @include fontSize(14px);
                            font-weight: 400;
                            color: $black;
                        }
                    }

                    &:hover {
                        outline: 1px solid $theme-lightgreen;
                        outline-offset: -1px;

                        p {
                            color: $theme-lightgreen;
                        }
                    }
                }
            }

            @keyframes slideDown {
                from {
                    opacity: 0;
                    -webkit-transform: translate3d(0, -20%, 0);
                    transform: translate3d(0, -20%, 0);
                }

                to {
                    opacity: 1;
                    -webkit-transform: none;
                    transform: none;
                }
            }
        }

        .bikeWrapper {
            position: absolute;
            bottom: -3px;
            width: 140%;
            left: -110px;
            // overflow: hidden;
            z-index: -1;
            animation: movebiker 15s linear infinite;

            .bikebox {
                width: 100px;
                position: relative;
            }

            .rider {
                height: 88px;
            }

            .hair {
                @include position(absolute, $left: -8px, $top: 6px);
                animation: waving 3s ease-in-out infinite;
                z-index: -1;
                transform-origin: right center;
                height: 16px;
            }

            @keyframes waving {
                0% {
                    -webkit-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                20% {
                    -webkit-transform: rotate(-1deg);
                    -ms-transform: rotate(-1deg);
                    transform: rotate(-1deg);
                }

                54% {
                    -webkit-transform: rotate(2deg);
                    -ms-transform: rotate(2deg);
                    transform: rotate(2deg);
                }

                82% {
                    -webkit-transform: rotate(-1deg);
                    -ms-transform: rotate(-1deg);
                    transform: rotate(-1deg);
                }
            }

            .bikerbox {
                height: 260px;
                z-index: -1;
                position: relative;
            }

            @keyframes movebiker {
                from {
                    transform: translateX(0);
                }

                to {
                    transform: translateX(100%);
                }
            }
        }

        .carWrapper {
            position: absolute;
            bottom: 0px;
            width: 150%;
            left: -150px;
            overflow: hidden;
            z-index: 2;
            animation: drivecar 10s linear infinite;

            .carbox {
                width: 140px;
                height: 53px;

                .carbody {
                    width: 100%;
                }
            }

            .bikerbox {
                height: 93px;
                z-index: -1;

                .biker {
                    height: 93px;
                    z-index: 3;
                }
            }

            .rearWheel {
                @include position(absolute, $left: 9px, $bottom: 0px);
                animation: rotating 1.2s linear infinite;
                z-index: 1;
                height: 24px;
                width: 24px;
            }

            .frontWheel {
                @include position(absolute, $left: 101px, $bottom: 0px);
                animation: rotating 1.2s linear infinite;
                z-index: 1;
                height: 24px;
                width: 24px;
            }

            @keyframes rotating {
                from {
                    -webkit-transform: rotate(-360deg);
                    transform: rotate(-360deg);
                }

                to {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                0% {
                    bottom: 0px;
                }

                60% {
                    bottom: 0px;
                }

                100% {
                    bottom: 0px;
                }
            }

            @keyframes drivecar {
                from {
                    transform: translateX(0);
                }

                to {
                    transform: translateX(100%);
                }
            }
        }

    }

    .how-it-works-sec {
        // padding-left: 4px;
        // padding-right: 4px;

        .easyWay-box {
            border: 2px solid #E1EDFF;
            padding: 30px 10px;
            @include border-radius(15px);
            text-align: center;
            margin-bottom: 20px;
            animation: slideDown 1.5s both;

            &:nth-child(2) {
                animation-delay: 1s;
            }

            &:nth-child(3) {
                animation-delay: 2s;
            }

            &:last-child {
                margin-bottom: 3s;
            }

            &:hover {
                box-shadow: 0px 12px 32px 0px rgba(32, 56, 100, 0.12);
                border: 2px solid $white;

                span {
                    background: #E1EDFF;
                }
            }

            span {
                width: 45px;
                height: 45px;
                border: 2px solid #E1EDFF;
                @include border-radius(50%);
                display: inline-block;
                margin-bottom: 20px;
                padding-top: 2px;
                font-size: 26px;
                font-weight: 500;

            }

            h3 {
                @include fontSize(18px);
                font-weight: 500;
                margin-bottom: 10px;
            }
        }

        @keyframes slideDown {
            from {
                opacity: 0;
                -webkit-transform: translate3d(0, -10%, 0);
                transform: translate3d(0, -10%, 0);
            }

            to {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }
    }

    .why-choose-section {
        padding: 40px 0px;
        background: rgba(243, 243, 255, 0.40);

        .carousel-part {
            width: 100%;

            .choose-sec {
                padding: 20px 6px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 6px 20px 0px rgba(32, 56, 100, 0.12);
                margin: 30px 12px 60px 12px;
                text-align: center;

                img {
                    width: 150px;
                    margin-bottom: 16px
                }

                h3 {
                    @include fontSize(16px);
                    color: $black;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                p {
                    @include fontSize(14px);
                    color: lighten($black, 40%);
                    font-weight: 400;
                    line-height: 22px;
                }

                $othericons: icon1, icon2, icon3, icon4, icon5;
                $k: 0;

                @each $icons in $othericons {
                    $bacRightP: $k * 150;
                    $k: $k +1;

                    .#{$icons} {
                        position: relative;
                        background: url("../../../../public/images/choose-sec-icons.svg") no-repeat -#{$bacRightP}px 0px/750px;
                        height: 150px;
                        width: 134px;
                        margin: auto;
                        margin-bottom: 20px;
                    }

                }


            }



            .react-multiple-carousel__arrow {
                min-width: 30px;
                min-height: 30px;
                z-index: 1;
            }

            .react-multiple-carousel__arrow--left {
                left: calc(50% - 80px);
                bottom: 0px;
                background: #E1EDFF;

                &:before {
                    color: $black;
                    font-size: 12px;
                }
            }

            .react-multiple-carousel__arrow--right {
                right: calc(50% - 80px);
                bottom: 0px;
                background: #E1EDFF;

                &:before {
                    color: $black;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .react-multi-carousel-dot-list {
                bottom: 4px;

                .react-multi-carousel-dot {
                    button {
                        border: 1px solid $black;
                        background: $white;
                        height: 10px;
                        width: 10px;
                        margin: 0px 4px;
                    }

                    &.react-multi-carousel-dot--active {
                        button {
                            background: $black;
                        }
                    }
                }
            }

        }
    }

    .clients-section {
        // padding-left: 12px;
        // padding-right: 12px;

        .clientSliding-part {
            width: 100%;

            .logos {
                overflow: hidden;
                white-space: nowrap;

                .logos-outer {
                    display: inline-block;

                    .logos-slide {
                        // white-space: nowrap;
                        display: flex;
                        animation: 30s slide infinite linear;

                        // display: inline-block;
                        .logobox {
                            width: 120px;
                            height: 80px;
                            padding: 5px 10px;
                            border: 1px solid rgba(163, 183, 219, 0.30);
                            margin: 5px;
                            @include border-radius(10px);
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 100%;
                                max-height: 70px;
                                object-fit: contain;
                            }
                        }
                    }
                }

            }

            @keyframes slide {
                from {
                    transform: translateX(0);
                }

                to {
                    transform: translateX(-100%);
                }
            }
        }
    }

    .recognition-section {
        padding: 40px 0px;
        background: rgba(243, 243, 255, 0.40);

        .carousel-part {
            width: 100%;

            .awards-sec {
                padding: 30px 6px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 6px 20px 0px rgba(32, 56, 100, 0.12);
                margin: 20px 12px 60px 12px;
                text-align: center;

                img {
                    width: auto;
                    max-height: 218px;
                }
            }

            .react-multiple-carousel__arrow {
                min-width: 30px;
                min-height: 30px;
                z-index: 1;
            }

            .react-multiple-carousel__arrow--left {
                left: 12px;
                bottom: 0px;
                background: #E1EDFF;

                &:before {
                    color: $black;
                    font-size: 12px;
                    left: -1px;
                }
            }

            .react-multiple-carousel__arrow--right {
                right: 12px;
                bottom: 0px;
                background: #E1EDFF;

                &:before {
                    color: $black;
                    font-size: 12px;
                    right: -1px;
                }
            }

            .react-multi-carousel-dot-list {
                bottom: 4px;

                .react-multi-carousel-dot {
                    button {
                        border: 1px solid $black;
                        background: $white;
                        height: 10px;
                        width: 10px;
                        margin: 0px 4px;
                    }

                    &.react-multi-carousel-dot--active {
                        button {
                            background: $black;
                        }
                    }
                }
            }

        }
    }

    .ourProducts {
        // padding-left: 12px;
        // padding-right: 12px;

        .light_p {
            @include fontSize(14px);
            font-weight: 400;
            color: $grey;
            text-align: center;
        }

        .productWrapper {
            padding-top: 8px;
            width: 100%;

            .productList {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .productPage-type {
                    margin: 10px;
                    max-width: 160px;
                    box-shadow: -12px 12px 40px 0px rgba(32, 56, 100, 0.12);
                    @include border-radius(15px);
                    text-align: center;
                    flex: 1 0 auto;
                    width: 40%;


                    .box {
                        text-decoration: none;
                        cursor: pointer;
                        padding: 20px 10px;
                        display: inline-block;
                        width: 100%;
                        position: relative;

                        &.disabled {
                            cursor: wait;

                            p,
                            img {
                                opacity: 0.4;
                            }

                            &:before {
                                background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
                                content: '';
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                z-index: 9;
                                display: block;
                            }
                        }

                        img {
                            width: 80px;
                        }

                        p {
                            margin-top: 8px;
                            @include fontSize(14px);
                            font-weight: 400;
                            color: $black;
                        }
                    }

                    &:hover {
                        outline: 1px solid $theme-lightgreen;
                        outline-offset: -1px;

                        p {
                            color: $theme-lightgreen;
                        }
                    }
                }
            }
        }
    }

    .testimonials-sec {
        padding-top: 40px;
        background: rgba(243, 243, 255, 0.40);

        .testimonial-sec {
            padding: 16px 30px 30px;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
            }

            .author {
                margin-top: 30px;
                margin-bottom: 10px;

                h5 {
                    @include fontSize(16px);
                    color: $black;
                    font-weight: 600;
                    margin-bottom: 5px;

                }

                p {
                    @include fontSize(12px);
                    color: lighten($black, 40%);
                    font-weight: 500;
                }
            }

            .testimonial-desc {
                @include fontSize(14px);
                color: $black;
                font-weight: 400;
                max-width: 800px;
            }

        }

        .react-multiple-carousel__arrow {
            min-width: 30px;
            min-height: 30px;
            z-index: 1;
        }

        .react-multiple-carousel__arrow--left {
            left: 0px;
            // bottom: 0px;
            background: #E1EDFF;

            &:before {
                color: $black;
                font-size: 12px;
                top: 2px;
                left: -1px;
            }
        }

        .react-multiple-carousel__arrow--right {
            right: 0px;
            background: #E1EDFF;

            &:before {
                color: $black;
                font-size: 12px;
                top: 2px;
                right: -1px;
            }
        }
    }

    .ourPartners-section {
        // padding-left: 12px;
        // padding-right: 12px;

        .partnersSliding-part {
            width: 100%;

            .logos {
                overflow: hidden;
                white-space: nowrap;

                .logos-outer {
                    display: inline-block;

                    .logos-slide {
                        // white-space: nowrap;
                        display: flex;
                        animation: 30s slide infinite linear;

                        // display: inline-block;
                        .logobox {
                            width: 120px;
                            padding: 10px 0px;
                            // border: 1px solid rgba(163, 183, 219, 0.30);
                            margin: 5px;
                            @include border-radius(10px);
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                // width: 80px;
                                height: 50px;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

            }

            @keyframes slide {
                from {
                    transform: translateX(0);
                }

                to {
                    transform: translateX(-100%);
                }
            }
        }
    }

    %btn {
        background: $theme-lightgreen;
        color: $white;
        @include border-radius(8px);
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        text-transform: capitalize;
        box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0.15);

        &:hover {
            background: lighten($theme-lightgreen, 5%);
            @include box-shadow(0px 0px 0px rgba($black, 0));
        }
    }

    .greenLgBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 10px 24px;
    }

    .greenMdBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 8px 20px;
    }

    .greenSmBtn {
        @extend %btn;
        @include fontSize(14px);
        padding: 4px 14px;
    }

    .greenXsBtn {
        @extend %btn;
        @include fontSize(12px);
        padding: 2px 14px;
    }
}