@import "./../../../CommonSCSS/mixins.scss";

.footer_m {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 24px;
    background: rgba(243, 243, 255, 0.40);
    // padding-left: 12px;
    // padding-right: 12px;
    .logo{
        width: 150px;
    }
    h5 {
        @include fontSize(14px);
        line-height: 24px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 10px;
        // letter-spacing: 1.4px;
        font-weight: 600;
    }
    
        .socialIcons {
            display: flex;
            gap: 8px;
    
            a {
                color: $theme-blue;
                display: inline-block;
                padding: 4px 0px;
    
                &:hover {
                    color: $theme-lightgreen;
                }
            }
        }

    .footer-nav-list {
        li {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            .nav-link {
                @include fontSize(12px);
                text-transform: capitalize;
                font-weight: 400;
                color: $black;
                text-decoration: none;
            }
        }
    }

    .detail_p {
        @include fontSize(14px);
        text-transform:capitalize;
        font-weight: 600;
        color: $black;
        margin-bottom: 14px;
        
        span{
            display: block;
            margin-top: 2px;
            font-weight: 400;
            @include fontSize(12px);
            a {
                text-decoration: none;
                color: $black;
                word-wrap: break-word;
                text-transform: initial;
            }
        }
    }
    .new_p{
        @include fontSize(12px);
        text-transform:initial;
        font-weight: 400;
        color: $black;
        
        span{
            font-weight: 600;
        }
    }
    .disclaimer_p{
        @include fontSize(9px);
        text-transform:initial;
        font-weight: 400;
        color: $black;
        text-align: justify;
        line-height: 12px;
        span{
            font-weight: 600;
        }
    }
    .desc_box{
        padding: 12px 0px;
        background: $white;
        p{
            @include fontSize(12px);
            text-transform:capitalize;
            font-weight: 400;
            color: $black;
            text-align: center;
        }
    }
}