@import "./mixins.scss";
@import "./../Component/Common/FieldTypes/FieldTypes.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.productFormMobile {
  .backnavigate {
    @include position(fixed, $top: 0px, $left: 0px);
    z-index: 91;
    // border-bottom: 1px solid rgba($black, 0.1);
    background: $white;
    padding: 4px 0px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;

    .backStep {
      margin-right: 8px;
      color: #000000;
      cursor: pointer;
      @include fontSize(14px);
      line-height: 24px;
      font-weight: 500;
      text-decoration: none;
      display: inline-block;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 12px;

      &:hover {
        svg {
          left: -4px;
          color: $theme-green;
        }
      }

      svg {
        position: relative;
        transition: all 0.5s ease;
        left: 0px;
        top: 5px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .formWrapper {
    width: 100%;
    margin: 0 auto;
    padding: 70px 8px 24px;
    min-height: 100vh;
    // overflow: hidden;
    z-index: 9;

    &.carbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 12px);
        background: url("./../../public/images/mcarForm_Background.svg")
          no-repeat bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(100%);
      }
    }

    &.twbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 25px);
        background: url("./../../public/images/mbikeformbg.svg") no-repeat bottom
          center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(100%);
      }
    }

    &.healthbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/mhealthformbg.svg") no-repeat
          bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(1);
      }
    }

    &.termbg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/mtermformbg.svg") no-repeat
          bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(1);
      }
    }
    &.homeinsurancebg {
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $bottom: 0px);
        background: url("./../../public/images/mhiformbg.svg") no-repeat
          bottom center/100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(1);
      }
    }

    %btn {
      background: $theme-lightgreen;
      color: $white;
      @include border-radius(12px);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      text-transform: capitalize;
      box-shadow: none;

      &:hover {
        background: lighten($theme-lightgreen, 5%);
        @include box-shadow(0px 4px 12px rgba($theme-green, 0.3));
      }
    }

    .greenXlBtn {
      @extend %btn;
      @include fontSize(14px);
      padding: 12px 30px;
      line-height: 21px;
    }

    .greenLgBtn {
      @extend %btn;
      @include fontSize(14px);
      padding: 10px 20px;
      line-height: 21px;
    }

    .greenMdBtn {
      @extend %btn;
      @include fontSize(14px);
      padding: 6px 16px;
    }

    .greenSmBtn {
      @extend %btn;
      @include fontSize(14px);
      padding: 3px 14px;
    }

    .greenXsBtn {
      @extend %btn;
      @include fontSize(12px);
      padding: 2px 14px;
    }

    .toinsure {
      font-family: "Poppins", sans-serif;
      @include fontSize(14px);
      font-weight: 500;
      line-height: 14px;
      margin: 0px;

      span {
        @include fontSize(16px);
        color: $theme-lightgreen;
      }
    }

    .pageHeading {
      font-family: "Poppins", sans-serif;
      @include fontSize(22px);
      font-weight: 300;
      line-height: 33px;
      margin: 0px 0px 10px;

      span {
        font-weight: 600;
      }

      .best {
        color: $theme-lightgreen;
        padding-left: 12px;
        padding-right: 14px;
        position: relative;

        &:before {
          @include position(absolute, $top: -2px, $left: 1px);
          content: "";
          background: url("./../../public/images/bestTag_bg.png") no-repeat 0 0 /100%;
          width: 70px;
          height: 36px;
        }
      }
    }

    .heading_desc {
      font-family: "Poppins", sans-serif;
      @include fontSize(14px);
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 8px;
      color: rgba($black, 0.5);
    }

    .newvehicle {
      font-family: "Poppins", sans-serif;
      @include fontSize(14px);
      font-weight: 500;
      line-height: 21px;
      color: $black;
      display: inline-block;

      span {
        color: $theme-lightgreen;
        cursor: pointer;
        padding-left: 6px;
      }
    }
    .insuredMembers {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        width: 112px;
        max-height: 71px;
        padding: 44px 8px 13px;
        border-radius: 6px;
        background: $white;
        border: 1px solid rgba($black, 0.1);
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 500;
        margin: 6px;
        line-height: 15px;
        cursor: pointer;
        color: $black;
        text-align: center;
        position: relative;

        &.active {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        // health adults list
        $othericons: oneadult, twoadults;
        $k: 0;

        @each $icons in $othericons {
          $bacRightP: $k * 33;
          $k: $k + 1;

          .#{$icons} {
            position: absolute;
            background: url("./../../public/images/health_adults_icons.svg") no-repeat -#{$bacRightP}px 0px/66px;
            height: 33px;
            width: 33px;
            top: 6px;
            left: calc(50% - 16px);

            &.active {
              background: url("./../../public/images/health_adults_icons_blue.svg") no-repeat -#{$bacRightP}px 0px/66px;
            }
          }
        }
      }
    }

    .manufactour_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        width: 90px;
        max-height: 81px;
        padding: 52px 8px 13px;
        border-radius: 6px;
        background: $white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(12px);
        font-weight: 400;
        margin: 6px;
        line-height: 15px;
        cursor: pointer;
        color: $black;
        border: 0;
        text-align: center;
        position: relative;

        &.active {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        // &:hover {
        //     background: rgba(#203864, 0.2);
        // }
      }

      $othericons: hyundai, maruti, tata, volkswagen, mahindra, kia, honda,
        skoda, toyota, mercedes, audi, bmw;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 60;
        $k: $k + 1;

        .#{$icons} {
          position: absolute;
          background: url("./../../public/images/manufacture_imgs.svg")
            no-repeat -#{$bacRightP}px
            0px/720px;
          height: 50px;
          width: 60px;
          top: 10px;
          left: calc(50% - 30px);
        }
      }

      &.twManufactour {
      $othericons: bajaj,
      tvs,
      hero,
      honda,
      royalenfield,
      ktm;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 50;
        $k: $k + 1;

        .#{$icons} {
          position: absolute;
          background: url("./../../public/images/bikemanufacture_imgs.svg")
            no-repeat -#{$bacRightP}px
            0px/300px;
          height: 50px;
          width: 50px;
          top: 10px;
          left: calc(50% - 25px);
        }
      }
      }

      
    }

    .varient_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        padding: 8px 16px;
        border-radius: 8px;
        background: $white;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 6px;
        line-height: 14px;
        cursor: pointer;
        color: $black;
        border: 1px solid rgba($theme-blue, 0.2);
        text-align: center;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &.active {
          background: $theme-blue;
          color: $white;
          box-shadow: 0px 4px 12px 0px rgba($theme-blue, 0.4);
        }

        // &:hover {
        //     background: rgba(#203864, 0.2);
        // }
      }
    }

    .fuel_list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 24px 0;

      li {
        width: 90px;
        max-height: 91px;
        padding: 65px 15px 12px;
        border-radius: 6px;
        background: $white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        margin: 6px;
        line-height: 15px;
        cursor: pointer;
        color: $black;
        border: 0;
        text-align: center;
        position: relative;

        &.active {
          background: #203864;
          color: $white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        }

        &:hover {
          background: rgba(#203864, 0.2);
        }
      }

      $othericons: petrol, diesel, lpg, cng, electric;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 50;
        $k: $k + 1;

        .#{$icons} {
          position: absolute;
          background: url("./../../public/images/fuel_imgs.svg")
            no-repeat -#{$bacRightP}px
            0px/250px;
          height: 50px;
          width: 50px;
          top: 10px;
          left: calc(50% - 25px);
        }
      }
    }

    .claimed {
      font-family: "Poppins", sans-serif;
      @include fontSize(14px);
      font-weight: 500;
      line-height: 21px;
      color: $black;
      margin: 0;

      span {
        display: block;
        @include fontSize(11px);
        font-weight: 400;
        line-height: 14px;
        color: rgba($black, 0.5);
      }
    }

    .details_list {
      padding: 12px;
      @include border-radius(0 0 12px 12px);
      border-right: 1px dashed var(--primary-blue, #203864);
      border-bottom: 1px dashed var(--primary-blue, #203864);
      border-left: 1px dashed var(--primary-blue, #203864);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      box-shadow: 0px 10px 12px 0px rgba(30, 113, 236, 0.1);
      text-align: center;
      margin-bottom: 12px;

      h6 {
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 400;
        line-height: 15px;
        margin-bottom: 0;
        color: rgba($black, 0.5);
      }

      p {
        font-family: "Poppins", sans-serif;
        @include fontSize(14px);
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 0;
      }
    }

    .changeVehicle {
      background: rgba($theme-yellow, 0.4);
      border: 1px solid transparent;
      @include border-radius(20px);
      color: darken($theme-yellow, 40%);
      cursor: pointer;
      display: table;
      @include fontSize(14px);
      text-decoration: none;
      transition: all 0.3s;
      padding: 4px 12px;
      margin: 20px auto 0px;

      &:hover {
        border: 1px dashed darken($theme-yellow, 40%);
      }
    }

    .rangeSlider {
      color: $theme-lightgreen;
      height: 6px;

      .MuiSlider-rail {
        opacity: 0.2;
      }

      .Mui-focusVisible {
        @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
      }

      .MuiSlider-thumb {
        height: 24px;
        width: 24px;

        &:hover {
          @include box-shadow(0px 0px 0px 8px rgba($theme-lightgreen, 0.2));
        }
      }
    }

    .disabledCheckbox {
      .MuiTypography-root {
        margin-left: 0px;

        a {
          color: $theme-lightgreen;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .Mui-disabled {
        color: $black;
      }
    }
  }
}
