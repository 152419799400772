@import "../../../CommonSCSS/mixins.scss";

.inputField {
  position: relative;
  width: 100%;

  label,
  input {
    font-weight: 400;
    letter-spacing: 0px;
    @include fontSize(13px);
    font-family: "Poppins", sans-serif;
  }

  label {
    transform: translate(14px, 10px) scale(1);
    top: 3px;
  }

  svg {
    display: none;
  }

  .MuiInputLabel-shrink {
    background: #fff;
    padding: 0 4px;
  }

  &.vehicleNumberField {
    fieldset {
      height: 44px;
      top: 0px;
    }
    &:before {
      @include position(absolute, $top: 0px, $left: 0);
      content: "";
      background: url("./../../../../public/images/ind.svg") no-repeat 0 0 /30px;
      width: 40px;
      height: 44px;
      @include border-radius(5px 0px 0px 5px);
      z-index: 4;
    }

    input {
      padding: 10px 12px 11px 42px;
      @include fontSize(20px);
      font-weight: 700;
      height: 44px;
      text-transform: uppercase;
    }
  }
  &.datepicker {
    &:before {
      @include position(absolute, $top: calc(50% - 6px), $right: 12px);
      content: "";
      background: url("./../../../../public/images/calender_icon.svg") no-repeat
        0 0 /13px;
      width: 13px;
      height: 13px;
      z-index: 4;
    }
    fieldset {
      border-color: rgba(0, 0, 0, 0.2) !important;
    }
    label {
      color: rgba(0, 0, 0, 0.6);
    }
    .MuiFormControl-root {
      width: 100%;
    }
  }

  &.nativeSelect {
    position: relative;

    &:before {
      @include position(absolute, $right: 10px, $top: 17px);
      content: "";
      border: solid $black;
      border-width: 0px 0px 1.4px 1.4px;
      transform: rotate(-45deg);
      transition: all 0.3s ease;
      transform-origin: center;
      height: 6px;
      width: 6px;
      z-index: 1;
    }
    .MuiInputLabel-shrink {
      background: white;
      @include border-radius(8px);
      padding-left: 4px;
      padding-right: 12px;
      transform: translate(8px, -9px) scale(0.75);
    }

    .MuiInputBase-root {
      margin-top: 0;
      height: 44px;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }

      .MuiNativeSelect-select {
        padding: 11.7px 24px 11.6px 10px;
        @include fontSize(13px);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        // height: 44px;
        font-family: "poppins", "sans-serif";

        &:focus {
          border-color: #6b62dc !important;
        }

        :nth-child(1) {
          display: none;
        }
      }
    }
  }

  .infoMessage {
    background: $white;
    @include border-radius(20px);
    @include position(absolute, $left: -7px, $top: 9px);
    height: 16px;
    width: 16px;
    z-index: 9;

    svg {
      color: #000;
      opacity: 0.6;
      display: inherit;
      @include fontSize(14px);
      margin-top: 1px;
      margin-left: 1px;
    }
  }

  input {
    // height: 20px;
    @include border-radius(8px);
    background: $white;
    padding: 12px 12px;
  }

  .MuiInputBase-root {
    // @include box-shadow(0px 3px 6px rgba($black, 0.1));
    background: $white;
    @include border-radius(8px);
    height: 44px;
  }
  .MuiInputBase-multiline {
    height: auto;
    font-family: "poppins", "sans-serif";
  }

  .MuiAutocomplete-root {
    &:before {
      @include position(absolute, $right: 14px, $top: 17px);
      content: "";
      border: solid $black;
      border-width: 0px 0px 1.4px 1.4px;
      transform: rotate(-45deg);
      transition: all 0.3s ease;
      transform-origin: center;
      height: 6px;
      width: 6px;
      z-index: 1;
    }

    .MuiAutocomplete-inputRoot {
      @include border-radius(8px);
      padding: 0px 0px 0px 8px;
      height: 44px;
    }

    .MuiAutocomplete-clearIndicator {
      background: #fff;
      height: 18px;
      width: 18px;
      border: 1px solid #ccc;

      svg {
        display: block;
        height: 12px;
        color: theme-yellow;
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiAutocomplete-hasClearIcon {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        height: 44px;
        @include border-radius(8px);
        padding: 8px 0px 7px 8px !important;

        .MuiButtonBase-root {
          height: 20px;

          &.MuiAutocomplete-clearIndicator {
            display: none;
          }

          .MuiChip-label {
            @include fontSize(10px);
          }

          &#ArrowDropDownIcon {
            display: none !important;
          }

          svg {
            display: block;
          }

          .MuiTouchRipple-root {
            display: none;
          }

          .MuiChip-deleteIcon {
            color: rgba(0, 0, 0, 0.26);
            @include fontSize(18px);
            cursor: pointer;
            margin: 0px 2px 0 -6px;
          }
        }

        .MuiAutocomplete-input {
          min-width: 0px !important;
          padding: 4.5px 4px 7.5px 5px;
        }
      }
    }
  }

  .MuiAutocomplete-popper {
    // @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0.2) !important);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    @include border-radius(0px 0px 8px 8px);
    // z-index: 999;

    .MuiAutocomplete-paper {
      @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0));
    }

    ul {
      // @include box-shadow(0px 4px 12px rgba(0,0,0,0.2));
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      max-height: 244px;
      overflow-y: auto;
      // z-index: 999;
    }
    .MuiAutocomplete-option {
      @include fontSize(13px);
      line-height: 18px;
      min-height: 32px;
    }
  }

  .MuiInputLabel-shrink {
    color: $theme-green !important;
    transform: translate(14px, -9px) scale(0.75);
  }

  .MuiSelect-select {
    text-align: left;
    padding: 14px 12px;
    @include fontSize(13px);
    @include border-radius(8px);
    font-family: "Poppins", sans-serif;
    padding-right: 16px !important;

    &:before {
      @include position(absolute, $right: 10px, $top: 20px);
      content: "";
      border: solid $black;
      border-width: 0px 0px 1.4px 1.4px;
      transform: rotate(-45deg);
      // transition: all 0.3s ease;
      transform-origin: left center;
      height: 6px;
      width: 6px;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #6b62dc !important;
    }

    // .MuiSelect-select {
    //   &:before {
    //     // border: solid $theme-green;
    //     border-width: 0px 0px 1.4px 1.4px;
    //     // transform: rotate(-223deg);
    //     right: 10px;
    //     // transform-origin: top center;
    //     top: 19px;
    //   }
    // }
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.2);
    @include border-radius(8px);
    border-width: 1px !important;
    font-weight: 500;
    legend {
      span {
        display: none;
      }
    }
  }

  .error {
    color: $secondary;
    @include fontSize(11px);
    text-align: right;
    @include position(absolute, $right: 0px, $bottom: -16px);
  }
}

.MuiMenu-paper {
  max-height: 200px !important;

  .MuiMenu-list {
    .MuiMenuItem-root {
      @include fontSize(14px);
    }
  }
}
.evInputField {
  label {
    font-size: 13px !important;
  }
  label,
  input {
    font-family: "poppins", sans-serif;
  }
  .MuiInputLabel-shrink {
    color: $theme-green !important;
  }
}
