@import "../../../../CommonSCSS/mixins.scss";

.contact_wrapper {
    margin-top: 100px;

    .main_heading {
        h1,h5 {
            font-family: "Poppins", sans-serif;
            @include fontSize(18px);
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            margin-bottom: 0;

            span {
                display: block;
                @include fontSize(11px);
                font-weight: 400;
                line-height: 14px;
                color: #808080;
                padding-top: 8px;
            }
        }
    }

    .support_mail_parent {
        background: linear-gradient(90deg,
                rgba(0, 181, 135, 0.3),
                rgba(255, 255, 255, 0));
        padding: 8px 12px;
        @include border-radius(12px);
    }

    .other_query_parent {
        background: linear-gradient(90deg,
                rgba(32, 56, 100, 0.3),
                rgba(255, 255, 255, 0));
        padding: 12px;
        @include border-radius(12px);
    }

    .phone_number_parent {
        background: linear-gradient(90deg,
                rgba(217, 19, 46, 0.3),
                rgba(255, 255, 255, 0));
        padding: 12px;
        @include border-radius(12px);
    }

    .support-mail {
        font-family: "Poppins", sans-serif;
        @include fontSize(12px);
        font-weight: 500;
        line-height: 18px;
    }

    .carebimastreetcom {
        font-family: "Poppins", sans-serif;
        @include fontSize(12px);
        font-weight: 400;
        line-height: 18px;
    }

    .form_details {
        border-radius: 0px 0px 12px 12px;
        @include border-radius(0 0 12px 12px);
        border-right: 1px dashed rgba(30, 113, 236, 0.50);
        border-bottom: 1px dashed rgba(30, 113, 236, 0.50);
        border-left: 1px dashed rgba(30, 113, 236, 0.50);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(30, 113, 236, 0.08) 100%);
        box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
        padding: 0 16px 20px;
        margin: 10px 0 24px;

        h6 {
            font-family: "Poppins", sans-serif;
            @include fontSize(18px);
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            margin-bottom: 20px;

            span {
                display: block;
                @include fontSize(11px);
                font-weight: 400;
                line-height: 14px;
                color: #808080;
                padding-top: 8px;
            }
        }
    }

    .amaze_address {
        background-color: rgba(30, 113, 236, 0.04);
        padding: 12px;
        @include border-radius(12px);
        margin-bottom: 20px;

        h6 {
            font-family: "Poppins", sans-serif;
            @include fontSize(16px);
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 8px;
        }

        p {
            font-family: "Poppins", sans-serif;
            @include fontSize(11px);
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 0px;
        }
    }


}