@import "./../../../CommonSCSS/mixins.scss";

.pirWrapper {
    padding-top: 100px;

    @media (max-width: 899px) {
        padding-top: 75px;
        min-height: auto !important;
    }

    h2 {
        @include fontSize(45px);
        font-weight: 600;
        line-height: 68px;

        @media (max-width: 991px) {
            @include fontSize(32px);
            line-height: 50px;
        }
    }

    h4 {
        @include fontSize(28px);
        font-weight: 500;
    }

    h5 {
        @include fontSize(26px);
        font-weight: 600;
    }

    .mHide {
        @media (max-width: 899px) {
            display: none;
        }
    }

    .memberBox {
        background-color: #ffffff;
        border-radius: 16px;
        box-shadow: 0px 12px 40px rgba(32, 56, 100, 0.12);
        border: 1px solid transparent;
        color: #828282;
        padding: 8px;
        text-align: center;
        font-size: 12px;
        width: 136px;
        height: 136px;
        cursor: pointer;
        position: relative;

        .checkbox {
            height: 16px;
            width: 16px;
            border-radius: 3px;
            border: 1px solid #777;
            margin-left: auto;
            position: relative;
        }

        img {
            width: 86px;
            margin: 0px 12px 12px;
        }

        &.active {
            border-color: $theme-lightgreen;
            color: $black;

            .checkbox {
                background: $theme-lightgreen;
                border-color: $theme-lightgreen;

                &:before {
                    content: "";
                    height: 3px;
                    width: 6px;
                    border-left: 1px solid $white;
                    border-bottom: 1px solid $white;
                    transform: rotate(-45deg);
                    position: absolute;
                    left: 4px;
                    top: 4px;
                }
            }
        }
    }

    .members {
        @media (max-width: 899px) {
            justify-content: center;
        }
    }

    .bottomSpaceNone {
        @media (max-width: 899px) {
            padding-bottom: 0px;
        }
    }

    .greenToggle {
        .switch {
            background-color: $white;
            border: none;
            box-shadow: 0px 12px 32px rgba(32, 56, 100, 0.12);

            button {
                border: none;
                padding: 6px 20px;
            }

            button.Mui-selected {
                background-color: $theme-lightgreen;
            }
        }
    }

    .gradientDashBox {
        border-left: 1px dashed $theme-blue;
        border-right: 1px dashed $theme-blue;
        border-bottom: 1px dashed $theme-blue;
        border-radius: 0px 0px 12px 12px;
        padding: 0px 12px 12px;
        box-shadow: 0px 10px 12px rgba(#1e71ec, 0.1);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        width: 100%;
        margin: 16px 16px 8px;

        .MuiTypography-root {
            margin-left: 0px;
        }
    }

    // .inrIcon {
    //     .inputField input {
    //         padding-left: 24px;
    //     }
    // }

    .recommendation {
        background: $white;
        border-radius: 12px;
        box-shadow: 0px 12px 32px rgba(32, 56, 100, 0.12);
        border-left: 10px solid $theme-lightgreen;
        text-align: center;
        margin: 16px 0px;
        padding: 12px;
    }

    .indications {
        display: flex;
        justify-content: center;
        gap: 24px;
        margin-bottom: 24px;

        li {
            padding-left: 26px;
            position: relative;
            font-size: 14px;

            &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 1px;
                border-radius: 2px;
                height: 20px;
                width: 20px;
            }

            &.high:before {
                background-color: red;
            }

            &.medium:before {
                background-color: #fec75f;
            }

            &.low:before {
                background-color: #429c6b;
            }
        }
    }

    .coverageInfo {
        @media (max-width: 1100px) {
            display: inline-block;
            margin: 0px auto 12px;
        }

        li {
            font-size: 16px;
            text-align: left;
            margin: 24px 0px;
            position: relative;
            padding-left: 24px;

            @media (max-width: 1100px) {
                margin: 0px 0px 8px;
            }

            span {
                display: block;

                @media (max-width: 1100px) {
                    display: inline-block;
                }
            }

            &:after {
                background-color: $theme-lightgreen;
                border-radius: 40px;
                content: "";
                position: absolute;
                top: 6px;
                left: 0px;
                height: 14px;
                width: 14px;
            }
        }
    }

    .shortof {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        display: inline-block;
        padding-bottom: 4px;
        background: url("../../../../public/images/heading_bg.svg") no-repeat bottom 0px center/106%;

        span {
            color: $theme-lightgreen;
        }
    }

    .motorCard {
        background-color: #fff;
        border-radius: 12px;
        padding: 12px 20px;
        box-shadow: 0px 12px 32px rgba(32, 56, 100, 0.12);
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        img {
            padding: 12px;
            border: 1px solid $theme-lightgreen;
            box-shadow: 0px 12px 32px rgba(32, 56, 100, 0.12);
            border-radius: 12px;
            width: 140px;

            @media (max-width: 700px) {
                padding: 8px;
                width: 100px;
            }
        }
    }

    .bottomButtons {
        @media (max-width: 899px) {
            width: 100%;
            justify-content: center;
        }
    }
}